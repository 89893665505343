import restaurantsImage from "../../assets/images/dashboard/restaurantsCardImage.svg";
import fashionImage from "../../assets/images/dashboard/fashionCardImage.svg";
import homeAndBabyImage from "../../assets/images/home-and-baby.svg";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import React from "react";

function Card({ text, img, handleClick }) {
  return (
    <li className="product-Module__list isLink" onClick={handleClick}>
      <div className="product-box" style={{ direction: "ltr" }}>
        <div
          className="product-box__img"
          style={{
            backgroundImage: `url(${img})`,
          }}
        ></div>
        <div
          style={{
            padding: "0 10px",
            height: 80,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h6 className="product-box__brand">{text}</h6>
        </div>
      </div>
    </li>
  );
}

const project = process.env.REACT_APP_PROJECT || "default";

export default function FashionRestaurantsStripe({
  handleFashionClick,
  handleRestaurantsClick,
  handleHomeAndBabyClick,
}) {
  const project = process.env.REACT_APP_PROJECT || "default";
  let fashionText = "רשתות אופנה בהנחה";
  let restaurantsText = "מסעדות בהנחה";
  let homeAndBabyText = "לבית וליולדת";

  if (project === "pele") {
    fashionText = "רשתות אופנה - 12% הנחה";
    restaurantsText = "מסעדות - 12% הנחה";
    homeAndBabyText = "לבית וליולדת - 12% הנחה";
  }

  const cards = [
    { text: fashionText, img: fashionImage, handleClick: handleFashionClick },
    {
      text: restaurantsText,
      img: restaurantsImage,
      handleClick: handleRestaurantsClick,
    },
    {
      text: homeAndBabyText,
      img: homeAndBabyImage,
      handleClick: handleHomeAndBabyClick,
    },
  ];

  return (
    <ScrollingCarousel>
      <ul className="categoryList">
        {cards.map((card, index) => (
          <Card
            key={index}
            text={card.text}
            img={card.img}
            handleClick={card.handleClick}
          />
        ))}
      </ul>
    </ScrollingCarousel>
  );
}
