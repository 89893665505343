import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Header from "../header/Header";
import h from "../../assets/images/categories/h-min.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getSelfPickupProducts } from "../../redux/API/product/product.action";
import ProductCard from "../product/ProductCard";

export default function SelfPickupProducts() {
  const { t } = useTranslation();
  const items = useSelector((state) =>
    state.product.selfPickupItems.map((item) => ({
      ...item,
      price: item?.prices?.[0]?.value,
    }))
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!items.length) dispatch(getSelfPickupProducts());
  }, [dispatch, items.length]);

  return (
    <div>
      <Header />
      <div className="pageTemplate">
        <div className="container">
          <div
            className="PageBgHeading"
            style={{
              backgroundImage: `url(${h})`,
            }}
          >
            <h5 className="PageBgHeading-title">{"מוצרים באיסוף עצמי"}</h5>
          </div>

          <ul className="product-Module">
            {items?.length > 0
              ? items?.map((product) => (
                  <ProductCard product={product} key={product.id} />
                ))
              : t("No products")}
          </ul>
        </div>
      </div>
    </div>
  );
}
