export const MerchantsActionTypes = {
  GET: {
    GET_MERCHANT_PENDING: "GET_MERCHANT_PENDING",
    GET_MERCHANT_SUCCESS: "GET_MERCHANT_SUCCESS",
    GET_MERCHANT_ERROR: "GET_MERCHANT_ERROR",

    GET_MERCHANT_BY_ID_PENDING: "GET_MERCHANT_BY_ID_PENDING",
    GET_MERCHANT_BY_ID_SUCCESS: "GET_MERCHANT_BY_ID_SUCCESS",
    GET_MERCHANT_BY_ID_ERROR: "GET_MERCHANT_BY_ID_ERROR",

    GET_MERCHANT_BY_CATEGORY_ID_PENDING: "GET_MERCHANT_BY_CATEGORY_ID_PENDING",
    GET_MERCHANT_BY_CATEGORY_ID_SUCCESS: "GET_MERCHANT_BY_CATEGORY_ID_SUCCESS",
    GET_MERCHANT_BY_CATEGORY_ID_ERROR: "GET_MERCHANT_BY_CATEGORY_ID_ERROR",

    GET_MERCHANTS_BY_SEARCH_PENDING: "GET_MERCHANTS_BY_SEARCH_PENDING",
    GET_MERCHANTS_BY_SEARCH_SUCCESS: "GET_MERCHANTS_BY_SEARCH_SUCCESS",
    GET_MERCHANTS_BY_SEARCH_ERROR: "GET_MERCHANTS_BY_SEARCH_ERROR",

    GET_MERCHANT_PRODUCTS_PENDING: "GET_MERCHANT_PRODUCTS_PENDING",
    GET_MERCHANT_PRODUCTS_SUCCESS: "GET_MERCHANT_PRODUCTS_SUCCESS",
    GET_MERCHANT_PRODUCTS_ERROR: "GET_MERCHANT_PRODUCTS_ERROR",

    GET_MERCHANTS_FASHION_PENDING: "GET_MERCHANTS_FASHION_PENDING",
    GET_MERCHANTS_FASHION_SUCCESS: "GET_MERCHANTS_FASHION_SUCCESS",
    GET_MERCHANTS_FASHION_ERROR: "GET_MERCHANTS_FASHION_ERROR",

    GET_MERCHANTS_RESTAURANTS_PENDING: "GET_MERCHANTS_RESTAURANTS_PENDING",
    GET_MERCHANTS_RESTAURANTS_SUCCESS: "GET_MERCHANTS_RESTAURANTS_SUCCESS",
    GET_MERCHANTS_RESTAURANTS_ERROR: "GET_MERCHANTS_RESTAURANTS_ERROR",
  },
  CLEAR_MERCHANT: "CLEAR_MERCHANT",
  CLEAR_MERCHANT_PRODUCTS: "CLEAR_MERCHANT_PRODUCTS",
};
