import React from "react";
import { useNavigate } from "react-router-dom";
import { couponsOldPricesMap } from "../../../helpers/hardcodedIds";

const Coupon = ({ coupon }) => {
  const navigate = useNavigate();
  const {
    id,
    title,
    mainImageURL,
    color = "var(--secondary)",
    bg = "white",
    prices,
    type = "product",
    subtitle,
  } = coupon;

  const getImageUrl = (mainImageURL) => {
    if (
      mainImageURL.includes("http") ||
      mainImageURL.includes("/static/media/")
    )
      return mainImageURL;
    return `${process.env.REACT_APP_API_URL}/upload${mainImageURL}`;
  };

  const bimkomPrice =
    couponsOldPricesMap[id] ||
    prices.find((p) => p.type === "base_price")?.value;
  const price = prices.find((p) => p.type === "price")?.value;

  const renderBimkom = (
    <p>
      {!(price && bimkomPrice) ? null : (
        <>
          לתשלום: <strong>{price}&nbsp;₪</strong> במקום{" "}
          <span style={{ textDecoration: "line-through" }}>
            {bimkomPrice}&nbsp;₪
          </span>
        </>
      )}
    </p>
  );

  const renderCoins = (
    <p>
      לתשלום: <strong>{price}&nbsp;₪ </strong>
      {/* {+((prices[0].value / (1 - DISCOUNT)) * DISCOUNT).toFixed(1)}{" "}
    <GiTwoCoins /> */}
    </p>
  );

  return (
    <div
      className="caseback-box"
      style={{
        backgroundColor: bg,
        color: color,
        // backgroundColor,
        // width: '363px',
        // ...style
      }}
    >
      <div className="caseback-boxInner">
        <div className="caseback-text">
          <h5 className="casebackBox-Title">{title}</h5>
          <h5 className="casebackBox-offer">
            {/* {discountValue ? (<><span>{discountValue}{isTypePercent} </span> {t("Discount")}</>) : (<p>להצטרף למועדון WINcard+</p>)} */}
          </h5>
          {/* <h6 className="casebackBox-subTitle">{''}</h6> */}

          {bimkomPrice ? renderBimkom : renderCoins}
          <p>{subtitle || ""}</p>
          <button
            onClick={() =>
              type === "merchant"
                ? navigate(`/vendor/${id}`)
                : navigate(`/product/${id}`)
            }
            style={{ border: "1px solid" }}
          >
            {type === "merchant" ? "לבדוק" : "להזמנה"}
          </button>
        </div>
        <div className="caseback-image">
          <img src={getImageUrl(mainImageURL)} alt={title} />
        </div>
      </div>
    </div>
  );
};

export default Coupon;
