import { useNavigate } from "react-router-dom";
import { ReactComponent as Locate } from "../../assets/icons/locate.svg";

import "./SearchResult.css";

const SearchReults = ({ filterProducts, filterStores }) => {
  const navigate = useNavigate();

  window.onload = () => {
    document.onclick = function (e) {
      if (e.target.className !== "menuSearch" && e.target.id !== "search-bar") {
        document.querySelector(".menuSearch").style.display = "none";
      }
    };
  };
  return (
    <div className="menuSearch">
      <div className="menuSearchSection">
        <div className="menuSearchSectionLink">הטבות</div>
        <div className="MenuSearchItemsDiv">
          {filterProducts.map((el, i) => {
            return (
              <div key={i}>
                <div
                  onClick={() => {
                    navigate(`/product/${el.id}`);
                  }}
                  className="menuSearchItem"
                >
                  <img alt="" className="menuSearchItemImg" src={el.image} />
                  <div className="menuSearchItemText">{el.title}</div>
                </div>
                <hr
                  style={{
                    margin: 0,
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div className="menuSearchSection">
        <div to={"#"} className="menuSearchSectionLink">
          רשתות
          {/* {header} */}
        </div>
        <div className="MenuSearchItemsDiv">
          {filterStores.map((el, i) => {
            return (
              <div key={i}>
                <div
                  onClick={() => {
                    navigate(`/vendor/${el.id}`, {
                      state: { id: el.id },
                    });
                  }}
                  className="menuSearchItem"
                >
                  <img alt="" className="menuSearchItemImg" src={el.image} />
                  <div className="menuSearchItemText">
                    <div>{el.title}</div>
                    <div>
                      {el.address && (
                        <div className="menuSearchItemText_sub">
                          <Locate />
                          {el.address}
                        </div>
                      )}

                      {el.kosher === "כשר" ||
                        (el.kosher === "כשר למהדרין" && (
                          <div className="menuSearchItemText_sub">
                            {el.kosher}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <hr
                  style={{
                    margin: 0,
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      {/* <div to="#" className="menuSearchMore"      >
        לעוד תוצאות
      </div> */}
    </div>
  );
};

export default SearchReults;
