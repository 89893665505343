import { ca } from "date-fns/locale";
import { ProductsActionTypes } from "./product.types";

const INITIAL_STATE = {
  isProductsPending: false,
  products: [],
  productsError: false,

  isProductPending: false,
  product: {},
  productError: false,

  isHotProductsPending: false,
  hotProducts: [],
  hotProductsError: null,

  isSearchProductsPending: false,
  searchProducts: [],
  searchProductsError: null,

  isAttractionProductsPending: false,
  attractionProducts: [],
  attractionProductsError: null,

  selfPickupPending: false,
  selfPickupItems: [],
  selfPickupError: null,

  isFashionOpenProductsPending: false,
  fashionOpenProducts: [],
  fashionOpenProductsError: null,

  isRestaurantsOpenProductsPending: false,
  restaurantsOpenProducts: [],
  restaurantsOpenProductsError: null,

  isHomeBabyOpenProductsPending: false,
  homeBabyOpenProducts: [],
  homeBabyOpenProductsError: null,
};

const ProductsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ProductsActionTypes.GET.GET_PRODUCT_PENDING:
      return {
        ...state,
        isProductsPending: true,
      };

    case ProductsActionTypes.GET.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        isProductsPending: false,
        products: action.payload,
        productsError: null,
      };

    case ProductsActionTypes.GET.GET_PRODUCT_ERROR:
      return {
        ...state,
        isProductsPending: false,
        productsError: action.payload,
      };

    case ProductsActionTypes.GET.GET_PRODUCT_BY_ID_PENDING:
      return {
        ...state,
        isProductPending: true,
      };

    case ProductsActionTypes.GET.GET_PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        isProductPending: false,
        product: action.payload,
        productError: null,
      };

    case ProductsActionTypes.GET.GET_PRODUCT_BY_ID_ERROR:
      return {
        ...state,
        isProductPending: false,
        productError: action.payload,
      };

    case ProductsActionTypes.GET.GET_HOT_PRODUCTS_PENDING:
      return {
        ...state,
        isHotProductsPending: true,
      };

    case ProductsActionTypes.GET.GET_HOT_PRODUCTS_SUCCESS:
      return {
        ...state,
        isHotProductsPending: false,
        hotProducts: action.payload,
        hotProductsError: null,
      };

    case ProductsActionTypes.GET.GET_HOT_PRODUCTS_ERROR:
      return {
        ...state,
        isHotProductsPending: false,
        hotProductsError: action.payload,
      };

    case ProductsActionTypes.GET.GET_ATTRACTION_PRODUCTS_PENDING:
      return {
        ...state,
        isAttractionProductsPending: true,
      };

    case ProductsActionTypes.GET.GET_ATTRACTION_PRODUCTS_SUCCESS:
      return {
        ...state,
        isAttractionProductsPending: false,
        attractionProducts: action.payload,
        attractionProductsError: null,
      };

    case ProductsActionTypes.GET.GET_ATTRACTION_PRODUCTS_ERROR:
      return {
        ...state,
        isAttractionProductsPending: false,
        attractionProductsError: action.payload,
      };

    case ProductsActionTypes.GET.GET_SELF_PICKUP_PRODUCTS_PENDING:
      return {
        ...state,
        selfPickupPending: true,
      };

    case ProductsActionTypes.GET.GET_SELF_PICKUP_PRODUCTS_SUCCESS:
      return {
        ...state,
        selfPickupPending: false,
        selfPickupItems: action.payload,
        selfPickupError: null,
      };

    case ProductsActionTypes.GET.GET_SELF_PICKUP_PRODUCTS_ERROR:
      return {
        ...state,
        selfPickupPending: false,
        selfPickupError: action.payload,
      };

    case ProductsActionTypes.GET.GET_FASHION_OPEN_PRODUCTS_PENDING:
      return {
        ...state,
        isFashionOpenProductsPending: true,
      };

    case ProductsActionTypes.GET.GET_FASHION_OPEN_PRODUCTS_SUCCESS:
      return {
        ...state,
        isFashionOpenProductsPending: false,
        fashionOpenProducts: action.payload,
        fashionOpenProductsError: null,
      };

    case ProductsActionTypes.GET.GET_FASHION_OPEN_PRODUCTS_ERROR:
      return {
        ...state,
        isFashionOpenProductsPending: false,
        fashionOpenProductsError: action.payload,
      };

    case ProductsActionTypes.GET.GET_RESTAURANTS_OPEN_PRODUCTS_PENDING:
      return {
        ...state,
        isRestaurantsOpenProductsPending: true,
      };

    case ProductsActionTypes.GET.GET_RESTAURANTS_OPEN_PRODUCTS_SUCCESS:
      return {
        ...state,
        isRestaurantsOpenProductsPending: false,
        restaurantsOpenProducts: action.payload,
        restaurantsOpenProductsError: null,
      };

    case ProductsActionTypes.GET.GET_RESTAURANTS_OPEN_PRODUCTS_ERROR:
      return {
        ...state,
        isRestaurantsOpenProductsPending: false,
        restaurantsOpenProductsError: action.payload,
      };

    case ProductsActionTypes.GET.GET_SEARCH_PRODUCTS_PENDING:
      return {
        ...state,
        isSearchProductsPending: true,
      };

    case ProductsActionTypes.GET.GET_SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isSearchProductsPending: false,
        searchProducts: action.payload,
        searchProductsError: null,
      };

    case ProductsActionTypes.GET.GET_SEARCH_PRODUCTS_ERROR:
      return {
        ...state,
        isSearchProductsPending: false,
        searchProductsError: action.payload,
      };

    case ProductsActionTypes.GET.GET_HOME_BABY_OPEN_PRODUCTS_PENDING:
      return {
        ...state,
        isHomeBabyOpenProductsPending: true,
      };

    case ProductsActionTypes.GET.GET_HOME_BABY_OPEN_PRODUCTS_SUCCESS:
      return {
        ...state,
        isHomeBabyOpenProductsPending: false,
        homeBabyOpenProducts: action.payload,
        homeBabyOpenProductsError: null,
      };

    case ProductsActionTypes.GET.GET_HOME_BABY_OPEN_PRODUCTS_ERROR:
      return {
        ...state,
        isHomeBabyOpenProductsPending: false,
        homeBabyOpenProductsError: action.payload,
      };

    default:
      return state;
  }
};

export default ProductsReducer;
