import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField, Button, Select, MenuItem } from "@material-ui/core";
import { IoIosRestaurant } from "react-icons/io";
import { GiClothes } from "react-icons/gi";
import { MdHome } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "../wallets/wallets.css";
import Header from "../header/Header";
import ConfirmDialog from "./ConfirmDialog";
// import CouponList from "./couponList/CouponList";
import { sendMoney } from "../../redux/actions-exporter";
import { PROJECT_NAME } from "../../config";
import { confirmRestaurantDialogText } from "../../helpers/hardcodedIds";

export default function Wallets() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.user);
  const sendMoneyError = useSelector((state) => state.user.sendMoneyError);
  const [isRestaurantConfirmed, setIsRestaurantConfirmed] = useState(false);
  const [restaurantsDialog, setRestaurantsDialog] = useState(false);
  const [amountType, setAmountType] = useState("money");
  const [amount, setAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [email, setEmail] = useState("");
  const [purpose, setPurpose] = useState("");
  const isPele = PROJECT_NAME === "pele";

  const handleClickRestaurants = () => {
    if (PROJECT_NAME !== "pele") {
      setIsRestaurantConfirmed(true);
    } else {
      setRestaurantsDialog(true);
    }
  };

  const handleClickHomeBaby = () => {
    navigate("/allvendors/home-baby");
  };

  useEffect(() => {
    if (isRestaurantConfirmed) {
      navigate("/allvendors/restaurants");
    }
  }, [isRestaurantConfirmed, navigate]);

  const onSend = () => {
    dispatch(
      sendMoney(
        {
          amountType,
          amount,
          email,
          purpose,
        },
        () => toast.success("Money has been successfully sent"),
        () => toast.error(sendMoneyError.response.data.message)
      )
    );
  };

  return (
    <div>
      <Header isFull={true} />
      <div className="walletTamplate">
        <div className="container">
          <div className="walletIcons">
            {/* <div
              className="walletIconsBox isLink"
            >
              <FaWallet />
              <h6 className="walletIconsBox-title">
                תשלום ברשת מזון
                <br />
                (בקרוב)
              </h6>
            </div> */}

            <div
              className="walletIconsBox isLink"
              onClick={handleClickRestaurants}
            >
              <IoIosRestaurant />
              {isPele ? (
                <h6 className="walletIconsBox-title">
                  מסעדות
                  <br />
                  (מהדרין)
                </h6>
              ) : (
                <h6 className="walletIconsBox-title">מסעדות</h6>
              )}
            </div>
            <div
              className="walletIconsBox isLink"
              onClick={() => {
                navigate("/allvendors/fashion");
              }}
            >
              <GiClothes />
              <h6 className="walletIconsBox-title">{t("fashion")}</h6>
            </div>
            <div
              className="walletIconsBox isLink"
              onClick={handleClickHomeBaby}
            >
              <MdHome />
              <h6 className="walletIconsBox-title">{t("לבית וליולדת")}</h6>
            </div>
          </div>
          <ConfirmDialog
            open={restaurantsDialog}
            handleClose={() => setRestaurantsDialog(false)}
            handleConfirm={() => setIsRestaurantConfirmed(true)}
            text1={confirmRestaurantDialogText.text1}
            text2={confirmRestaurantDialogText.text2}
          />

          <div>
            {show ? (
              <div className="ContactUsModule">
                <h6 className="ContactUsModule-title"> {t("sendMoney")}</h6>
                <Select
                  labelId="amount-type"
                  id="amount-type"
                  name="select"
                  value={amountType}
                  label={t("Amount type")}
                  style={{ border: 0, direction: "ltr" }}
                  className="inputStyle inputStyle-select"
                  onChange={(e) => {
                    setAmountType(e.target.value);
                    setMaxAmount(user[e.target.value]);
                    setAmount(0);
                  }}
                >
                  <MenuItem value="money">{t("Money")}</MenuItem>
                  <MenuItem value="credit">{t("Credit")}</MenuItem>
                </Select>
                <TextField
                  required
                  id="Amount"
                  className="inputStyle"
                  name="Amount"
                  label={t("Amount")}
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: maxAmount } }}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  type="number"
                  margin="dense"
                />
                <TextField
                  required
                  id="User"
                  name="User"
                  label={t("User email")}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="inputStyle"
                  fullWidth
                  margin="dense"
                />
                <TextField
                  required
                  id="Transfer purpose"
                  name="Transfer purpose"
                  label={t("Transfer purpose")}
                  onChange={(e) => setPurpose(e.target.value)}
                  type="text"
                  className="inputStyle"
                  fullWidth
                  margin="dense"
                />
                <div className="formBtn">
                  <Button onClick={() => setShow(false)} className="blueBtn">
                    {t("cancel")}
                  </Button>
                  <Button onClick={onSend} className="blueBtn">
                    {" "}
                    {t("send")}
                  </Button>
                </div>
              </div>
            ) : null}

            {/* <CouponList /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
