import axios from "axios";

axios.defaults.withCredentials = true;

export const baseURL = process.env.REACT_APP_API_URL;
const botId = process.env.REACT_APP_BOT_ID;

const axiosInstance = axios.create({
  baseURL,
  data: {},
  withCredentials: true,
});

const axiosNoTokenInstance = axios.create({
  baseURL,
  data: {},
});

axiosNoTokenInstance.interceptors.request.use((config) => {
  config.params = {
    botId,
    ...config.params,
  };
  return config;
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.params = {
    botId,
    ...config.params,
  };
  config.headers = { Authorization: token ? `Bearer ${token}` : "" };
  return config;
});

// axiosNoTokenInstance.interceptors.request.use(request => {
//   console.log('Starting Request', JSON.stringify(request, null, 2))
//   return request
// })

// axiosNoTokenInstance.interceptors.response.use(response => {
//   console.log('Response:', JSON.stringify(response, null, 2))
//   return response
// })

export { axiosInstance, axiosNoTokenInstance };
