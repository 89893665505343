import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Header from "../header/Header";
import {
  clearMerchant,
  clearMerchantProducts,
  getMerchantById,
  getMerchantProducts,
} from "../../redux/actions-exporter";
import ProductCard from "../product/ProductCard";
import LoaderPrimary from "../UI/LoaderPrimary";
import EmptyResults from "../emptyResults/EmptyResults";

export default function Market({ showBenefitType = true }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const merchant = useSelector((state) => state.merchant.merchant);
  const products = useSelector(
    (state) =>
      showBenefitType
        ? state.merchant.merchantProducts
        : state.merchant.merchantProducts.filter(
            (product) => !product.prices.some((price) => price.type === "price")
          ),
    shallowEqual
  ); // Convoluted logic, not good.);
  const isMerchantProductsPending = useSelector(
    (state) => state.merchant.isMerchantProductsPending
  );

  useEffect(() => {
    dispatch(getMerchantById(id));
    dispatch(getMerchantProducts(id));

    return () => {
      dispatch(clearMerchant());
      dispatch(clearMerchantProducts());
    };
  }, [dispatch, id]);

  return (
    <div>
      <Header />
      <div className="pageTemplate">
        <div className="container">
          <h5 className="module-heading__title" style={{ margin: "10px 0" }}>
            {merchant.title || " "}
          </h5>

          <ul className="product-Module">
            {isMerchantProductsPending ? (
              <LoaderPrimary />
            ) : products.length > 0 ? (
              products.map((product) => (
                <ProductCard product={product} key={product.id} />
              ))
            ) : (
              <EmptyResults />
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
