import { OrderActionTypes } from "./order.types";

export const cardStatuses = {
  NOT_USED: "NOT_USED",
  USED: "USED",
  PARTIALLY_USED: "PARTIALLY_USED",
  CANCELLED: "CANCELLED",
  EXPIRED: "EXPIRED",
};

export const pendingStatus = {
  IDLE: "idle",
  SUCCESS: "success",
  FAILED: "failed",
  PENDING: "pending",
};

const INITIAL_STATE = {
  isOrderPending: false,
  order: {},
  orderError: null,

  isOrdersPending: false,
  orders: [],
  ordersError: null,

  usage: {},
  isUsagePending: false,
  usageError: null,

  benefitCards: [],
  isBenefitCardsPending: pendingStatus.IDLE,
  benefitCardsError: null,

  loadCards: [],
  isLoadCardsPending: pendingStatus.IDLE,
  loadCardsError: null,

  allCards: [],
  activeCards: [],
  inactiveCards: [],
  isAllCardsPending: pendingStatus.IDLE,
  allCardsError: null,
};

const OrderReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case OrderActionTypes.GET.ORDER_PENDING:
      return {
        ...state,
        isOrderPending: true,
      };

    case OrderActionTypes.GET.ORDER_SUCCESS:
      return {
        ...state,
        isOrderPending: false,
        order: action.payload,
      };

    case OrderActionTypes.GET.ORDER_ERROR:
      return {
        ...state,
        isOrderPending: false,
        orderError: action.payload,
      };

    case OrderActionTypes.GET.ORDERS_PENDING:
      return {
        ...state,
        isOrdersPending: true,
      };

    case OrderActionTypes.GET.ORDERS_SUCCESS:
      return {
        ...state,
        isOrdersPending: false,
        orders: action.payload,
      };

    case OrderActionTypes.GET.ORDERS_ERROR:
      return {
        ...state,
        isOrdersPending: false,
        ordersError: action.payload,
      };

    case OrderActionTypes.CLEAR.ORDER:
      return {
        ...state,
        order: {},
      };

    case OrderActionTypes.GET.USAGE_PENDING:
      return {
        ...state,
        isUsagePending: true,
      };

    case OrderActionTypes.GET.USAGE_SUCCESS:
      return {
        ...state,
        isUsagePending: false,
        usage: action.payload,
        usageError: null,
      };

    case OrderActionTypes.GET.USAGE_ERROR:
      return {
        ...state,
        isUsagePending: false,
        usageError: action.payload,
      };

    case OrderActionTypes.GET.BENEFIT_CARDS_PENDING:
      return {
        ...state,
        isBenefitCardsPending: pendingStatus.PENDING,
      };

    case OrderActionTypes.GET.BENEFIT_CARDS_SUCCESS:
      return {
        ...state,
        isBenefitCardsPending: pendingStatus.SUCCESS,
        benefitCards: action.payload,
        benefitCardsError: null,
      };

    case OrderActionTypes.GET.BENEFIT_CARDS_ERROR:
      return {
        ...state,
        isBenefitCardsPending: pendingStatus.FAILED,
        benefitCardsError: action.payload,
      };

    case OrderActionTypes.GET.LOAD_CARDS_PENDING:
      return {
        ...state,
        isLoadCardsPending: pendingStatus.PENDING,
      };

    case OrderActionTypes.GET.LOAD_CARDS_SUCCESS:
      return {
        ...state,
        isLoadCardsPending: pendingStatus.SUCCESS,
        loadCards: action.payload,
        loadCardsError: null,
      };

    case OrderActionTypes.GET.LOAD_CARDS_ERROR:
      return {
        ...state,
        isLoadCardsPending: pendingStatus.FAILED,
        loadCardsError: action.payload,
      };

    case OrderActionTypes.GET.ALL_CARDS_PENDING:
      return {
        ...state,
        isAllCardsPending: pendingStatus.PENDING,
      };

    case OrderActionTypes.GET.ALL_CARDS_SUCCESS:
      return {
        ...state,
        isAllCardsPending: pendingStatus.SUCCESS,
        allCards: action.payload.allCards,
        activeCards: action.payload.activeCards,
        inactiveCards: action.payload.inactiveCards,
        allCardsError: null,
      };

    case OrderActionTypes.GET.ALL_CARDS_ERROR:
      return {
        ...state,
        isAllCardsPending: pendingStatus.FAILED,
        allCardsError: action.payload,
      };
    default:
      return state;
  }
};

export default OrderReducer;
