import loaderImg from "../../assets/images/loader.png";
import logoMiluim from "../../assets/logo/miluim/logoMiluim.svg";
import logoBenefits from "../../assets/logo/benefits/logoBenefits_white_h150.png";
import logoPele from "../../assets/logo/pele/pele_text.png";
import logoMshuk from "../../assets/logo/mshuk/win.png";
import logoNifgashim from "../../assets/logo/logoNifgashim.svg";
import logoMax from "../../assets/images/max.png";

const styles = {
  image: {
    width: "100%",
    objectFit: "contain",
  },
  poweredBy: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
  },
  loader: {
    height: "100%",
    width: "100vw",
    maxWidth: "450px",
    display: "flex",
    alignItems: "end",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    margin: "auto",
  },
  loaderMiluim: {
    background: "linear-gradient(to right top, white, var(--primary))",
    flexDirection: "column",
    justifyContent: "center",
    gap: "40px",
    alignItems: "center",
    padding: "20px",
  },
  loaderBenefits: {
    background: "var(--bg)",
    flexDirection: "column",
    justifyContent: "center",
    gap: "40px",
    alignItems: "center",
    padding: "20px",
    color: "var(--header-text)",
  },
};

const Image = ({ src, style = {}, alt = "" }) => (
  <div style={style}>
    <img src={src} alt={alt} style={styles.image} />
  </div>
);

const LoaderBenefits = () => (
  <div
    style={{
      ...styles.loader,
      ...styles.loaderBenefits,
    }}
  >
    <Image src={logoBenefits} style={{ marginBottom: 80, width: "70%" }} />
  </div>
);

const LoaderMiluim = () => (
  <div
    style={{
      ...styles.loader,
      ...styles.loaderMiluim,
    }}
  >
    <Image src={logoMiluim} style={{ marginBottom: 80, width: "50%" }} />
    Powered by
    <div style={styles.poweredBy}>
      <Image src={logoNifgashim} style={{ width: "50%" }} />
      <Image src={logoPele} style={{ width: "50%" }} />
    </div>
  </div>
);

const LoaderMshuk = () => (
  <div
    style={{
      ...styles.loader,
      ...styles.loaderBenefits,
    }}
  >
    <Image src={logoMshuk} style={{ marginBottom: 30, width: "50%" }} />
    Powered by
    <div style={styles.poweredBy}>
      <Image
        src={logoMax}
        style={{
          width: "40%",
        }}
      />
    </div>
  </div>
);

const LoaderDefault = () => (
  <div style={styles.loader}>
    <Image src={loaderImg} />
  </div>
);

const loaderComponents = {
  default: LoaderDefault,
  pele: LoaderDefault,
  miluim: LoaderMiluim,
  benefits: LoaderBenefits,
  mshuk: LoaderMshuk,
};

const Loader = ({ type = "default" }) => {
  const LoaderComponent = loaderComponents[type] || loaderComponents.default;

  return <LoaderComponent />;
};

export default Loader;
