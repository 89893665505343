const stringMap = {
  NOT_USED: "לא מומש",
  USED: "מומש",
  PARTIALLY_USED: "מומש חלקית",
  CANCELLED: "בוטל",
  EXPIRED: "פג תוקף",
};

const colorMap = {
  NOT_USED: "success",
  USED: "error",
  PARTIALLY_USED: "warning",
  CANCELLED: "error",
  EXPIRED: "error",
};

export const getStatusString = (status) => stringMap[status] || "—";
export const getStatusColor = (status) => colorMap[status] || "default";
