import { OrderActionTypes } from "./order.types";
import { axiosInstance } from "../../axios.config";
import { cardStatuses } from "./order.reducer";

export const getOrder = (orderId) => (dispatch) => {
  dispatch({ type: OrderActionTypes.GET.ORDER_PENDING });
  axiosInstance
    .get(`/order`, {
      params: {
        orderId,
      },
    })
    .then((res) =>
      dispatch({
        type: OrderActionTypes.GET.ORDER_SUCCESS,
        payload: res.data.payload,
      })
    )
    .catch((error) =>
      dispatch({
        type: OrderActionTypes.GET.ORDER_ERROR,
        payload: error.response.data,
      })
    );
};

export const getOrders = () => (dispatch) => {
  dispatch({ type: OrderActionTypes.GET.ORDERS_PENDING });

  axiosInstance
    .get("/order")
    .then((res) =>
      dispatch({
        type: OrderActionTypes.GET.ORDERS_SUCCESS,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: OrderActionTypes.GET.ORDERS_ERROR,
        payload: error.response.data,
      })
    );
};
export const getUsage = () => (dispatch) => {
  dispatch({ type: OrderActionTypes.GET.USAGE_PENDING });

  axiosInstance
    .get(`/multipass/usage-history`)
    .then((res) =>
      dispatch({
        type: OrderActionTypes.GET.USAGE_SUCCESS,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: OrderActionTypes.GET.USAGE_ERROR,
        payload: error.response.data,
      })
    );
};

export const getBenefitCards = () => (dispatch) => {
  dispatch({ type: OrderActionTypes.GET.BENEFIT_CARDS_PENDING });

  axiosInstance
    .get(`/card/getAllCards`, {
      params: { type: "BENEFIT" },
    })
    .then((res) =>
      dispatch({
        type: OrderActionTypes.GET.BENEFIT_CARDS_SUCCESS,
        payload: res.data.cards,
      })
    )
    .catch((error) =>
      dispatch({
        type: OrderActionTypes.GET.BENEFIT_CARDS_ERROR,
        payload: error.response.data,
      })
    );
};

export const getLoadCards = () => (dispatch) => {
  dispatch({ type: OrderActionTypes.GET.LOAD_CARDS_PENDING });

  axiosInstance
    .get(`/card/getAllCards`, {
      params: { type: "LOAD" },
    })
    .then((res) =>
      dispatch({
        type: OrderActionTypes.GET.LOAD_CARDS_SUCCESS,
        payload: res.data.cards,
      })
    )
    .catch((error) =>
      dispatch({
        type: OrderActionTypes.GET.LOAD_CARDS_ERROR,
        payload: error.response.data,
      })
    );
};

export const getAllCards = () => (dispatch) => {
  dispatch({ type: OrderActionTypes.GET.ALL_CARDS_PENDING });

  axiosInstance
    .get(`/card/getAllCards`)
    .then((res) => {
      const allCards = res.data.cards;
      const activeCards = allCards.filter(
        (card) =>
          card.status === cardStatuses.NOT_USED ||
          card.status === cardStatuses.PARTIALLY_USED
      );
      const inactiveCards = allCards.filter(
        (card) =>
          card.status === cardStatuses.USED ||
          card.status === cardStatuses.CANCELLED ||
          card.status === cardStatuses.EXPIRED
      );

      dispatch({
        type: OrderActionTypes.GET.ALL_CARDS_SUCCESS,
        payload: { allCards, activeCards, inactiveCards },
      });
    })
    .catch((error) =>
      dispatch({
        type: OrderActionTypes.GET.ALL_CARDS_ERROR,
        payload: error.response.data,
      })
    );
};

export const clearOrder = () => (dispatch) => {
  dispatch({ type: OrderActionTypes.CLEAR.ORDER });
};
