import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../header/Header";
import { getMerchants } from "../../redux/actions-exporter";
import placeholderImg from "../../assets/images/categories/recommended-min.jpg";
import LoaderPrimary from "../UI/LoaderPrimary";
import { shortenString } from "../../helpers/shortenString";
import { marketsIds } from "../../helpers/hardcodedIds";

export default function Vendors() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // заменить на фильтрацию на бэке
  const merchants = useSelector((state) => state.merchant.merchants);
  const markets = merchants.filter(
    (m) => marketsIds.includes(m.id)
    // || m.id === 641 // TODO: fix when duplicating beitan into carrefour
  );
  const merchantsPending = useSelector(
    (state) => state.merchant.isMerchantsPending
  );
  useEffect(() => {
    if (!merchants.length) dispatch(getMerchants());
  }, [dispatch, merchants.length]);

  return (
    <div>
      <Header />

      <div className="pageTemplate">
        <div className="container">
          <div className="vendorAllListBlock">
            <h6 className="module-heading__title">שוברים לרשתות מזון</h6>
            <ul className="categoryList">
              {markets?.length > 0 ? (
                markets.map((item) => (
                  <li
                    key={item.id}
                    className="categoryList__block"
                    onClick={() => navigate(`/foods/${item.id}`)}
                  >
                    <div className="category-box__img">
                      <img
                        src={
                          `${process.env.REACT_APP_API_URL}/upload${item.mainImageURL}` ||
                          placeholderImg
                        }
                        className="img-fluid"
                        alt="My Awesome"
                        style={{ padding: 0 }}
                      />
                    </div>
                    <h6 className="category-box__title">
                      {item.title && shortenString(item.title, 30)}
                    </h6>
                  </li>
                ))
              ) : merchantsPending ? (
                <LoaderPrimary />
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
