import * as React from "react";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import "./rules.css";
import { Container } from "@mui/material";

const theme = createTheme({
  direction: "rtl",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function Cancel() {
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <h5
            style={{
              textAlign: "center",
              marginTop: "25px",
            }}
          >
            <b>מדיניות ביטול עסקה</b>
          </h5>
          <div className="rules">
            ביטול רכישת השובר יתבצע בהתאם להוראות חוק הגנת הצרכן, התשמ"א-1981
            (להלן- "החוק") ו/או בהתאם להוראות כל דין, לרבות תקנות. ניתן לבטל את
            רכישת השובר תוך 14 יום מיום ביצוע הרכישה, וזאת במידה והשובר לא מומש.
            במקרה כזה יתקבל זיכוי בניכוי עמלת דמי ביטול בסך 5% ממחיר העסקה או
            100 ₪, הנמוך מבניהם. במידה והשובר הינו לרכישת מוצר, והשובר מומש בבית
            העסק, החזרת המוצר הינה לפי תקנון בית העסק. במקרה של ביטול עסקה,
            יבוטל השובר ולא ניתן יהיה לממשו בבית העסק.
          </div>
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
}
