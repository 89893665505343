const AccentIcon = ({ img, handleClick, title }) => {
  return (
    <div onClick={handleClick} className="grayIconLink accentIconLink">
      {img}
      <div className="grayIconTitle">{title}</div>
    </div>
  );
};

export default AccentIcon;
