import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ArrowBackStyled = () => (
  <ArrowBackIcon
    fontSize="10px"
    sx={{ color: "var(--primary)", mb: "0.1em" }}
  />
);

const InfoTypography = ({ children }) => (
  <Typography variant="p" sx={{ fontSize: 16, color: "#637381" }}>
    {children}
  </Typography>
);

const TitleTypography = ({ children }) => (
  <Typography variant="h5" sx={{ fontSize: 16, color: "#212B36" }}>
    {children}
  </Typography>
);

const AccentColor = ({ children }) => (
  <span style={{ color: "green" }}>{children}</span>
);

const PeleInfo = () => {
  return (
    <>
      <Typography
        variant="h5"
        sx={{
          color: "#212B36",
          fontSize: 16,
          fontWeight: 600,
        }}
      >
        הנחות והטבות בעשרות בתי עסק
      </Typography>
      <InfoTypography>
        קונים חכם וחוסכים כסף בכל קניה <br />
        אז, איך זה עובד?
        <br />
        באפליקציה 5 קטגוריות:
      </InfoTypography>

      <TitleTypography>
        1. "שוברים לרשתות מזון" – <AccentColor>4% הנחה!</AccentColor>
      </TitleTypography>
      <InfoTypography>
        באזור זה תוכלו לקנות שובר כספי ממבחר הרשתות הקיימות.
        <br />
        כל שעליכם לעשות הוא לבחור את הרשת המתאימה <ArrowBackStyled /> לבחור את
        השובר המתאים <ArrowBackStyled /> לבחור את כמות השוברים שברצונכם לרכוש{" "}
        <ArrowBackStyled /> ללחוץ על כפתור "הזמנה".
        <br />
        בשלב זה תועברו לתשלום מאובטח באמצעות כרטיס אשראי, לאחר התשלום תמצאו את
        השובר תחת הלשונית "השוברים שלי".
        <br />
        כעת, כל שעליכם לעשות הוא להציג את קוד השובר בקופה ו...חסכתם
      </InfoTypography>
      <TitleTypography>
        2. "תשלום בהנחה בקופה" – <AccentColor>12% הנחה!</AccentColor>
      </TitleTypography>
      <InfoTypography>
        בלחיצה על לשונית "תשלום בקופה" (בתחתית העמוד) תועברו למסך המציג את רשתות
        האופנה והמסעדות בהן ניתן לשלם בהנחה באמצעות האפליקציה.
        <br />
        בהגיעכם לקופה (בסיום הקניה/הארוחה), כל שעליכם לעשות הוא לבחור את בית
        העסק הרלוונטי <ArrowBackStyled /> להקליד את סכום החשבון{" "}
        <ArrowBackStyled /> הסכום לתשלום בפועל יופיע בהדגשה <ArrowBackStyled />{" "}
        וללחוץ על כפתור "שלם".
        <br />
        בשלב זה תועברו לתשלום מאובטח באמצעות כרטיס אשראי, לאחר התשלום יוצג קוד
        השובר אותו עליכם להציג בקופה ו...חסכתם
      </InfoTypography>
      <TitleTypography>3. "הטבות חמות"</TitleTypography>
      <InfoTypography>
        באזור זה תוכלו למצוא מגוון של דילים כגון: ארוחות בוקר, פיצות וכד'.
        <br />
        בוחרים את הדיל המתאים לכם <ArrowBackStyled /> לוחצים על כפתור "הזמנה".
        <br />
        בשלב זה תועברו לתשלום מאובטח באמצעות כרטיס אשראי, לאחר התשלום תמצאו את
        השובר תחת הלשונית "השוברים שלי".
        <br />
        בסיום הארוחה/חשבון הציגו את השובר בקופה ו....חסכתם
        <br />
        נא לשים לב להתניות המימוש המופיעות בגוף הדיל.
      </InfoTypography>
      <TitleTypography>4. "מוצרים באיסוף עצמי"</TitleTypography>
      <InfoTypography>
        באזור זה תמצאו מוצרים בהנחה ממגוון רשתות פופולאריות....
        <br />
        בוחרים את המוצר המתאים לכם <ArrowBackStyled /> לוחצים על כפתור "הזמנה".
        <br />
        בשלב זה תועברו לתשלום מאובטח באמצעות כרטיס אשראי, לאחר התשלום תמצאו את
        השובר תחת הלשונית "השוברים שלי".
        <br />
        נא לשים לב להתניות המימוש/איסוף המופיעות בגוף המוצר.
      </InfoTypography>
      <TitleTypography>5. "אטרקציות"</TitleTypography>
      <InfoTypography>
        באזור זה תוכלו לקנות שוברים למבחר אטרקציות.
        <br />
        כל שעליכם לעשות הוא לבחור את האטרקציה המתאימה <ArrowBackStyled /> לבחור
        את כמות השוברים שברצונכם לרכוש <ArrowBackStyled /> ללחוץ על כפתור
        "הזמנה".
        <br />
        בשלב זה תועברו לתשלום מאובטח באמצעות כרטיס אשראי, בסוף התהליך תמצאו את
        השובר תחת הלשונית "השוברים שלי".
        <br />
        כעת, כל שעליכם לעשות הוא להציג את קוד השובר בקופת האטרקציה ו...חסכתם
      </InfoTypography>
    </>
  );
};

const MshukInfo = () => {
  return (
    <>
      <Typography
        variant="h5"
        sx={{
          color: "#212B36",
          fontSize: 16,
          fontWeight: 600,
        }}
      >
        הנחות והטבות בעשרות בתי עסק
      </Typography>
      <InfoTypography>
        קונים חכם וחוסכים כסף בכל קניה <br />
        אז, איך זה עובד?
        <br />
        באפליקציה 4 קטגוריות:
      </InfoTypography>

      <TitleTypography>1. "תשלום בהנחה בקופה"</TitleTypography>
      <InfoTypography>
        בלחיצה על לשונית "תשלום בקופה" (בתחתית העמוד) תועברו למסך המציג את רשתות
        האופנה והמסעדות בהן ניתן לשלם בהנחה באמצעות האפליקציה.
        <br />
        בהגיעכם לקופה (בסיום הקניה/הארוחה), כל שעליכם לעשות הוא לבחור את בית
        העסק הרלוונטי <ArrowBackStyled /> להקליד את סכום החשבון{" "}
        <ArrowBackStyled /> הסכום לתשלום בפועל יופיע בהדגשה <ArrowBackStyled />{" "}
        וללחוץ על כפתור "שלם".
        <br />
        בשלב זה תועברו לתשלום מאובטח באמצעות כרטיס אשראי, לאחר התשלום יוצג קוד
        השובר אותו עליכם להציג בקופה ו...חסכתם
      </InfoTypography>
      <TitleTypography>2. "הטבות חמות"</TitleTypography>
      <InfoTypography>
        באזור זה תוכלו למצוא מגוון של דילים כגון: ארוחות בוקר, פיצות וכד'.
        <br />
        בוחרים את הדיל המתאים לכם <ArrowBackStyled /> לוחצים על כפתור "הזמנה".
        <br />
        בשלב זה תועברו לתשלום מאובטח באמצעות כרטיס אשראי, לאחר התשלום תמצאו את
        השובר תחת הלשונית "השוברים שלי".
        <br />
        בסיום הארוחה/חשבון הציגו את השובר בקופה ו....חסכתם
        <br />
        נא לשים לב להתניות המימוש המופיעות בגוף הדיל.
      </InfoTypography>
      <TitleTypography>3. "מוצרים באיסוף עצמי"</TitleTypography>
      <InfoTypography>
        באזור זה תמצאו מוצרים בהנחה ממגוון רשתות פופולאריות....
        <br />
        בוחרים את המוצר המתאים לכם <ArrowBackStyled /> לוחצים על כפתור "הזמנה".
        <br />
        בשלב זה תועברו לתשלום מאובטח באמצעות כרטיס אשראי, לאחר התשלום תמצאו את
        השובר תחת הלשונית "השוברים שלי".
        <br />
        נא לשים לב להתניות המימוש/איסוף המופיעות בגוף המוצר.
      </InfoTypography>
      <TitleTypography>4. "אטרקציות"</TitleTypography>
      <InfoTypography>
        באזור זה תוכלו לקנות שוברים למבחר אטרקציות.
        <br />
        כל שעליכם לעשות הוא לבחור את האטרקציה המתאימה <ArrowBackStyled /> לבחור
        את כמות השוברים שברצונכם לרכוש <ArrowBackStyled /> ללחוץ על כפתור
        "הזמנה".
        <br />
        בשלב זה תועברו לתשלום מאובטח באמצעות כרטיס אשראי, בסוף התהליך תמצאו את
        השובר תחת הלשונית "השוברים שלי".
        <br />
        כעת, כל שעליכם לעשות הוא להציג את קוד השובר בקופת האטרקציה ו...חסכתם
      </InfoTypography>
    </>
  );
};

const DefaultInfo = () => {
  return (
    <>
      <Typography
        variant="h5"
        sx={{
          color: "#212B36",
          fontSize: 16,
          fontWeight: 600,
        }}
      >
        הנחות והטבות בעשרות בתי עסק
      </Typography>
      <InfoTypography>
        קונים חכם וחוסכים כסף בכל קניה <br />
        אז, איך זה עובד?
        <br />
        באפליקציה 5 קטגוריות:
      </InfoTypography>

      <TitleTypography>1. "שוברים לרשתות מזון"</TitleTypography>
      <InfoTypography>
        באזור זה תוכלו לקנות שובר כספי ממבחר הרשתות הקיימות.
        <br />
        כל שעליכם לעשות הוא לבחור את הרשת המתאימה <ArrowBackStyled /> לבחור את
        השובר המתאים <ArrowBackStyled /> לבחור את כמות השוברים שברצונכם לרכוש{" "}
        <ArrowBackStyled /> ללחוץ על כפתור "הזמנה".
        <br />
        בשלב זה תועברו לתשלום מאובטח באמצעות כרטיס אשראי, לאחר התשלום תמצאו את
        השובר תחת הלשונית "השוברים שלי".
        <br />
        כעת, כל שעליכם לעשות הוא להציג את קוד השובר בקופה ו...חסכתם
      </InfoTypography>
      <TitleTypography>2. "תשלום בהנחה בקופה"</TitleTypography>
      <InfoTypography>
        בלחיצה על לשונית "תשלום בקופה" (בתחתית העמוד) תועברו למסך המציג את רשתות
        האופנה והמסעדות בהן ניתן לשלם בהנחה באמצעות האפליקציה.
        <br />
        בהגיעכם לקופה (בסיום הקניה/הארוחה), כל שעליכם לעשות הוא לבחור את בית
        העסק הרלוונטי <ArrowBackStyled /> להקליד את סכום החשבון{" "}
        <ArrowBackStyled /> הסכום לתשלום בפועל יופיע בהדגשה <ArrowBackStyled />{" "}
        וללחוץ על כפתור "שלם".
        <br />
        בשלב זה תועברו לתשלום מאובטח באמצעות כרטיס אשראי, לאחר התשלום יוצג קוד
        השובר אותו עליכם להציג בקופה ו...חסכתם
      </InfoTypography>
      <TitleTypography>3. "הטבות חמות"</TitleTypography>
      <InfoTypography>
        באזור זה תוכלו למצוא מגוון של דילים כגון: ארוחות בוקר, פיצות וכד'.
        <br />
        בוחרים את הדיל המתאים לכם <ArrowBackStyled /> לוחצים על כפתור "הזמנה".
        <br />
        בשלב זה תועברו לתשלום מאובטח באמצעות כרטיס אשראי, לאחר התשלום תמצאו את
        השובר תחת הלשונית "השוברים שלי".
        <br />
        בסיום הארוחה/חשבון הציגו את השובר בקופה ו....חסכתם
        <br />
        נא לשים לב להתניות המימוש המופיעות בגוף הדיל.
      </InfoTypography>
      <TitleTypography>4. "מוצרים באיסוף עצמי"</TitleTypography>
      <InfoTypography>
        באזור זה תמצאו מוצרים בהנחה ממגוון רשתות פופולאריות....
        <br />
        בוחרים את המוצר המתאים לכם <ArrowBackStyled /> לוחצים על כפתור "הזמנה".
        <br />
        בשלב זה תועברו לתשלום מאובטח באמצעות כרטיס אשראי, לאחר התשלום תמצאו את
        השובר תחת הלשונית "השוברים שלי".
        <br />
        נא לשים לב להתניות המימוש/איסוף המופיעות בגוף המוצר.
      </InfoTypography>
      <TitleTypography>5. "אטרקציות"</TitleTypography>
      <InfoTypography>
        באזור זה תוכלו לקנות שוברים למבחר אטרקציות.
        <br />
        כל שעליכם לעשות הוא לבחור את האטרקציה המתאימה <ArrowBackStyled /> לבחור
        את כמות השוברים שברצונכם לרכוש <ArrowBackStyled /> ללחוץ על כפתור
        "הזמנה".
        <br />
        בשלב זה תועברו לתשלום מאובטח באמצעות כרטיס אשראי, בסוף התהליך תמצאו את
        השובר תחת הלשונית "השוברים שלי".
        <br />
        כעת, כל שעליכם לעשות הוא להציג את קוד השובר בקופת האטרקציה ו...חסכתם
      </InfoTypography>
    </>
  );
};

const infoContentComponents = {
  pele: PeleInfo,
  mshuk: MshukInfo,
  default: DefaultInfo,
};

export default function InfoDrawer({ open, handleClose, projectName }) {
  const InfoContentComponent =
    infoContentComponents[projectName] || infoContentComponents.default;
  return (
    <Drawer
      className="infoWrapper"
      PaperProps={{
        sx: {
          width: "350px",
          left: "calc(50vw - 175px)",
          top: "12.5%",
          height: "75%",
          margin: "auto",
          borderRadius: "10px",
          padding: "10px",
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <IconButton
        sx={{
          justifyContent: "end",
          p: 0,
          width: "25px",
          position: "absolute",
          top: 10,
          right: 10,
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <Box
        className="container"
        gap={3}
        p={2}
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "right",
          justifyContent: "center",
        }}
      >
        <InfoContentComponent />
      </Box>
    </Drawer>
  );
}
