import { MerchantsActionTypes } from "./merchant.types";
import { axiosNoTokenInstance } from "../../axios.config";
import {
  fashionCategoryId,
  restaurantsCategoryId,
} from "../../../helpers/hardcodedIds";

export const getMerchants = () => (dispatch) => {
  dispatch({ type: MerchantsActionTypes.GET.GET_MERCHANT_PENDING });

  axiosNoTokenInstance
    .get(`/merchant/all`, {
      params: {
        relations: ["categories"],
      },
    })
    .then((res) => {
      dispatch({
        type: MerchantsActionTypes.GET.GET_MERCHANT_SUCCESS,
        payload: res.data.payload,
      });
    })
    .catch((error) =>
      dispatch({
        type: MerchantsActionTypes.GET.GET_MERCHANT_ERROR,
        payload: error.response.data,
      })
    );
};

export const getMerchantsBySearch = (search) => (dispatch) => {
  dispatch({ type: MerchantsActionTypes.GET.GET_MERCHANTS_BY_SEARCH_PENDING });

  axiosNoTokenInstance
    .get(`/merchant/all`, {
      params: {
        relations: ["categories"],
        search,
      },
    })
    .then((res) =>
      dispatch({
        type: MerchantsActionTypes.GET.GET_MERCHANTS_BY_SEARCH_SUCCESS,
        payload: res.data.payload,
      })
    )
    .catch((error) =>
      dispatch({
        type: MerchantsActionTypes.GET.GET_MERCHANTS_BY_SEARCH_ERROR,
        payload: error.response.data,
      })
    );
};

export const getMerchantById = (merchantId) => (dispatch) => {
  dispatch({ type: MerchantsActionTypes.GET.GET_MERCHANT_BY_ID_PENDING });

  axiosNoTokenInstance
    .get(`/merchant?id=${merchantId}`)
    .then((res) => {
      dispatch({
        type: MerchantsActionTypes.GET.GET_MERCHANT_BY_ID_SUCCESS,
        payload: res.data.payload,
      });
    })
    .catch((error) =>
      dispatch({
        type: MerchantsActionTypes.GET.GET_MERCHANT_BY_ID_ERROR,
        payload: error.response.data,
      })
    );
};

export const clearMerchant = () => (dispatch) =>
  dispatch({ type: MerchantsActionTypes.CLEAR_MERCHANT });

export const getMerchantCategories = (merchantId) => (dispatch) => {
  dispatch({
    type: MerchantsActionTypes.GET.GET_MERCHANT_BY_CATEGORY_ID_PENDING,
  });

  axiosNoTokenInstance
    .get(`/merchant/${merchantId}/categories`)
    .then((res) =>
      dispatch({
        type: MerchantsActionTypes.GET.GET_MERCHANT_BY_CATEGORY_ID_SUCCESS,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: MerchantsActionTypes.GET.GET_MERCHANT_BY_CATEGORY_ID_ERROR,
        payload: error.response.data,
      })
    );
};

export const getMerchantProducts = (merchantId) => (dispatch) => {
  dispatch({ type: MerchantsActionTypes.GET.GET_MERCHANT_PRODUCTS_PENDING });

  axiosNoTokenInstance
    .get(`/merchant?id=${merchantId}&relations=products`)
    .then((res) =>
      dispatch({
        type: MerchantsActionTypes.GET.GET_MERCHANT_PRODUCTS_SUCCESS,
        payload: res.data.payload.products,
      })
    )
    .catch((error) =>
      dispatch({
        type: MerchantsActionTypes.GET.GET_MERCHANT_PRODUCTS_ERROR,
        payload: error.response.data,
      })
    );
};

export const clearMerchantProducts = () => (dispatch) =>
  dispatch({ type: MerchantsActionTypes.CLEAR_MERCHANT_PRODUCTS });

const getMerchantsByProductCategoryID =
  (productCategoryId, type) => (dispatch) => {
    dispatch({ type: MerchantsActionTypes.GET[`${type}_PENDING`] });

    axiosNoTokenInstance
      .get(`/product-category?id=${productCategoryId}`, {
        params: {
          relations: ["merchants"],
        },
      })
      .then((res) =>
        dispatch({
          type: MerchantsActionTypes.GET[`${type}_SUCCESS`],
          payload: res.data.payload.merchants,
        })
      )
      .catch((error) =>
        dispatch({
          type: MerchantsActionTypes.GET[`${type}_ERROR`],
          payload: error.response.data,
        })
      );
  };

export const getMerchantsFashion = () =>
  getMerchantsByProductCategoryID(fashionCategoryId, "GET_MERCHANTS_FASHION");

export const getMerchantsRestaurants = () =>
  getMerchantsByProductCategoryID(
    restaurantsCategoryId,
    "GET_MERCHANTS_RESTAURANTS"
  );
