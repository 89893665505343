import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { ERROR_STATUSES_MESSAGES } from "../../../helpers/hardcodedIds";
import Header from "../../header/Header";
import "./styles.css";

const Index = () => {
  const { orderId } = useParams();
  const location = useLocation();
  const { status } = location.state;

  const message =
    ERROR_STATUSES_MESSAGES[status] || ERROR_STATUSES_MESSAGES.default;

  return (
    <div className="pageTemplate">
      <Header isFull={false} />
      <div className="container">
        <div className="pelecard-page__order pelecard__order_error">
          <h3 className="pelecard-page__title">{message}</h3>
          <div className="pelecard-page__info">Order Id: {orderId}</div>
        </div>
      </div>
    </div>
  );
};

export default Index;
