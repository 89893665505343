import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Header from "../header/Header";
import {
  getProductCategory,
  getProductsByCategoryId,
} from "../../redux/API/productCategories/productCategories.action";
import defaultImg from "../../assets/images/categories/recommended-min.jpg";
import ProductCard from "../product/ProductCard";
import LoaderPrimary from "../UI/LoaderPrimary";

export default function CategoryProduct() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const category = useSelector(
    (state) => state.productCategories.productCategory
  );

  const isProductsCategoryPending = useSelector(
    (state) => state.productCategories.isProductsCategoryPending
  );
  const categoryProducts = useSelector(
    (state) => state.productCategories.productsCategory
  );

  useEffect(() => {
    dispatch(getProductCategory(id));
    dispatch(getProductsByCategoryId(id));
  }, [dispatch, id]);

  return (
    <div>
      <Header />

      <div className="pageTemplate">
        <div className="container">
          <div
            className="PageBgHeading"
            style={{
              backgroundImage: `url(${category.bannerImage || defaultImg[0]})`,
            }}
          >
            <h5 className="PageBgHeading-title">{t(category?.title)}</h5>
          </div>

          {!isProductsCategoryPending ? (
            <ul className="product-Module">
              {categoryProducts?.length > 0
                ? categoryProducts.map((product) => (
                    <ProductCard product={product} key={product.id} />
                  ))
                : t("No products")}
            </ul>
          ) : (
            <ul
              className="product-Module"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <LoaderPrimary />
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
