import React from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { useNavigate } from "react-router-dom";
import placeholderImg from "../../assets/images/categories/recommended-min.jpg";
import { shortenString } from "../../helpers/shortenString";
import LoaderPrimary from "../UI/LoaderPrimary";

const RowOfMerchants = ({ items = [], onClick }) => {
  return (
    <ul className={`categoryList one`}>
      {items.length ? (
        items.map((item) => (
          <li
            className="categoryList__block"
            onClick={() => onClick(item)}
            key={item.id}
          >
            <div className="category-box__img">
              <img
                src={
                  `${process.env.REACT_APP_API_URL}/upload${item.mainImageURL}` ||
                  placeholderImg
                }
                className="img-fluid"
                alt="merchant logo"
              />
            </div>
            {item.length > 0 ? (
              <h6 className="category-box__title up">
                {item[0].network?.name || item.title}
              </h6>
            ) : (
              <h6 className="category-box__title">
                {shortenString(item.title, 20)}
              </h6>
            )}
          </li>
        ))
      ) : (
        <LoaderPrimary />
      )}
    </ul>
  );
};

export default function MerchantsStripe({ title, items, isMarkets }) {
  const navigate = useNavigate();

  const handleClick = (item) => {
    navigate(`/${isMarkets ? "foods" : "market"}/${item.id}`);
  };
  return (
    <>
      <div className="row align-items-center">
        <div className="col-7">
          <h6 className="module-heading__title">{title}</h6>
        </div>
        <div
          className="col-5 text-right isLink"
          onClick={() => {
            navigate(isMarkets ? "/vendors" : "/wallet");
          }}
        >
          הכל
          <ArrowLeftIcon />
        </div>
      </div>

      {!isMarkets && (
        <>
          <div className="vendorType">מסעדות</div>
          <RowOfMerchants items={items.food} onClick={handleClick} />
          <div className="vendorType">אופנה</div>
          <RowOfMerchants items={items.other} onClick={handleClick} />
        </>
      )}
      {isMarkets && <RowOfMerchants items={items} onClick={handleClick} />}
    </>
  );
}
