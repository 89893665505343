import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Drawer from "@mui/material/Drawer";
import { Box, Button, Stack, Typography } from "@mui/material";
import Logo from "../logo/Logo";

const ConfirmDialog = ({
  open,
  handleClose,
  handleConfirm,
  text1,
  text2,
  text3,
  buttonText = "בסדר, הבנתי",
}) => {
  return (
    <Drawer
      className="infoWrapper"
      PaperProps={{
        sx: {
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%) !important",
          width: "80%",
          maxWidth: "350px",
          height: "40%",
          minHeight: 400,
          margin: "auto",
          borderRadius: "10px",
          padding: "10px",
          zIndex: "10000",
          justifyContent: "center",
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <IconButton
        sx={{
          justifyContent: "end",
          p: 0,
          width: "25px",
          position: "absolute",
          top: 10,
          right: 10,
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>

      <Box
        className="container"
        gap={3}
        p={2}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Logo square />
        <Stack direction="row" alignItems="center" gap={1}>
          <NotificationsIcon />
          {!!text1 && (
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                color: "#212B36",
                fontSize: 18,
                fontWeight: 700,
              }}
            >
              {text1}
            </Typography>
          )}
        </Stack>
        {!!text2 && (
          <Typography
            variant="p"
            sx={{ textAlign: "center", fontSize: 16, color: "#637381" }}
          >
            {text2}
          </Typography>
        )}
        {!!text3 && (
          <Typography
            variant="p"
            sx={{ textAlign: "center", fontSize: 16, color: "#637381" }}
          >
            {text3}
          </Typography>
        )}
        <Button
          onClick={handleConfirm}
          variant="contained"
          sx={{
            fontWeight: 700,
            borderRadius: "50px",
            background: "#212B36",
            boxShadow: "none",
            "&:hover": {
              background: "var(--primary-dark)",
              boxShadow: "none",
            },
          }}
        >
          {buttonText}
        </Button>
      </Box>
    </Drawer>
  );
};

export default ConfirmDialog;
