import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  getProductById,
  openSidebar,
  fastCheckout,
} from "../../redux/actions-exporter";
import QuantitySelector from "../quantitySelector";
import { shortenString } from "../../helpers/shortenString";

import Header from "../header/Header";
import "react-toastify/dist/ReactToastify.css";
import "../category/category.css";
import { marketsCards, oldPricesMap } from "../../helpers/hardcodedIds";
import { MATBEOT_ENABLED } from "../../config";
import { formatPrice } from "../../helpers/formatPrice";

const DISCOUNT = 0.12;

export default function ProductDetails() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const isSuper = marketsCards.indexOf(id) !== -1;
  const user = useSelector((state) => state?.user?.user);
  const product = useSelector((state) => state.product.product);
  const productIsPending = useSelector(
    (state) => state.product.productIsPending
  );

  const handleInputFocus = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [numbersOfItems, setNumbersOfItems] = useState(1);
  const [userPrice, setUserPrice] = useState("");
  const [error, setError] = useState("");
  // const [merchants, setMerchants] = useState([]);
  // const [chain, setChain] = useState([]);
  const productPrice = product?.prices?.find(
    (price) => price.type === "price"
  )?.value;
  const basePrice = product?.prices?.find(
    (price) => price.type === "base_price"
  )?.value;

  const oldPrice = oldPricesMap[product.id] || basePrice;

  const minPrice = product?.prices?.find(
    (price) => price.type === "min_price"
  )?.value;
  const maxPrice = product?.prices?.find(
    (price) => price.type === "max_price"
  )?.value;

  useEffect(() => {
    if (product.id !== id) dispatch(getProductById(id));
  }, [dispatch, id, product.id]);

  const handleChangePrice = (e) => {
    const value = e.target.value;
    const reg = /^\d+$/;
    setError("");
    if (
      value === "" ||
      (reg.test(value) && parseInt(value) < (maxPrice || 1500))
    ) {
      setUserPrice(value);
    } else {
      e.preventDefault();
      return setError(`הכנס סכום בין ${minPrice || 1} ל-${maxPrice || 1500}`);
    }
  };

  // LEGACY FOR MERCHANTS-NETWORKS
  // useEffect(() => {
  //   const list = [];
  //   product?.merchants?.map((merchant) => {
  //     if (
  //       !list.includes(merchant) &&
  //       !merchant.title.includes("סגור") &&
  //       !merchant.title.includes("איטלקיה") &&
  //       merchant.type !== "hr"
  //     )
  //       list.push(merchant);
  //   });

  //   const groups = Object.values(
  //     list.reduce((acc, curr) => {
  //       const firstWord = curr.network?.name || curr.title;
  //       if (!acc[firstWord]) {
  //         acc[firstWord] = [];
  //       }

  //       acc[firstWord].push(curr);
  //       return acc;
  //     }, {})
  //   );
  //   setMerchants(groups.sort((a, b) => b.length - a.length));
  // }, [product]);

  const handleChangeQuantity = (e) => setNumbersOfItems(e.target.value);

  const handleAddProduct = (product) => {
    if (
      !productPrice &&
      (parseInt(userPrice) >= (maxPrice || 1500) ||
        parseInt(userPrice) <= (minPrice || 1))
    ) {
      return setError(`הכנס סכום בין ${minPrice || 1} ל-${maxPrice || 1500}`);
    }
    if (!user) {
      return dispatch(openSidebar());
      // navigate("/");
    }
    const data = productPrice
      ? {
          productId: id,
          quantity: numbersOfItems,
          priceAtPurchase: productPrice,
        }
      : {
          productId: id,
          amount: userPrice,
          priceAtPurchase: userPrice * (1 - DISCOUNT),
        };

    setLoad(true);
    dispatch(
      fastCheckout(data, (data) => {
        setLoad(false);
        const { orderId, paymentURL } = data;
        navigate("/pay", {
          state: { paymentURL, orderId },
        });
      })
    );
  };

  useEffect(() => {
    if (product && product?.type === "gift") {
      navigate("/gift", {
        state: { product },
      });
    }
  }, [product]); //eslint-disable-line
  return (
    <div>
      <Header isProduct={true} reduce={userPrice * DISCOUNT || 0} />
      <div className="productDetails">
        <div className="container">
          {product.id === id && !productIsPending && (
            <div className="row">
              <div className="col-lg-12">
                <div className="productDetails-info">
                  <div className="productDetails-heading">
                    <h5 className="productDetails-title">{product.title}</h5>
                    <h6 className="productDetails-price">
                      {productPrice ? (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {oldPrice && (
                            <span
                              style={{
                                textDecoration: "line-through",
                                color: "var(--secondary)",
                              }}
                            >
                              ₪{oldPrice}
                            </span>
                          )}
                          <span>₪{productPrice}</span>
                        </div>
                      ) : (
                        <>
                          <input
                            type="tel"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            placeholder="סכום החשבון:"
                            value={userPrice}
                            onChange={handleChangePrice}
                            className="priceInput"
                            onFocus={handleInputFocus}
                          />{" "}
                          ₪
                        </>
                      )}
                    </h6>
                  </div>
                  <p
                    style={{ color: "red", textAlign: "center", marginTop: 4 }}
                  >
                    {error || " "}
                  </p>
                  {!productPrice && (
                    <h5
                      style={{
                        fontWeight: 900,
                        margin: "16px 0",
                        textAlign: "center",
                        visibility: userPrice > 0 ? "visible" : "hidden",
                      }}
                    >
                      {MATBEOT_ENABLED ? (
                        <span>
                          לתשלום: {formatPrice(userPrice * (1 - DISCOUNT))} ₪ +{" "}
                          {formatPrice(+(userPrice * DISCOUNT))} מטבעות פלא
                        </span>
                      ) : (
                        <span>
                          לתשלום לאחר 12% הנחה:{" "}
                          {formatPrice(userPrice * (1 - DISCOUNT))} ₪
                        </span>
                      )}
                    </h5>
                  )}
                  {productPrice && (
                    <QuantitySelector
                      onChange={handleChangeQuantity}
                      minValue={1}
                      maxValue={5}
                      value={numbersOfItems}
                    />
                  )}
                  <div className="productDetails-btns">
                    <LoadingButton
                      className="addcart_btn"
                      onClick={() => handleAddProduct(product)}
                      loading={load}
                    >
                      <span>{t("addToCart")}</span>
                    </LoadingButton>
                  </div>

                  <div className="productDetails-content">
                    <h6 className="productDetails-contentTitle">
                      {t("description")}
                    </h6>
                    <p className="productDetails-contentText">
                      {product?.content || ""}
                    </p>
                    {isSuper && (
                      <p>
                        ניתן להשתמש יותר משובר אחד בכל קניה
                        <br /> *במידה ונשארה יתרה בשובר - ניתן להשתמש עד הניצול
                        מלא
                      </p>
                    )}
                  </div>

                  <ul className="productDetails-List">
                    {product?.sku && (
                      <li className="productDetails-ListItem">
                        <strong>{t("SKU")}: </strong>
                        {product.sku}
                      </li>
                    )}
                    <li className="productDetails-ListItem">
                      <strong>{t("categories")}: </strong>
                      {product?.categories?.reduce(
                        (p, c, i) => p + (i > 0 ? ", " : "") + c.title,
                        ""
                      ) || t("No categories")}
                    </li>
                  </ul>
                  {product?.merchants?.length ? (
                    <>
                      <p className="productDetails-brandName">
                        {/* {t("merchants")} */}
                        חנויות מכבדות
                      </p>
                      <ul className="categoryList">
                        {product.merchants.map((item) => (
                          <li
                            key={item.id}
                            className="categoryList__block"
                            onClick={() => {
                              navigate(`/vendor/${item.id}`);
                            }}
                          >
                            <div className="category-box__img">
                              <img
                                src={`${process.env.REACT_APP_API_URL}/upload${item.mainImageURL}`}
                                className="img-fluid"
                                alt="logo"
                              />
                            </div>
                            {/* 
                          LEGACY FOR MERCHANTS-NETWORKS
                          {item.length > 1 && (
                            <>
                              <h6 className="category-box__title up">
                                {item.network?.name}
                              </h6>
                              <h6
                                className="category-box__title down"
                                onClick={() => setChain(item)}
                              >
                                {item.length > 1 && (
                                  <>
                                    סניפים
                                    <ArrowDropDownIcon />
                                  </>
                                )}
                              </h6>
                            </>
                          ) } */}

                            <h6 className="category-box__title">
                              {shortenString(item.title, 20)}
                            </h6>

                            {/* LEGACY FOR MERCHANTS-NETWORKS 
                          {chain === item && (
                            <div className="chains">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  fontSize: "larger",
                                  fontWeight: "bold",
                                  padding: "10px 0",
                                }}
                              >
                                {"סניפי "}
                                {chain[0].network.name}
                                <IconButton
                                  sx={{
                                    justifyContent: "end",
                                    p: 0,
                                  }}
                                  onClick={() => setChain([])}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </div>
                              <div
                                style={{
                                  overflowY: "scroll",
                                }}
                              >
                                {chain?.map((branch, i) => (
                                  <>
                                    {i !== 0 && (
                                      <hr
                                        style={{
                                          margin: "5px",
                                          borderColor: "#eee",
                                        }}
                                      />
                                    )}
                                    <div
                                      className="chain"
                                      onClick={() => {
                                        navigate(`/vendor/${branch.id}`, {
                                          state: { id: branch.id },
                                        });
                                      }}
                                    >
                                      {branch.title}
                                    </div>
                                  </>
                                ))}
                              </div>
                            </div>
                          )} */}
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    t("No merchants")
                  )}
                </div>
              </div>
              <div className="productDetails-img">
                {product?.discount && (
                  <div className="product-box__discount single">
                    <span className="product-box__off">
                      {product.discountType ? "" : "-"}
                      {product.discount}
                      {product.discountType}
                    </span>
                  </div>
                )}

                <img
                  src={`${process.env.REACT_APP_API_URL}/upload${product?.mainImageURL}`}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
