import * as React from "react";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import "./rules.css";
import { Container } from "@mui/material";

const theme = createTheme({
  direction: "rtl",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const rulesByProject = {
  pele: (
    <ol>
      <ol>
        <li>
          <h5>כללי</h5>
          האתר/אפליקציה מופעל על ידי מפעיל האתר כהגדרתו להלן, המנהל חנות
          וירטואלית לציבור הגולשים ברשת האינטרנט בישראל. הוראות תקנון זה יחולו
          על כל גלישה באתר, ובכלל זה על כל גולש הנכנס לאתר ו/או מבצע ו/או
          מעוניין לבצע הזמנה באמצעות האתר (ובכלל האמור באמצעות אפליקציה או בכל
          דרך מקוונת אחרת), ולפיכך הנך מתבקש לקרוא את התקנון בעיון, שכן הוראותיו
          תחייבנה ותהוונה את הבסיס המשפטי בכל דין ודברים בינך לבין מפעיל האתר
          ו/או מנהליו ו/או "פלא", ובכניסתך לאתר והמשך גלישה בו הנך מצהיר ומתחייב
          כי הנך מודע לכך, מאשר זאת, מצהיר ומתחייב כי הנך מודע לתקנון האתר ומאשר
          אותו. הרשמה לאתר ומסירת פרטים אישיים הינה זכות ולא חובה.
          <br />
          למנהלי האתר שמורה הזכות, בכל עת וללא צורך בהודעה מראש, לשנות מעת לעת,
          על פי שיקול דעתם הבלעדי, תקנון זה ו/או את תנאי השימוש באתר ו/או את
          תכניו של האתר, המידע המפורסם בו, השירותים ו/או המוצרים המוצעים
          במסגרתו, וכל הנובע משינויים אלה. לכן, באחריות המשתמש באתר לקרוא תנאי
          שימוש אלה בכל כניסה לאתר, ולא תהיה לו כל טענה ו/או תביעה ו/או דרישה
          כלפי מנהל האתר בקשר לכך. 
          <br />
          כל שינוי ייכנס לתוקף מיד עם פרסומו באתר, והוא יחול על כל משתמשי האתר
          ו/או על כל מי שמחזיק בשוברים שהונפקו ע"י בנפיטס.
          <br />
          הגלישה באתר אינה כרוכה בתשלום, אולם רכישת מוצרים ו/או שירותים באתר
          כרוכה בתשלום ובהזנת פרטים. 
        </li>
        <li>
          <h5>הגדרות</h5>
          "מנהל/י האתר" ו/או "מפעיל האתר"- חברת בנפיטס מועדוני צרכנות בע"מ. וכל
          הבאים מטעמה
          <br />
          "שובר"- שובר המעניק לזכאי לאחוז בו, בכפוף לפירעון התמורה בעדו, זכות חד
          פעמית, הנרכשת במסגרת האתר, לקבלת מוצר או שירות תמורת מימושו, כמפורסם
          וכמפורט ביחס לשובר, אלא אם כן מדובר בתו שי או בשובר מתנה ("גיפט קארד")
          בעלי ערך כספי נקוב, ובמקרה כזה ניתן יהיה לממשו מספר פעמים (אך לא בעסקת
          תשלומים נדחים), עד לפירעון מלוא הערך הנקוב
          <br />
          השובר יכול להיות בעל שווי כספי (ערך נקוב) ו/או שובר מתנה המיועד לרכישת
          מוצרים ספציפיים או קבלת שירות ספציפי או חוויות ברשתות מובילות בארץ
          (כגון קופון, למשל), על פי המפורסם באתר זה.
          <br />
          השובר יישלח ישלח לאפליקציה ו/או בדוא"ל ו/או באופן מודפס, לפי בחירת
          הגולש באתר.
          <br />
          "משתמש" או "משתמש באתר"- כל אדם בגיר (מעל גיל 18) העושה שימוש כלשהו
          באתר
        </li>
        <li>
          <h5> תנאים כלליים</h5>
          כל משתמש/ גולש מצהיר כי הוא מקבל על עצמו את הוראות תקנון זה ותנאי
          השימוש באתר המפורטים להלן (להלן: "תקנון האתר" או "התקנון"), וכי לא תהא
          לו ו/או למי מטעמו כל טענה ו/או תביעה כנגד בעלי האתר ו/או מפעיליו ו/או
          מי מטעמם מלבד טענות הקשורות בהפרת התחייבויות בנפיטס ו/או בעלי האתר
          ו/או מפעיליו על פי תקנון זה. למען הסר ספק, המוצרים ו/או השירותים
          המוצעים לרכישה במסגרת האתר, מוצעים למכירה על ידי בתי העסק השונים
          המזוהים בכל אחת מעסקאות המכירה ולא  על ידי בנפיטס ו/או האתר ו/או
          מנהליו (למעט השוברים עצמם, אותם מנפיקה בנפיטס). בית עסק הרלוונטי בקשר
          עם מימוש השובר הוא יהיה הצד שיישא באחריות הבלעדית בגין השירות ו/או
          המוצרים ו/או הרכישה המתבצעת באמצעות השובר, וכל הנובע מהם.
          <br />
          מנהל האתר שומר על זכותו לשנות תקנון זה מזמן לזמן.
          <br />
          רק הכללים הכלולים בתקנון זה, כפי שהוא מעודכן באתר, יחייבו את מנהל האתר
          כלפי משתמשי וגולשי האתר.
        </li>
        <li>
          <h5>תנאי השימוש באתר</h5>
          הרשאים לעשות שימוש באתר הינם כל אדם תושב מדינת ישראל בן 18 ומעלה (וכן
          חברה המאוגדת כדין לפי חוקי מדינת ישראל והרשומה ברשם החברות בישראל).
          זהו, מבלי לגרוע מהאמור לעיל, תנאי מוקדם לביצוע הזמנה באתר. רכישת
          המוצרים באתר הינה באמצעות כרטיס אשראי.
          <br />
          כל רכישה באתר מחייבת לקבל את אישור הגורם הרלוונטי (כגון חברת כרטיסי
          האשראי), אשר דרכה התחייב הלקוח לביצוע התשלום עבור עסקת הרכישה של
          המוצרים ו/או השירותים שמציע האתר.
          <br />
          המוצרים והשירותים המוצעים באתר מיועדים לשימוש הפרטי והאישי של הלקוחות
          ולא למטרות מסחריות ו/או סיטונאות. מנהל האתר רשאי למנוע מלקוח כלשהו את
          השתתפות והשימוש באתר, באופן זמני או לצמיתות, על פי שיקול דעתו הסביר,
          בכפוף לכל דין, לרבות אם יהא סבור כי הלקוח עלול לסחור בהם או להעביר
          באופן אחר לצד שלישי, וזאת מבלי צורך לנמק החלטתו ו/או מבלי להודיע ללקוח
          על כך מראש. מנהל האתר אף שומר לעצמו את הזכות, על פי שיקול דעתו הסביר
          ובכפוף לכל דין, לבטל את רכישתו של המשתמש בנסיבות אלו ו/או בנסיבות
          אחרות, בהן, למשל לדעת מנהל האתר, נמסרו פרטים שגויים באופן מכוון ו/או
          המשתמש נהג בניגוד להוראות תקנון זה ו/או פרטי כרטיס האשראי עמו בוצע
          התשלום נחסם ו/או לדעת מנהל האתר קיים חשש סביר כי המשתמש עשה שימוש
          העלול לפגוע בצדדים שלישיים ו/או במנהל האתר. 
          <br />
          אין בחסימה, בהפסקה או בהגבלת השירותים או ביטולם, כאמור לעיל, כדי לגרוע
          מחובתו של הלקוח לנהוג בכל עת בהתאם לתנאי שימוש אלה. כל שימוש של הלקוח
          באתר, לרבות בשירותים ובתכנים שבאתר, בניגוד להוראות מנהל האתר ו/או
          בניגוד לתנאי שימוש אלה ו/או בניגוד להוראות כל דין, יזכה את מנהל האתר
          ו/או צדדים שלישיים בעלי זכויות בשירותים ובתכנים לפי העניין, בנקיטת אחד
          או יותר מן הסעדים הקבועים בתקנון זה כלפי הלקוח, וזאת מבלי לגרוע מכל
          סעד אחר העומד לזכות מנהל האתר ו/או צדדים שלישים בעלי זכויות בשירותים
          ובתכנים על פי כל דין. כל גולש אשר נרשם לאתר מתבקש לאשר את התקנון ע"מ
          להשלים את האישור. גולשים אשר מבצעים רישום באמצעות גוגל או פייסבוק בדף
          הרישום מאשרים באופן אוטומטי את התקנון ותנאי השימוש באתר.
        </li>
        <li>
          <h5>הרכישה באתר, השובר ומימושו</h5>
          מובהר בזאת כי תנאי סף לקבלת שובר ו/או למימושו, הינה כי תמורתו המלאה
          שולמה.  התשלום במסגרת רכישות שביצע המשתמש באתר ייעשה על ידי המשתמש,
          באמצעות הזנת פרטי כרטיס אשראי לחיוב וכן מסירת פרטים אישיים.
          <br />
          כל הוראות תקנון זה יוחלו, בהתאמה ובשינויים המחויבים, על כל שובר שהונפק
          ע"י בנפיטס, אף אם נרכש שלא דרך האתר.
          <br />
          פרטי הרכישה יועברו לאישור חברת כרטיס האשראי (או הגורם המשלם), אשר תאשר
          או תדחה את העסקה. רק עסקאות אשר אושרו תזכינה את המשתמש בקבלת השובר
          ומימושו. בכל מקרה של דחיית העסקה על ידי חברת האשראי (או הגורם המשלם),
          תשלח למשתמש הודעה מתאימה, והוא יידרש לפרוע את התשלום בכל דרך אחרת בו
          יורה לו מנהל האתר. במקרה כזה, השובר, אם וככל שנשלח, יהיה חסום לשימוש
          וזאת עד להסדרת התשלום, ולמשתמש ו/או לכל צד ג' שהוא לא תהיה כל טענה
          ו/או דרישה ו/או תביעה בעניין.  כמו כן, מובהר ומוסכם בזאת כי, בכל מקרה,
          למנהל האתר שמורה הזכות לסרב ו/או שלא לקבל ו/או לבטל עסקה, אף אם אושרה
          על ידי חברת האשראי, על פי שיקול דעתו הבלעדי וללא צורך לנמק את החלטתו.
          לכל שובר תיקבע תקופת מימוש, במסגרתה תוכל לממשו. המחירים המפורסמים באתר
          ביחס לשוברים הינם מחירים כוללים, אלא אם כן צוין במפורש אחרת. השובר
          יישלח למשתמש האתר ו/או לצד ג' כלשהו בהתאם לבחירת משתמש האתר, אשר
          באחריותו יהיה להזין את פרטיו וכל הנובע מכך, באמצעים עליו יחליט מנהל
          האתר, ומימוש השובר ייעשה אף הוא באופן בו יחליט עליו מנהל האתר, מעת
          לעת.
          <br />
          השובר יכובד בבתי העסק כפי שיפורטו באתר זה. יש לפנות טלפונית לבית העסק
          הנבחר ולוודא ישירות את תנאי מימוש השובר.
          <br />
          השובר אינו מיועד לשימוש של משלם מסוים, וניתן להעבירו גם לשימוש כל צד
          ג' שהוא, ובלבד שתמורתו המלאה שולמה לבנפיטס.
          <br />
          הסכום המירבי שניתן לרכוש/ לצבור/ לממש ביחס לכל שובר מוגבל עד לסך של
          1,500 ₪ ואינו ניתן לטעינה נוספת.
          <br />
          החיוב באמצעות השובר יהיה מיידי ולא ניתן לבצע באמצעותו עסקאות תשלומים.
          <br />
          במקרה של חדלות פירעון של בית העסק אשר אמור היה לספק את השירות ו/או
          המוצר נשוא השובר, על המחזיק בשובר לפנות לחברת בנפיטס, אשר תזכה אותי
          בגין הערך הנקוב של השובר שלא מומש.
          <br />
          במקרה של שוברים עם ערך צבור, הסכום הנקוב יהיה ניתן למימוש באופן אחיד
          לגבי כל בתי העסק הרלוונטיים לאותו השובר.
          <br />
          על שובר מסוג "שובר מתנה" כהגדרתו בחוק הגנת הצרכן- ניתן יהיה למימוש
          בהתאם להוראות סעיף 14ז. לחוק הגנת הצרכן.
          <br />
          על שובר מסוג "תו קניה" כהגדרתו בחוק הגנת הצרכן- ניתן יהיה למימוש בהתאם
          להוראות סעיף 14ח. לחוק הגנת הצרכן.
          <br />
          באחריותך המלאה והבלעדית לממש השובר במסגרת תקופת המימוש.
          <br />
          תוקף שובר שהינו מסוג "תו קנייה" כהגדרתו בחוק הגנת הצרכן- חמש שנים;
          תוקף שובר שהינו "שובר מתנה" כהגדרתו בחוק הגנת הצרכן- שנתיים תוקף שובר
          מסוג "קופון" לקבלת הטבה ו/או מתנה ו/או מוצר ו/או שירות ספציפי הינו כפי
          שמפורט בתנאים המתייחסים לשובר מסוג קופון.
          <br />
          *על אף האמור, שוברים שתוקפם פג יוארכו לאחר פניית הלקוח אל בנפיטס,
          ויהיו ניתנים למימוש עד לתאריך 31/12/2030
          <br />
          במקרה של אובדן או גניבה של השובר, על הלקוח (מי שנחזה כמי שהחזיק כדין
          בשובר) לפנות לחברת בנפיטס, אשר מצדה תנסה, במאמצים סבירים, לשחזר את
          השובר והיתרה הצבורה במערכותיה, ובלבד שהשובר לא מומש ע"י צד ג' כלשהו
          וכי קיימת יתרת זכות בשובר. האמור לא יחול על שובר נייר (מודפס).
          <br />
          חובה על הלקוח שהזמין שובר הכרוך בפעילות או שירות, לבצע אישור הזמנה
          טלפוני מול מפעיל החוויה לפחות יום לפני התאריך המתוכנן על מנת לוודא מזג
          אויר ותנאים טכניים.
          <br />
          עדכונים ושינויים - בתי העסק, שעות פתיחה וזמינות ומגוון ההטבות עשויים
          להשתנות מעת לעת, אף לאחר רכישת השובר/ים. לכן, לפני מימוש השובר, יש
          להתעדכן ברשימת בתי העסק העדכנית המפורטת באתר. ומובהר בזאת כי בכל מקרה
          של סתירה, רשימת בתי העסק המפורטת באתר היא העדכנית והגוברת.
          <br />
          כמו כן, המוצרים ו/או השירותים באתר עשויים להשתנות מעת לעת, כל זמן שלא
          סופקו בפועל.
          <br />
          מנהל האתר רשאי בכל עת להורות על שינוי טיבם, סוגם ומחיריהם של המוצרים
          ו/או השירותים המוצעים למכירה במסגרת האתר.
          <br />
          לכן, לפני מימוש השובר בפועל, יש להתעדכן באתר, ולוודא כי לא חלו
          שינויים. מובהר בזאת כי בכל מקרה של סתירה, רשימת בתי העסק ו/או ההטבות
          ו/ו המוצרים ו/או השירותים המפורטת באתר היא העדכנית והגוברת.
          <br />
          יום ביצוע ההזמנה באתר, הינו היום בו אישרה חברת האשראי את העסקה שביצע
          הלקוח באתר.
          <br />
          ימי עסקים לצורך קיום התחייבויות האתר, מנהליו ומפעיליו, הינם ימי חול,
          להוציא ימי שבת, מועדים, חוה"מ וכן ימי חירום, ימי מלחמה, או ימים החוסים
          תחת כוח עליון.
          <br />
          מובהר כי במקרים חריגים ונדירים ו/או מחמת נסיבות שאינן בשליטת מפעילת
          האתר ו/או מחמת שינויים ברשימת בתי העסק או המוצרים ו/או השירותים
          המפורסמים באתר במועד הרכישה ועד למועד המימוש, ייתכן ומוצרים/ שירותים
          שהוזמנו במסגרת האתר, אך טרם סופקו, אינם במלאי ו/או לא ניתן יהיה לספקם,
          חרף הזמנתם באתר. במקרה כזה, מפעילת האתר תזכה את הלקוח בגין המוצרים/
          שירותים שלא ניתן לספק, או תעמיד לרשות הלקוח את האפשרות להזמין מוצרים /
          שירותים חלופיים – לפי בחירת הלקוח.
          <br />
          הנך מסכים ונותן את רשותך לכך שמפעילי האתר ישתמשו בפרטים שמסרת במסגרת
          רכישת המוצרים / שירותים, לצורך אספקתם וכל הכרוך בעסקה ו/או הנובע ממנה.
          הפרטים שיימסרו, לרבות שם הלקוח וכתובתו, הינה באחריות הלקוח בלבד, והאתר
          לא יהיה אחראי לכל אובדן או נזק הנובע ממילוי פרטים חסרים או
          שגויים.  האמור יחול גם במקרה של מסירת פרטים של צד ג' על ידי המשתמש
          (כגון במקרה של רכישת מתנה לצד שלישי).
        </li>
        <li>
          <h5>הצטרפות לדיוור ישיר/ משלוח דברי פרסומת/ הרשמה לניוזלטר</h5>
          הנך מסכים ונותן את רשותך לכך שמפעילי האתר ישתמשו בפרטים שמסרת, אתה או
          צד ג' בשמך ו/או אתה בשם ועבור צד ג', במסגרת ההירשמות לאתר ו/או במסגרת
          הרכישה באתר ו/או במסגרת קבלת שובר אשר נרכש באתר ו/או במסגרת ההרשמה לכל
          אחד מהשירותים המוצעים על ידי האתר או השירותים הנוספים המוצעים על ידי
          מפעיליו וכל עדכון של פרטים אלו וכן בכל מידע שייאסף אודות דפוסי השימוש
          שלך (לרבות באמצעות COOKIES) לצורך שיפור השירות המוצע על ידי האתר ועל
          מנת ליידע אותך בדבר מוצרים ושירותים (המוצעים על ידי האתר או מפעיליו
          ועל ידי צדדים שלישיים) העשויים לעניין אותך ולשגר אליך דברי פרסומת של
          מפרסמים. את המידע כאמור ואת דברי הפרסומת מפעילי האתר יהיו רשאים להביא
          לידיעתך באמצעות:
          <br />
          משלוח לכתובת המגורים שלך
          <br />
          משלוח הודעות אלקטרוניות לכתובת הדואר האלקטרוני אשר מסרת
          <br />
          הודעות מסרים קצרים, בין השאר לטלפון הסלולארי שלך (SMS)
          <br />
          מערכות חיוג אוטומטיות להעברת פקסימיליה או מסר קולי מוקלט לטלפון הנייד
          שלך או הקווי 
          <br />
          הסכמתך כאמור לעיל מהווה הסכמה לשיגור דברי פרסומת על פי חוק התקשורת
          (בזק ושידורים) (תיקון מס' 40), התשס"ח- 2008. מובהר כי על אף הסכמתך
          כאמור, אתה תהא רשאי לחזור בך מהסכמה זו על ידי משלוח הודעת סירוב לאתר
          בכתב או בדרך בה שוגר אליך דבר הפרסומת. מובהר שמפעילי האתר אינם אחראים
          לגבי תוכן הודעות שכאלה או טובין או שירותים שסופקו באמצעותן ע"י צד ג'
          למשתמש ו/או לצד ג' כלשהו, ואתה מסכים כי לא תהיה למפעילי האתר ולאתר כל
          חבות או אחריות בקשר לעניין זה.
        </li>
        <li>
          <h5>זמני אספקה ותשלום</h5>
          כל שובר הנמכר באתר "פלא" ניתן למימוש מידי, בכפוף לפירעון תשלום התמורה
          בגינו.  למימוש השובר יש להציג את מספר השובר המופק ע"י אתר "פלא" בפני
          בית העסק (במידה ומדובר באתר בילוי או נופש יש לתאם מראש עם הספק את מועד
          ההגעה). יש לשים לב לתנאים המצוינים על השובר ולשעות הפעילות.
        </li>
        <li>
          <h5>ביטול עסקה</h5>
          ביטול רכישת השובר יתבצע בהתאם להוראות חוק הגנת הצרכן, התשמ"א-1981
          (להלן- "החוק") ו/או בהתאם להוראות כל דין, לרבות תקנות.
          <br />
          ניתן לבטל את רכישת השובר תוך 14 יום מיום ביצוע הרכישה, וזאת במידה
          והשובר לא מומש. במקרה כזה יתקבל זיכוי בניכוי עמלת דמי ביטול בסך 5%
          ממחיר העסקה או 100 ₪, הנמוך מבניהם. במידה והשובר הינו לרכישת מוצר,
          והשובר מומש בבית העסק, החזרת המוצר הינה לפי תקנון בית העסק. במקרה של
          ביטול עסקה, יבוטל השובר ולא ניתן יהיה לממשו בבית העסק.
        </li>
        <li>
          <h5>אחריות למוצרים/ שירותים המוצעים במסגרת האתר</h5>
          האחריות הבלעדית למוצרים ולמתן לשירותים באתר, לרבות איכותם, טיבם
          ותקינותם חלה באופן בלעדי על בתי העסק ו/או הספקים ו/או היצרנים ו/או
          היבואנים ו/או הסיטונאים ו/או המשווקים המורשים של המוצרים/ השירותים,
          ע"פ החוקים והתקנות להגנת הצרכן הנהוגות בישראל.
          <br />
          בשום נסיבות לא תחול על האתר ו/או מי מטעמו אחריות כלשהי בגין נזק ישיר
          ו/או עקיף, מכל מין וסוג שהוא, הקשור בשימוש באתר ו/או בהתקשרות המחזיק
          בשובר עם איזה מבתי העסק, לרבות, ומבלי לפגוע בכלליות האמור לעיל, דמי
          נזיקין בגין אובדן השימוש, אובדן הנתונים או אובדן הרווחים, הנובעים או
          קשורים בכל דרך שהיא בשימוש או בביצועי האתר, בגין עיכוב בשימוש או באי
          יכולת להשתמש בו, באספקה או באי אספקה של שירותים, או בכל מידע, תוכנה,
          מוצר, שירות וגרפיקה נלווית שהושגו באמצעות האתר, ו/או הנובעים בכל דרך
          אחרת מן השימוש באתר, בין בהתבסס על הסכם ובין בנזיקין, בין באחריות
          מוחלטת, ו/או כל עילה אחרת, אפילו אם נודע לאתר ו/או למי מטעמו על
          האפשרות של דמי נזיקין כאמור.
          <br />
          האחריות המקצועית ו/או טיב המוצרים ועמידתם בלוחות הזמנים יחולו על בתי
          העסק בלבד.
          <br />
          בכלל האמור, בנפיטס אינה אחראית בכל הנוגע לפרטים שמסרו או לא מסרו בתי
          העסק השונים, ואינה אחראית בכל הנודע ליחסים החוזיים ו/או הצרכניים בין
          המחזיק בשובר לבין בית העסק.
        </li>
        <li>
          <h5>שיפוי</h5>
          המשתמש מתחייב לפצות ו/או לשפות, לפי העניין, את מנהל האתר ו/או מי
          מטעמו, ובכלל האמור: מנהליו, עובדיו, נציגיו, וכל הפועל בשמו ו/או מטעמו,
          מיד עם דרישתו הראשונה, ללא כל סייג וללא תנאי, בגין כל הוצאותיו, מכל
          מין וסוג שהוא, בגין כל נזק ו/או הוצאה ו/או פגיעה ו/או הפסד, מכל מין
          וסוג שהוא, שייגרמו לו עקב הפרת הוראות תנאי השימוש באתר ע"פ תקנון זה
          ו/או הפרת הוראת דין ו/או עקב כל תביעה ו/או טענה ו/או דרישה שתופנה נגד
          מנהל האתר על ידי צד שלישי כלשהו, בשל מעשי ו/או מחדלי המשתמש.
        </li>
        <li>
          <h5>מדיניות פרטיות ואבטחת מידע</h5>
          מתוך הכרה בחשיבות המידע הנמסר במסגרת האתר, מנהל האתר נוקט, כעניין
          שבמדיניות, בכל האמצעים הסבירים על מנת להגן על מידע משתמשי האתר,
          באמצעים שמבטיחים גישה למורשים בלבד על פי סיווג ומידור מתאימים ובכך
          למנוע זליגה, גניבה, מחיקה או סילוף של מידע זה. לצורך זאת מיישם מנהל
          האתר בקרות אבטחת מידע מתאימות הכוללות בין היתר אמצעים מנהלתיים ואמצעים
          טכניים מתאימים.
          <br />
          מנהל האתר מחויב לדאוג לסביבת מחשוב זמינה, רצופה, אמינה ויעילה כדי
          לתמוך בפעילותו עסקית וכפועל יוצא מכך, לנקוט בכל האמצעים הדרושים כדי
          למנוע סיכונים הנובעים מחדירה של גורמים בלתי מורשים למערכות שבאחריותו,
          לשמר את זמינות המידע, ולהגן עליו מפני הרס, פגיעה, חשיפה במזיד או
          במקרה, או שינוי לא מוסמך.
          <br />
          מנהל האתר יפעל לאבטחת פרטיו האישיים של המשתמש שימסרו באתר (ככל
          שימסרו), לשם שמירה על פרטיותו. עם זאת, מנהל האתר אינו יכול להבטיח כי
          מערכותיו חסינות בפני פריצות ו/או חדירות בלתי מורשות, ובמקרה כאמור לא
          תהיה למשתמש כל טענה ו/או תביעה ו/או דרישה בעניין.
          <br />
          מנהל האתר וכל גורם אחר הקשור באספקת השירותים והתכנים שבאתר לא יישאו
          באחריות כלשהי לכל נזק ו/או הפסד ו/או אובדן כלשהם, ישירים או עקיפים
          שיגרמו או העלולים להיגרם, במישרין או בעקיפין, למשתמש ו/או לצד ג'
          כתוצאה מפריצה ו/או חדירה בלתי מורשים למערכות מנהל האתר ו/או למערכות
          הספקים.
          <br />
          מנהל האתר נוקט באמצעי זהירות מקובלים על מנת לשמור, ככל האפשר, על
          סודיות המידע שבאתר. כל העברה של מספר כרטיס אשראי מהאתר נעשית באופן
          מוצפן לפי תקן. עם זאת, במקרים שאינם בשליטתו ו/או הנובעים מכוח עליון,
          מנהל האתר לא יהא אחראי לכל נזק מכל סוג שהוא, עקיף או ישיר, שייגרם
          למשתמש ו/או לצד ג' ו/או למי מטעמם, אם מידע זה ייאבד או אם יעשה בו
          שימוש לא מורשה, למעט לנזק ישיר אם נזק כאמור נגרם בשל רשלנותה (ככל
          שתקבע בבית המשפט) של מנהל האתר.
          <br />
          מנהל האתר ימנע ככל האפשר ממסירת פרטיך האישיים לצדדים שלישיים, אלא אם
          היא יהיה מחויב לעשות כן על פי צו שיפוטי או אם יעמוד בפני איום שינקטו
          כנגדו צעדים משפטיים (פליליים או אזרחיים) בגין פעולות שנעשו על ידי
          המשתמש באתר. במקרה זה רשאי מנהל האתר למסור את פרטיך לצד הטוען כי נפגע
          ממך או בהתאם להוראות הצו השיפוטי.
          <br />
          מנהל האתר רשאי להשתמש בפרטיך לצורך שיפור השירותים שהוא מציע באתר, וכן
          ליצירת קשר עמך ו/או עם צד שלישי והתאמת האתר לצרכי המשתמשים בו
          והעדפותיהם האישיות.
        </li>
        <li>
          <h5>קניין רוחני</h5>
          כל זכויות הקניין הרוחני, מכל מין וסוג שהוא, לרבות ובמיוחד: זכויות
          יוצרים, פטנטים, סימני מסחר, סודות מסחריים וכל כל מידע אחר, הינם ויהיו
          קניינו הבלעדי והמלא של מנהל האתר, ובכלל האמור אף זכויות אשר תצמחנה
          כתוצאה מהשימוש באתר ו/או מהרכישה במסגרתו.
          <br />
          לפיכך, המשתמש אינו רשאי לעשות בתכני האתר כל שימוש (למעט השימוש אשר
          הותר באופן מפורש במסגרת תקנון זה) ו/או אינו רשאי לשכפל ו/או להעתיק
          ו/או לשנות ו/או להעניק רישיונות ו/או הרשאות ו/או להעביר ו/או למכור,
          בכל אופן, דרך או צורה שהיא, כל דבר ועניין שהוא הקשורים באתר, בין
          במישרין ובין בעקיפין. כמו כן, כל זכויות הבעלות באתר, לרבות בעיצוב, וכן
          בכל תוכנה, יישום, קוד מחשב, קובץ גרפי, וטקסט וכל חומר אחר הכלול באתר,
          הינן רכושו הבלעדי של מנהל האתר ויוותרו בבעלותו המלאה בכל עת.
        </li>
        <li>
          <h5>שונות</h5>
          במקרה בו לא אושרה העסקה ע"י חברות האשראי יקבל מבצע הפעולה הודעה
          מתאימה. לצורך השלמת הרכישה יידרש מבצע הפעולה ליצור קשר טלפוני עם מוקד
          שירות הלקוחות של "פלא" לצורך הסדרת אישור חברות האשראי לביצוע העסקה.
          יובהר ויודגש, פעולה תחשב כמושלמת רק לאחר הסדרת אישור חברות האשראי
          לביצוע העסקה ע"י מבצע הפעולה.
          <br />
          השימוש באתר הינו שימוש חופשי ולא מוגבל. התכנים אשר מופיעים, ניתנים
          לשימוש כמות שהוא (AS IS) בהתאם להחלטת מנהל האתר ולא תהיה לך כל טענה,
          תביעה או דרישה כלפי מנהל האתר בגין תכונות השימוש, המגבלות או התגובות
          שיעורר הצגת המוצרים באתר ובמכשירי הקצה. השימוש, אפוא, בתכנים המוצגים
          באתר ובמכשירי הקצה ובשירותי האתר, יעשה על אחריותך הבלעדית והמלאה.
          <br />
          הנך מתחייב, לא לפרסם ברבים כל חלק, מוצר ו/או פלט של התכנים, בין שהם
          מודפסים כטקסט, כשרטוט או כדו"ח ובין שהוא ניתן כקובץ על גבי אמצעים
          מגנטיים או בכל צורה אחרת וכי לא ישכפל, לא יצלם, לא יעתיק ולא ידפיס כל
          מוצר ו/או פלט כאמור לעיל מתוך התכנים או כל חלק מהם לצורך הפצה או פרסום
          בכל דרך שהיא. כן, מתחייב הגולש כי לא יאפשר, בין במישרין ובין בעקיפין,
          בין בתמורה ובין ללא תמורה, את ביצוע אחת או יותר מהפעולות המפורטות
          לעיל.
          <br />
          מנהל האתר רשאי לסגור את האתר ולשנות מעת לעת את מבנהו, מראהו ואופי
          המוצרים, השירותים והתכנים הניתנים בו וזאת ללא צורך להודיע לך על כך
          מראש. לא תהיה לך כל טענה, תביעה או דרישה כלפי האתר בקשר לכך.
          <br />
          מנהל האתר רשאי לסגור את האתר לצורך עדכונים ו/או שדרוגים ו/או לשנות,
          מעת לעת, את מבנהו, מראהו וזמינותם של המוצרים, השירותים והתכנים הניתנים
          בו, וזאת ללא צורך בהודעה מראש למשתמש.
        </li>
        <li>
          <h5>דין ושיפוט</h5>
          הדין החל על הפעולות באתר ועל תקנון זה, הוא הדין הישראלי בלבד. בכל מקרה
          של מחלוקת - תהיה לבתי המשפט המצויים במחוז תל אביב-יפו או במחוז מרכז
          הסמכות הבלעדית לדון בתביעה.
        </li>
      </ol>
    </ol>
  ),
  miluim: (
    <ol>
      ברוכים הבאים לאתר/אפליקציה "המילואימניקים פלוס" (להלן: "האתר"). האתר מופעל
      על ידי חברת בנפיטס מועדוני צרכנות בע"מ; שהינה מנפיקת השוברים (להלן:
      "בנפיטס" ו/או "מפעיל האתר" ו/או "מנהל האתר" לפי העניין ו/או ההקשר). באתר
      תוכל לרכוש שוברי מתנה כמפורט להלן. חשוב לנו שתדע- אצלנו הלקוח הוא במקום
      הראשון. חשוב לנו שהלקוח יהיה מרוצה, וזאת על ידי הקפדה יתרה על ביטחון
      הקנייה, קשר ישיר ורציף מול בתי העסק ושירות לקוחות מהמעלה הראשונה . לכן,
      חשוב לנו שלפני הרכישה (או לפני מימוש השובר שכבר בידך, לפי העניין) תקפיד
      ותקרא את התקנון. כל הוראות תקנון זה יוחלו, בהתאמה ובשינויים המחויבים, על
      כל שובר שהונפק ע"י "בנפיטס", אף אם נרכש שלא דרך האתר. כמו כן, בכל מקרה של
      שובר מודפס (נייר) שהמחזיק מבקש להמירו לשובר דיגיטלי, יש להיכנס ללשונית
      הרלוונטית באתר ולהתעדכן בתנאים וברשימת בתי העסק.
      <ol>
        <ol>
          <li>
            <h5>כללי</h5>
            האתר/אפליקציה מופעל על ידי מפעיל האתר כהגדרתו להלן, המנהל חנות
            וירטואלית לציבור הגולשים ברשת האינטרנט בישראל. הוראות תקנון זה יחולו
            על כל גלישה באתר, ובכלל זה על כל גולש הנכנס לאתר ו/או מבצע ו/או
            מעוניין לבצע הזמנה באמצעות האתר (ובכלל האמור באמצעות אפליקציה או בכל
            דרך מקוונת אחרת), ולפיכך הנך מתבקש לקרוא את התקנון בעיון, שכן
            הוראותיו תחייבנה ותהוונה את הבסיס המשפטי בכל דין ודברים בינך לבין
            מפעיל האתר ו/או מנהליו ו/או "המילואימניקים פלוס", ובכניסתך לאתר
            והמשך גלישה בו הנך מצהיר ומתחייב כי הנך מודע לכך, מאשר זאת, מצהיר
            ומתחייב כי הנך מודע לתקנון האתר ומאשר אותו. הרשמה לאתר ומסירת פרטים
            אישיים הינה זכות ולא חובה.
            <br />
            למנהלי האתר שמורה הזכות, בכל עת וללא צורך בהודעה מראש, לשנות מעת
            לעת, על פי שיקול דעתם הבלעדי, תקנון זה ו/או את תנאי השימוש באתר ו/או
            את תכניו של האתר, המידע המפורסם בו, השירותים ו/או המוצרים המוצעים
            במסגרתו, וכל הנובע משינויים אלה. לכן, באחריות המשתמש באתר לקרוא תנאי
            שימוש אלה בכל כניסה לאתר, ולא תהיה לו כל טענה ו/או תביעה ו/או דרישה
            כלפי מנהל האתר בקשר לכך. כל שינוי ייכנס לתוקף מיד עם פרסומו באתר,
            והוא יחול על כל משתמשי האתר ו/או על כל מי שמחזיק בשוברים שהונפקו ע"י
            בנפיטס. הגלישה באתר אינה כרוכה בתשלום, אולם רכישת מוצרים ו/או
            שירותים באתר כרוכה בתשלום ובהזנת פרטים.
          </li>
          <li>
            <h5>הגדרות.</h5>
            "מנהל/י האתר" ו/או "מפעיל האתר"- חברת בנפיטס מועדוני צרכנות בע"מ וכל
            הבאים מטעמה.
            <br />
            "שובר"- שובר המעניק לזכאי לאחוז בו, בכפוף לפירעון התמורה בעדו, זכות
            חד פעמית, הנרכשת במסגרת האתר, לקבלת מוצר או שירות תמורת מימושו,
            כמפורסם וכמפורט ביחס לשובר, אלא אם כן מדובר בתו שי או בשובר מתנה
            ("גיפט קארד") בעלי ערך כספי נקוב, ובמקרה כזה ניתן יהיה לממשו מספר
            פעמים (אך לא בעסקת תשלומים נדחים), עד לפירעון מלוא הערך הנקוב.
            <br />
            השובר יכול להיות בעל שווי כספי (ערך נקוב) ו/או שובר מתנה המיועד
            לרכישת מוצרים ספציפיים או קבלת שירות ספציפי או חוויות ברשתות מובילות
            בארץ (כגון קופון, למשל), על פי המפורסם באתר זה.
            <br />
            השובר יישלח ישלח לאפליקציה ו/או בדוא"ל ו/או באופן מודפס, לפי בחירת
            הגולש באתר.
            <br />
            "משתמש" או "משתמש באתר"- כל אדם בגיר (מעל גיל 18) העושה שימוש כלשהו
            באתר;
          </li>
          <li>
            <h5>תנאים כלליים</h5>
            כל משתמש/ גולש מצהיר כי הוא מקבל על עצמו את הוראות תקנון זה ותנאי
            השימוש באתר המפורטים להלן (להלן: "תקנון האתר" או "התקנון"), וכי לא
            תהא לו ו/או למי מטעמו כל טענה ו/או תביעה כנגד בעלי האתר ו/או מפעיליו
            ו/או מי מטעמם מלבד טענות הקשורות בהפרת התחייבויות בנפיטס ו/או בעלי
            האתר ו/או מפעיליו על פי תקנון זה. למען הסר ספק, המוצרים ו/או
            השירותים המוצעים לרכישה במסגרת האתר, מוצעים למכירה על ידי בתי העסק
            השונים המזוהים בכל אחת מעסקאות המכירה ולא  על ידי בנפיטס ו/או האתר
            ו/או מנהליו (למעט השוברים עצמם, אותם מנפיקה בנפיטס). בית עסק
            הרלוונטי בקשר עם מימוש השובר הוא יהיה הצד שיישא באחריות הבלעדית בגין
            השירות ו/או המוצרים ו/או הרכישה המתבצעת באמצעות השובר, וכל הנובע
            מהם. מנהל האתר שומר על זכותו לשנות תקנון זה מזמן לזמן. רק הכללים
            הכלולים בתקנון זה, כפי שהוא מעודכן באתר, יחייבו את מנהל האתר כלפי
            משתמשי וגולשי האתר.
          </li>
          <li>
            <h5>תנאי השימוש באתר</h5>
            הרשאים לעשות שימוש באתר הינם כל אדם תושב מדינת ישראל בן 18 ומעלה
            (וכן חברה המאוגדת כדין לפי חוקי מדינת ישראל והרשומה ברשם החברות
            בישראל). זהו, מבלי לגרוע מהאמור לעיל, תנאי מוקדם לביצוע הזמנה באתר.
            רכישת המוצרים באתר הינה באמצעות כרטיס אשראי. כל רכישה באתר מחייבת
            לקבל את אישור הגורם הרלוונטי (כגון חברת כרטיסי האשראי), אשר דרכה
            התחייב הלקוח לביצוע התשלום עבור עסקת הרכישה של המוצרים ו/או השירותים
            שמציע האתר. המוצרים והשירותים המוצעים באתר מיועדים לשימוש הפרטי
            והאישי של הלקוחות ולא למטרות מסחריות ו/או סיטונאות. מנהל האתר רשאי
            למנוע מלקוח כלשהו את השתתפות והשימוש באתר, באופן זמני או לצמיתות, על
            פי שיקול דעתו הסביר, בכפוף לכל דין, לרבות אם יהא סבור כי הלקוח עלול
            לסחור בהם או להעביר באופן אחר לצד שלישי, וזאת מבלי צורך לנמק החלטתו
            ו/או מבלי להודיע ללקוח על כך מראש. מנהל האתר אף שומר לעצמו את הזכות,
            על פי שיקול דעתו הסביר ובכפוף לכל דין, לבטל את רכישתו של המשתמש
            בנסיבות אלו ו/או בנסיבות אחרות, בהן, למשל לדעת מנהל האתר, נמסרו
            פרטים שגויים באופן מכוון ו/או המשתמש נהג בניגוד להוראות תקנון זה
            ו/או פרטי כרטיס האשראי עמו בוצע התשלום נחסם ו/או לדעת מנהל האתר קיים
            חשש סביר כי המשתמש עשה שימוש העלול לפגוע בצדדים שלישיים ו/או במנהל
            האתר. אין בחסימה, בהפסקה או בהגבלת השירותים או ביטולם, כאמור לעיל,
            כדי לגרוע מחובתו של הלקוח לנהוג בכל עת בהתאם לתנאי שימוש אלה. כל
            שימוש של הלקוח באתר, לרבות בשירותים ובתכנים שבאתר, בניגוד להוראות
            מנהל האתר ו/או בניגוד לתנאי שימוש אלה ו/או בניגוד להוראות כל דין,
            יזכה את מנהל האתר ו/או צדדים שלישיים בעלי זכויות בשירותים ובתכנים
            לפי העניין, בנקיטת אחד או יותר מן הסעדים הקבועים בתקנון זה כלפי
            הלקוח, וזאת מבלי לגרוע מכל סעד אחר העומד לזכות מנהל האתר ו/או צדדים
            שלישים בעלי זכויות בשירותים ובתכנים על פי כל דין. כל גולש אשר נרשם
            לאתר מתבקש לאשר את התקנון ע"מ להשלים את האישור. גולשים אשר מבצעים
            רישום באמצעות גוגל או פייסבוק בדף הרישום מאשרים באופן אוטומטי את
            התקנון ותנאי השימוש באתר.
          </li>
          <li>
            <h5> הרכישה באתר, השובר ומימושו</h5>
            מובהר בזאת כי תנאי סף לקבלת שובר ו/או למימושו, הינה כי תמורתו המלאה
            שולמה. התשלום במסגרת רכישות שביצע המשתמש באתר ייעשה על ידי המשתמש,
            באמצעות הזנת פרטי כרטיס אשראי לחיוב וכן מסירת פרטים אישיים. כל
            הוראות תקנון זה יוחלו, בהתאמה ובשינויים המחויבים, על כל שובר שהונפק
            ע"י בנפיטס, אף אם נרכש שלא דרך האתר. פרטי הרכישה יועברו לאישור חברת
            כרטיס האשראי (או הגורם המשלם), אשר תאשר או תדחה את העסקה. רק עסקאות
            אשר אושרו תזכינה את המשתמש בקבלת השובר ומימושו. בכל מקרה של דחיית
            העסקה על ידי חברת האשראי (או הגורם המשלם), תשלח למשתמש הודעה מתאימה,
            והוא יידרש לפרוע את התשלום בכל דרך אחרת בו יורה לו מנהל האתר. במקרה
            כזה, השובר, אם וככל שנשלח, יהיה חסום לשימוש וזאת עד להסדרת התשלום,
            ולמשתמש ו/או לכל צד ג' שהוא לא תהיה כל טענה ו/או דרישה ו/או תביעה
            בעניין.  כמו כן, מובהר ומוסכם בזאת כי, בכל מקרה, למנהל האתר שמורה
            הזכות לסרב ו/או שלא לקבל ו/או לבטל עסקה, אף אם אושרה על ידי חברת
            האשראי, על פי שיקול דעתו הבלעדי וללא צורך לנמק את החלטתו. לכל שובר
            תיקבע תקופת מימוש, במסגרתה תוכל לממשו. המחירים המפורסמים באתר ביחס
            לשוברים הינם מחירים כוללים, אלא אם כן צוין במפורש אחרת. השובר יישלח
            למשתמש האתר ו/או לצד ג' כלשהו בהתאם לבחירת משתמש האתר, אשר באחריותו
            יהיה להזין את פרטיו וכל הנובע מכך, באמצעים עליו יחליט מנהל האתר,
            ומימוש השובר ייעשה אף הוא באופן בו יחליט עליו מנהל האתר, מעת לעת.
            <br />
            השובר יכובד בבתי העסק כפי שיפורטו באתר זה. יש לפנות טלפונית לבית
            העסק הנבחר ולוודא ישירות את תנאי מימוש השובר.
            <br />
            השובר אינו מיועד לשימוש של משלם מסוים, וניתן להעבירו גם לשימוש כל צד
            ג' שהוא, ובלבד שתמורתו המלאה שולמה לבנפיטס.
            <br />
            סכום המירבי שניתן לרכוש/ לצבור/ לממש ביחס לכל שובר מוגבל עד לסך של
            1,500 ₪ ואינו ניתן לטעינה נוספת.
            <br />
            החיוב באמצעות השובר יהיה מיידי ולא ניתן לבצע באמצעותו עסקאות
            תשלומים.
            <br />
            במקרה של חדלות פירעון של בית העסק אשר אמור היה לספק את השירות ו/או
            המוצר נשוא השובר, על המחזיק בשובר לפנות לחברת בנפיטס, אשר תזכה אותי
            בגין הערך הנקוב של השובר שלא מומש.
            <br />
            במקרה של שוברים עם ערך צבור, הסכום הנקוב יהיה ניתן למימוש באופן אחיד
            לגבי כל בתי העסק הרלוונטיים לאותו השובר.
            <br />
            על שובר מסוג "שובר מתנה" כהגדרתו בחוק הגנת הצרכן- ניתן יהיה למימוש
            בהתאם להוראות סעיף 14ז. לחוק הגנת הצרכן.
            <br />
            על שובר מסוג "תו קניה" כהגדרתו בחוק הגנת הצרכן- ניתן יהיה למימוש
            בהתאם להוראות סעיף 14ח. לחוק הגנת הצרכן.
            <br />
            באחריותך המלאה והבלעדית לממש השובר במסגרת תקופת המימוש.
            <br />
            תוקף שובר שהינו מסוג "תו קנייה" כהגדרתו בחוק הגנת הצרכן- חמש שנים;
            תוקף שובר שהינו "שובר מתנה" כהגדרתו בחוק הגנת הצרכן- שנתיים תוקף
            שובר מסוג "קופון" לקבלת הטבה ו/או מתנה ו/או מוצר ו/או שירות ספציפי
            הינו כפי שמפורט בתנאים המתייחסים לשובר מסוג קופון.
            <br />
            *על אף האמור, שוברים שתוקפם פג יוארכו לאחר פניית הלקוח אל בנפיטס,
            ויהיו ניתנים למימוש עד לתאריך 31/12/2030 .
            <br />
            במקרה של אובדן או גניבה של השובר, על הלקוח (מי שנחזה כמי שהחזיק כדין
            בשובר) לפנות לחברת בנפיטס, אשר מצדה תנסה, במאמצים סבירים, לשחזר את
            השובר והיתרה הצבורה במערכותיה, ובלבד שהשובר לא מומש ע"י צד ג' כלשהו
            וכי קיימת יתרת זכות בשובר. האמור לא יחול על שובר נייר (מודפס).
            <br />
            חובה על הלקוח שהזמין שובר הכרוך בפעילות או שירות, לבצע אישור הזמנה
            טלפוני מול מפעיל החוויה לפחות יום לפני התאריך המתוכנן על מנת לוודא
            מזג אויר ותנאים טכניים.
            <br />
            עדכונים ושינויים - בתי העסק, שעות פתיחה וזמינות ומגוון ההטבות עשויים
            להשתנות מעת לעת, אף לאחר רכישת השובר/ים. לכן, לפני מימוש השובר, יש
            להתעדכן ברשימת בתי העסק העדכנית המפורטת באתר. ומובהר בזאת כי בכל
            מקרה של סתירה, רשימת בתי העסק המפורטת באתר היא העדכנית והגוברת.
            <br />
            כמו כן, המוצרים ו/או השירותים באתר עשויים להשתנות מעת לעת, כל זמן
            שלא סופקו בפועל.
            <br />
            מנהל האתר רשאי בכל עת להורות על שינוי טיבם, סוגם ומחיריהם של המוצרים
            ו/או השירותים המוצעים למכירה במסגרת האתר.
            <br />
            לכן, לפני מימוש השובר בפועל, יש להתעדכן באתר, ולוודא כי לא חלו
            שינויים. מובהר בזאת כי בכל מקרה של סתירה, רשימת בתי העסק ו/או ההטבות
            ו/ו המוצרים ו/או השירותים המפורטת באתר היא העדכנית והגוברת.
            <br />
            יום ביצוע ההזמנה באתר, הינו היום בו אישרה חברת האשראי את העסקה שביצע
            הלקוח באתר.
            <br />
            ימי עסקים לצורך קיום התחייבויות האתר, מנהליו ומפעיליו, הינם ימי חול,
            להוציא ימי שבת, מועדים, חוה"מ וכן ימי חירום, ימי מלחמה, או ימים
            החוסים תחת כוח עליון.
            <br />
            מובהר כי במקרים חריגים ונדירים ו/או מחמת נסיבות שאינן בשליטת מפעילת
            האתר ו/או מחמת שינויים ברשימת בתי העסק או המוצרים ו/או השירותים
            המפורסמים באתר במועד הרכישה ועד למועד המימוש, ייתכן ומוצרים/ שירותים
            שהוזמנו במסגרת האתר, אך טרם סופקו, אינם במלאי ו/או לא ניתן יהיה
            לספקם, חרף הזמנתם באתר. במקרה כזה, מפעילת האתר תזכה את הלקוח בגין
            המוצרים/ שירותים שלא ניתן לספק, או תעמיד לרשות הלקוח את האפשרות
            להזמין מוצרים / שירותים חלופיים – לפי בחירת הלקוח.
            <br />
            הנך מסכים ונותן את רשותך לכך שמפעילי האתר ישתמשו בפרטים שמסרת במסגרת
            רכישת המוצרים / שירותים, לצורך אספקתם וכל הכרוך בעסקה ו/או הנובע
            ממנה. הפרטים שיימסרו, לרבות שם הלקוח וכתובתו, הינה באחריות הלקוח
            בלבד, והאתר לא יהיה אחראי לכל אובדן או נזק הנובע ממילוי פרטים חסרים
            או שגויים.  האמור יחול גם במקרה של מסירת פרטים של צד ג' על ידי
            המשתמש (כגון במקרה של רכישת מתנה לצד שלישי).
          </li>
          <li>
            <h5> הצטרפות לדיוור ישיר/ משלוח דברי פרסומת/ הרשמה לניוזלטר</h5>
            הנך מסכים ונותן את רשותך לכך שמפעילי האתר ישתמשו בפרטים שמסרת, אתה
            או צד ג' בשמך ו/או אתה בשם ועבור צד ג', במסגרת ההירשמות לאתר ו/או
            במסגרת הרכישה באתר ו/או במסגרת קבלת שובר אשר נרכש באתר ו/או במסגרת
            ההרשמה לכל אחד מהשירותים המוצעים על ידי האתר או השירותים הנוספים
            המוצעים על ידי מפעיליו וכל עדכון של פרטים אלו וכן בכל מידע שייאסף
            אודות דפוסי השימוש שלך (לרבות באמצעות COOKIES) לצורך שיפור השירות
            המוצע על ידי האתר ועל מנת ליידע אותך בדבר מוצרים ושירותים (המוצעים
            על ידי האתר או מפעיליו ועל ידי צדדים שלישיים) העשויים לעניין אותך
            ולשגר אליך דברי פרסומת של מפרסמים. את המידע כאמור ואת דברי הפרסומת
            מפעילי האתר יהיו רשאים להביא לידיעתך באמצעות:
            <br />
            משלוח לכתובת המגורים שלך
            <br />
            משלוח הודעות אלקטרוניות לכתובת הדואר האלקטרוני אשר מסרת
            <br />
            הודעות מסרים קצרים, בין השאר לטלפון הסלולארי שלך (SMS)
            <br />
            מערכות חיוג אוטומטיות להעברת פקסימיליה או מסר קולי מוקלט לטלפון
            הנייד שלך או הקווי
            <br />
            הסכמתך כאמור לעיל מהווה הסכמה לשיגור דברי פרסומת על פי חוק התקשורת
            (בזק ושידורים) (תיקון מס' 40), התשס"ח- 2008. מובהר כי על אף הסכמתך
            כאמור, אתה תהא רשאי לחזור בך מהסכמה זו על ידי משלוח הודעת סירוב לאתר
            בכתב או בדרך בה שוגר אליך דבר הפרסומת. מובהר שמפעילי האתר אינם
            אחראים לגבי תוכן הודעות שכאלה או טובין או שירותים שסופקו באמצעותן
            ע"י צד ג' למשתמש ו/או לצד ג' כלשהו, ואתה מסכים כי לא תהיה למפעילי
            האתר ולאתר כל חבות או אחריות בקשר לעניין זה.
          </li>
          <li>
            <h5>זמני אספקה ותשלום</h5>
            כל שובר הנמכר באתר "המילואימניקים פלוס" ניתן למימוש מידי, בכפוף
            לפירעון תשלום התמורה בגינו.
            <br />
            למימוש השובר יש להציג את מספר השובר המופק ע"י אתר "המילואימניקים
            פלוס" בפני בית העסק (במידה ומדובר באתר בילוי או נופש יש לתאם מראש עם
            הספק את מועד ההגעה). יש לשים לב לתנאים המצוינים על השובר ולשעות
            הפעילות.
          </li>
          <li>
            <h5>ביטול עסקה</h5>
            ביטול רכישת השובר יתבצע בהתאם להוראות חוק הגנת הצרכן, התשמ"א-1981
            (להלן- "החוק") ו/או בהתאם להוראות כל דין, לרבות תקנות. ניתן לבטל את
            רכישת השובר תוך 14 יום מיום ביצוע הרכישה, וזאת במידה והשובר לא מומש.
            במקרה כזה יתקבל זיכוי בניכוי עמלת דמי ביטול בסך 5% ממחיר העסקה או
            100 ₪, הנמוך מבניהם. במידה והשובר הינו לרכישת מוצר, והשובר מומש בבית
            העסק, החזרת המוצר הינה לפי תקנון בית העסק. במקרה של ביטול עסקה,
            יבוטל השובר ולא ניתן יהיה לממשו בבית העסק.
          </li>
          <li>
            <h5>אחריות למוצרים/ שירותים המוצעים במסגרת האתר</h5>
            האחריות הבלעדית למוצרים ולמתן לשירותים באתר, לרבות איכותם, טיבם
            ותקינותם חלה באופן בלעדי על בתי העסק ו/או הספקים ו/או היצרנים ו/או
            היבואנים ו/או הסיטונאים ו/או המשווקים המורשים של המוצרים/ השירותים,
            ע"פ החוקים והתקנות להגנת הצרכן הנהוגות בישראל. בשום נסיבות לא תחול
            על האתר ו/או מי מטעמו אחריות כלשהי בגין נזק ישיר ו/או עקיף, מכל מין
            וסוג שהוא, הקשור בשימוש באתר ו/או בהתקשרות המחזיק בשובר עם איזה מבתי
            העסק, לרבות, ומבלי לפגוע בכלליות האמור לעיל, דמי נזיקין בגין אובדן
            השימוש, אובדן הנתונים או אובדן הרווחים, הנובעים או קשורים בכל דרך
            שהיא בשימוש או בביצועי האתר, בגין עיכוב בשימוש או באי יכולת להשתמש
            בו, באספקה או באי אספקה של שירותים, או בכל מידע, תוכנה, מוצר, שירות
            וגרפיקה נלווית שהושגו באמצעות האתר, ו/או הנובעים בכל דרך אחרת מן
            השימוש באתר, בין בהתבסס על הסכם ובין בנזיקין, בין באחריות מוחלטת,
            ו/או כל עילה אחרת, אפילו אם נודע לאתר ו/או למי מטעמו על האפשרות של
            דמי נזיקין כאמור. האחריות המקצועית ו/או טיב המוצרים ועמידתם בלוחות
            הזמנים יחולו על בתי העסק בלבד. בכלל האמור, בנפיטס אינה אחראית בכל
            הנוגע לפרטים שמסרו או לא מסרו בתי העסק השונים, ואינה אחראית בכל
            הנודע ליחסים החוזיים ו/או הצרכניים בין המחזיק בשובר לבין בית העסק.
          </li>
          <li>
            <h5>שיפוי </h5>
            המשתמש מתחייב לפצות ו/או לשפות, לפי העניין, את מנהל האתר ו/או מי
            מטעמו, ובכלל האמור: מנהליו, עובדיו, נציגיו, וכל הפועל בשמו ו/או
            מטעמו, מיד עם דרישתו הראשונה, ללא כל סייג וללא תנאי, בגין כל
            הוצאותיו, מכל מין וסוג שהוא, בגין כל נזק ו/או הוצאה ו/או פגיעה ו/או
            הפסד, מכל מין וסוג שהוא, שייגרמו לו עקב הפרת הוראות תנאי השימוש באתר
            ע"פ תקנון זה ו/או הפרת הוראת דין ו/או עקב כל תביעה ו/או טענה ו/או
            דרישה שתופנה נגד מנהל האתר על ידי צד שלישי כלשהו, בשל מעשי ו/או
            מחדלי המשתמש.
          </li>
          <li>
            <h5> מדיניות פרטיות ואבטחת מידע</h5>
            תוך הכרה בחשיבות המידע הנמסר במסגרת האתר, מנהל האתר נוקט, כעניין
            שבמדיניות, בכל האמצעים הסבירים על מנת להגן על מידע משתמשי האתר,
            באמצעים שמבטיחים גישה למורשים בלבד על פי סיווג ומידור מתאימים ובכך
            למנוע זליגה, גניבה, מחיקה או סילוף של מידע זה. לצורך זאת מיישם מנהל
            האתר בקרות אבטחת מידע מתאימות הכוללות בין היתר אמצעים מנהלתיים
            ואמצעים טכניים מתאימים. מנהל האתר מחויב לדאוג לסביבת מחשוב זמינה,
            רצופה, אמינה ויעילה כדי לתמוך בפעילותו עסקית וכפועל יוצא מכך, לנקוט
            בכל האמצעים הדרושים כדי למנוע סיכונים הנובעים מחדירה של גורמים בלתי
            מורשים למערכות שבאחריותו, לשמר את זמינות המידע, ולהגן עליו מפני הרס,
            פגיעה, חשיפה במזיד או במקרה, או שינוי לא מוסמך. מנהל האתר יפעל
            לאבטחת פרטיו האישיים של המשתמש שימסרו באתר (ככל שימסרו), לשם שמירה
            על פרטיותו. עם זאת, מנהל האתר אינו יכול להבטיח כי מערכותיו חסינות
            בפני פריצות ו/או חדירות בלתי מורשות, ובמקרה כאמור לא תהיה למשתמש כל
            טענה ו/או תביעה ו/או דרישה בעניין. מנהל האתר וכל גורם אחר הקשור
            באספקת השירותים והתכנים שבאתר לא יישאו באחריות כלשהי לכל נזק ו/או
            הפסד ו/או אובדן כלשהם, ישירים או עקיפים שיגרמו או העלולים להיגרם,
            במישרין או בעקיפין, למשתמש ו/או לצד ג' כתוצאה מפריצה ו/או חדירה בלתי
            מורשים למערכות מנהל האתר ו/או למערכות הספקים. מנהל האתר נוקט באמצעי
            זהירות מקובלים על מנת לשמור, ככל האפשר, על סודיות המידע שבאתר. כל
            העברה של מספר כרטיס אשראי מהאתר נעשית באופן מוצפן לפי תקן. עם זאת,
            במקרים שאינם בשליטתו ו/או הנובעים מכוח עליון, מנהל האתר לא יהא אחראי
            לכל נזק מכל סוג שהוא, עקיף או ישיר, שייגרם למשתמש ו/או לצד ג' ו/או
            למי מטעמם, אם מידע זה ייאבד או אם יעשה בו שימוש לא מורשה, למעט לנזק
            ישיר אם נזק כאמור נגרם בשל רשלנותה (ככל שתקבע בבית המשפט) של מנהל
            האתר. מנהל האתר ימנע ככל האפשר ממסירת פרטיך האישיים לצדדים שלישיים,
            אלא אם היא יהיה מחויב לעשות כן על פי צו שיפוטי או אם יעמוד בפני איום
            שינקטו כנגדו צעדים משפטיים (פליליים או אזרחיים) בגין פעולות שנעשו על
            ידי המשתמש באתר. במקרה זה רשאי מנהל האתר למסור את פרטיך לצד הטוען כי
            נפגע ממך או בהתאם להוראות הצו השיפוטי. מנהל האתר רשאי להשתמש בפרטיך
            לצורך שיפור השירותים שהוא מציע באתר, וכן ליצירת קשר עמך ו/או עם צד
            שלישי והתאמת האתר לצרכי המשתמשים בו והעדפותיהם האישיות.
          </li>
          <li>
            <h5>קניין רוחני</h5>
            כל זכויות הקניין הרוחני, מכל מין וסוג שהוא, לרבות ובמיוחד: זכויות
            יוצרים, פטנטים, סימני מסחר, סודות מסחריים וכל כל מידע אחר, הינם
            ויהיו קניינו הבלעדי והמלא של מנהל האתר, ובכלל האמור אף זכויות אשר
            תצמחנה כתוצאה מהשימוש באתר ו/או מהרכישה במסגרתו. לפיכך, המשתמש אינו
            רשאי לעשות בתכני האתר כל שימוש (למעט השימוש אשר הותר באופן מפורש
            במסגרת תקנון זה) ו/או אינו רשאי לשכפל ו/או להעתיק ו/או לשנות ו/או
            להעניק רישיונות ו/או הרשאות ו/או להעביר ו/או למכור, בכל אופן, דרך או
            צורה שהיא, כל דבר ועניין שהוא הקשורים באתר, בין במישרין ובין
            בעקיפין. כמו כן, כל זכויות הבעלות באתר, לרבות בעיצוב, וכן בכל תוכנה,
            יישום, קוד מחשב, קובץ גרפי, וטקסט וכל חומר אחר הכלול באתר, הינן
            רכושו הבלעדי של מנהל האתר ויוותרו בבעלותו המלאה בכל עת.
          </li>
          <li>
            <h5>שונות</h5>
            במקרה בו לא אושרה העסקה ע"י חברות האשראי יקבל מבצע הפעולה הודעה
            מתאימה. לצורך השלמת הרכישה יידרש מבצע הפעולה ליצור קשר טלפוני עם
            מוקד שירות הלקוחות של "המילואימניקים פלוס" לצורך הסדרת אישור חברות
            האשראי לביצוע העסקה. יובהר ויודגש, פעולה תחשב כמושלמת רק לאחר הסדרת
            אישור חברות האשראי לביצוע העסקה ע"י מבצע הפעולה. השימוש באתר הינו
            שימוש חופשי ולא מוגבל. התכנים אשר מופיעים, ניתנים לשימוש כמות שהוא
            (AS IS) בהתאם להחלטת מנהל האתר ולא תהיה לך כל טענה, תביעה או דרישה
            כלפי מנהל האתר בגין תכונות השימוש, המגבלות או התגובות שיעורר הצגת
            המוצרים באתר ובמכשירי הקצה. השימוש, אפוא, בתכנים המוצגים באתר
            ובמכשירי הקצה ובשירותי האתר, יעשה על אחריותך הבלעדית והמלאה. הנך
            מתחייב, לא לפרסם ברבים כל חלק, מוצר ו/או פלט של התכנים, בין שהם
            מודפסים כטקסט, כשרטוט או כדו"ח ובין שהוא ניתן כקובץ על גבי אמצעים
            מגנטיים או בכל צורה אחרת וכי לא ישכפל, לא יצלם, לא יעתיק ולא ידפיס
            כל מוצר ו/או פלט כאמור לעיל מתוך התכנים או כל חלק מהם לצורך הפצה או
            פרסום בכל דרך שהיא. כן, מתחייב הגולש כי לא יאפשר, בין במישרין ובין
            בעקיפין, בין בתמורה ובין ללא תמורה, את ביצוע אחת או יותר מהפעולות
            המפורטות לעיל. מנהל האתר רשאי לסגור את האתר ולשנות מעת לעת את מבנהו,
            מראהו ואופי המוצרים, השירותים והתכנים הניתנים בו וזאת ללא צורך
            להודיע לך על כך מראש. לא תהיה לך כל טענה, תביעה או דרישה כלפי האתר
            בקשר לכך. מנהל האתר רשאי לסגור את האתר לצורך עדכונים ו/או שדרוגים
            ו/או לשנות, מעת לעת, את מבנהו, מראהו וזמינותם של המוצרים, השירותים
            והתכנים הניתנים בו, וזאת ללא צורך בהודעה מראש למשתמש.
          </li>
          <li>
            <h5>דין ושיפוט</h5>
            הדין החל על הפעולות באתר ועל תקנון זה, הוא הדין הישראלי בלבד. בכל
            מקרה של מחלוקת - תהיה לבתי המשפט המצויים במחוז תל אביב-יפו או במחוז
            מרכז הסמכות הבלעדית לדון בתביעה.
          </li>
        </ol>
      </ol>
    </ol>
  ),
  mshuk: (
    <ol>
      <li>
        <h5>כללי</h5>
        <ol>
          <li>
            הוראות התקנון מנוסחות בלשון זכר לצרכי נוחות בלבד ומתייחסות לנשים
            וגברים כאחד.
          </li>
          <li>
            האתר מנוהל ומופעל ע"י אלעד מועדוני צרכנות בע"מ (להלן: "מנהל האתר").
          </li>
        </ol>
      </li>
      <li>
        <h5> הצטרפות (הורדת) אפליקציית ארנק ההטבות.</h5>
        <ol>
          <li>
            כל אדם שמלאו לו 18 שנים, רשאי לבקש להצטרף למועדון כרטיס האשראי -
            wincard, המופעל ומנוהל ע"י מקס איט פיננסים בע"מ (להלן: "מקס")
          </li>
          <li>
            מובהר, כי ההצטרפות למועדון וקבלת הטבות המועדון, הינם ללקוחות פרטיים
            ולרווחתם.
          </li>
          <li>
            כל אדם המעוניין להיות חבר המועדון, מוזמן לפנות לאחד מסניפי הרשת
            להתקבל כחבר במועדון הלקוחות באמצעות בקשה להנפקת כרטיס אשראי wincard
            המונפק ע"י מקס איט פיננסים בע"מ והכל בכפוף לתנאים וההגבלות כמצוין
            בתנאי ההצטרפות לקבלת כרטיס אשראי המועדון, לרבות טופס גילוי נאות
            ולרבות התנאים המפורטים בתקנון זה.
          </li>
          <li>
            היה והצטרף אדם כחבר במועדון האשראי, יהא זכאי להשתמש באפליקציית
            ההטבות - wincoin.
          </li>
          <li>
            <h5> צבירת מטבעות "wincoins" ע"פ תכנית ה- cashback </h5>
            <ol>
              <li>
                מחזיקי ארנק ההטבות יזוכו במטבעות בעבור כל רכישה בחנויות הרשת.
              </li>
              <li>יתרת המטבעות בארנק תעודכן תוך 2 ימי עסקים ממועד הקנייה.</li>
              <li>
                קבלת ההטבה מותנית בכך שהקנייה תבוצע באמצעות כרטיס אשראי המועדון.
              </li>
              <li>
                אופן ושיעור הצבירה: בגין כל רכישה, תזוכה יתרת המטבעות בארנק
                בשיעור של 8% משווי הקניה. לדוגמא: ברכישה בסך של 600 ₪ ממוצרי
                הרשת מחזיק הארנק יצבור 48 מטבעות.
              </li>
              <li> תוקף המטבעות הינו למשך 12 חודשים. </li>
              <li>
                מנהל האתר ו/או מקס רשאים לשנות את השיטה לצבירת מטבעות, וזאת על
                פי שיקול דעתם הבלעדית.
              </li>
              <li>
                מנהל האתר ו/או מקס רשאים לשנות את יחס/מקדם המרת המטבעות ו/או
                הטבות שונות, וזאת על פי שיקול דעתם הבלעדית.
              </li>
              <li>
                מנהל האתר רשאי להגביל ו/או להתנות את ההטבות או המבצעים על פי
                שיקול דעתו הבלעדי.
              </li>
              <li>
                מנהל האתר יהיה רשאי לעשות שימוש בתשתית הטכנולוגית של המועדון
                לצורך מתן הטבות/מענקים/זכאויות לקבוצות לקוחות על פי שיקול דעתו
                הבלעדי.
              </li>
              <li>
                מובהר כי מנהל האתר ו/או מקס רשאים להפסיק את מתן ההטבות ו/או
                לשנות את אופיין ו/או אופן חישוב ההטבות בכל עת ועל פי שיקול דעתם
                הבלעדית וללא הודעה מוקדמת, ולא תישמע מצד הלקוח כל טענה לרבות
                בגין הסתמכות על מתן ההטבות בהצטרפות כחבר מועדון.
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <h5> המרה/מימוש ויתרת מטבעות.</h5>
        <ol>
          <li>
            מנהל האתר יאפשר לחברי המועדון להמיר מטבעות שנצברו לזכותם ולקבל כנגדם
            הנחות במגוון רשתות ו/או הנחות בקניית שוברים שונים, כפי שיפורסם מעת
            לעת.
          </li>
          <li>
            יובהר כי, למנהל האתר אין התחייבות כלשהי לפדות את יתרת המטבעות בכסף.
          </li>
          <li>
            מימוש המטבעות הינו בכפוף לתנאים הבאים:
            <ol>
              <li>
                לצורך מימוש המטבעות, נדרשת פעולה אקטיבית של החבר באמצעות
                האפליקציה/אתר.
              </li>
              <li>
                החבר יידרש להזדהות בהתאם לדרישות הזיהוי של מנהל האתר כפי שיהיו
                מעת לעת.
              </li>
              <li>
                מימוש מטבעות בבתי העסק המכבדים, תתבצע מתוך אזור ה"ארנק" הנמצא
                באפליקציה. בלחיצה על כפתור "תשלום בבית עסק" (באזור הארנק), יופיע
                מחשבון בו יידרש החבר להקליד את הסכום המלא של העסקה, ולאשר לתשלום
                את הסכום (המופחת) שיופיע לאחר ניכוי המטבעות. את הקוד/ברקוד
                המתקבל יציג החבר לקופאי/ת.
              </li>
              <li>
                מימוש המטבעות אפשרי ברשימת הרשתות המפורטות (באזור הארנק), תחת
                הכפתורים "מסעדות" ו/או "אופנה".
              </li>
              <li> זהות הרשתות עשויה להשתנות ללא הודעה מוקדמת. </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <h5> עדכון פרטים אישיים.</h5>
        <ol>
          <li>
            בהצטרפות לאתר מאשר החבר כי המידע שנמסר על ידו ו/או ייאסף אודותיו
            (להלן: "המידע"), יישמר במאגרי המידע של מנהל האתר לצורך ייעול השירות
            והקשר עמו, לרבות לטובת צרכים תפעוליים, שיווקיים ודיוור ישיר.{" "}
          </li>
          <li>
            יובהר כי לא חלה על החבר חובה חוקית למסור את המידע ומסירתו הינה
            מרצונו ובהסכמתו.
          </li>
          <li>
            מנהל האתר יהיה רשאי להעביר את המידע לצדדים שלישיים (ובלבד שיתחייבו
            לשמור על פרטי החבר האישיים כנדרש על -פי דין), הנותנים שירות למנהל
            האתר, לצורך תפעול ההסדרים הנוגעים למועדון כמפורט בתקנון זה. מנהל
            האתר רשאי להסתייע בשירותים של ספקים חיצוניים באספקת שירות לחבר, אשר
            יתכן שתהיה גם להם גישה למידע בסיסי אודות החבר.
          </li>
          <li>
            בהתאם להסכמת החבר כדין, מנהל האתר ו/או מקס יהיו רשאים לפנות אל החבר
            בדיוור ישיר באמצעות דואר אלקטרוני, מסרונים (SMS), רשתות חברתיות,
            ובטלפון, וזאת כל עוד לא נתקבלה הודעה אחרת מהחבר בכתב.
          </li>
          <li>
            במסגרת ההרשמה יידרש החבר למסור מידע אישי לגביו, כדוגמת שם מלא, דרכי
            ההתקשרות עמו והכל בהתאם לתנאי השימוש של האתר/אפליקציה.
          </li>
          <li>
            באחריות החבר לדווח למנהל האתר בדבר שינוי בפרטיו האישיים. מנהל האתר
            לא יהיה אחראי לאי קבלת הטבה ו/או כל עדכון בקשר עם האתר/אפליקציה
            הנובע מאי עדכון פרטיו האישיים של החבר במועד.
          </li>
          <li>
            החבר מסכים בזאת באופן מפורש לשימוש במידע כאמור לעיל ומאשר כי השימוש
            בו לא ייחשב כפגיעה בפרטיותו ולא יזכה את החבר בכל סעד ו/או פיצוי.
            החבר רשאי לבטל את הסכמתו זו על ידי מתן הודעה בכתב למנהל האתר.
          </li>
        </ol>
      </li>
      <li>
        <h5> ביטול חברות באתר/אפליקציה</h5>
        <ol>
          <li>
            מנהל האתר יהא רשאי לבטל חברותו של חבר/משתמש, מכל סיבה שהיא מבלי שתהא
            לו חובה לנמק את סיבת הביטול ולרבות במידה והחבר לא עמד בהתחייבויותיו
            כלפי מנהל האתר ו/או מקס.
          </li>
          <li>
            מנהל האתר ו/או מקס יוכלו להודיע בכל דרך שימצאו לנכון על הפסקת פעילות
            האתר בהודעה כנדרש על פי דין. במקרה של הפסקת פעילות האתר כאמור, תינתן
            לחבר האפשרות לממש מטבעות שנצברו בהתאם להוראות תקנון זה, למשך תקופה
            הנדרשת על פי דין.
          </li>
        </ol>
      </li>
      <li>
        <h5>7. אבטחת מידע והגנת הפרטיות</h5>
        <ol>
          <li>
            מנהל האתר ישתמש באמצעי הגנה והצפנה SSL הנהוגים בתחום הסחר האלקטרוני
            בישראל ובארה"ב לפעולות של מסירת מידע מקוון, ובהתאם לנדרש על פי כל
            דין. אמצעי ההגנה האמור נועדו לאבטחת זרימת מידע מוצפנת בין מחשב חבר
            המועדון בזמן ביצוע הרכישה לבין שרתי המערכת.
          </li>
          <li>
            מנהל האתר ינהג במידע ובפרטים האישיים שנמסרו במסגרת השימוש באתר בהתאם
            ובכפוף להוראות כל דין. מבלי לגרוע מן האמור, מנהל האתר יהא רשאי
            להשתמש בפרטים האישיים ובמידע שמסרו החברים כאמור וכן לאסוף מידע אודות
            דפוסי השימוש שלהם באתר על ידי רישום השימוש שיעשו באתר (לרבות
            באמצעותcookies ) בכפוף לכל דין, במקרים הבאים:
            <ol>
              <li>
                לצורך העברתם לספקי התוכן על מנת להשלים את פעולת הרכישה שביצעו
                החברים באתר.
              </li>
              <li>
                לצורך שיפור המידע והתכנים שמנהל האתר יציע במסגרת האתר לחברים
                באופן אישי ו/או לכלל ו/או לחלק מהחברים באתר.
              </li>
              <li>
                על מנת לשלוח לחברים, מעת לעת, תכנים ו/או ליידע אותם בדבר מוצרים
                ושירותים שונים העשויים לעניין אותם והנמכרים על ידי מנהל האתר
                ו/או על ידי ספקי התוכן.
              </li>
              <li>
                לצורך ניתוח מידע סטטיסטי על ידי מנהל האתר ו/או מסירתו לצדדים
                שלישיים לרבות למפרסמים ובלבד שמידע כאמור לא יזהה את החברים אישית
                באופן שמי.
              </li>
              <li> לצורך יצירת קשר עם החברים במקרה הצורך.</li>
              <li> לצורך תחזוקת האתר ו/או המערכת.</li>
            </ol>
          </li>
          <li>
            ידוע לחברים כי מנהל האתר מקדיש משאבים ונוקט אמצעים מחמירים למניעת
            חדירה לאתר ולסיכול פגיעה אפשרית בפרטיותם, אולם הוא אינו יכול למנוע
            שיבושים באתר באופן מוחלט.
          </li>
        </ol>
      </li>
      <li>
        <h5> כללי</h5>
        <ol>
          <li>
            למנהל האתר נשמרת הזכות להוסיף, לשנות ולהחליף בכל עת את תנאי התקנון,
            ללא הודעה מוקדמת ועל פי שיקול דעתו הבלעדי והמוחלט, ובכלל זאת הזכות
            לפרש את הוראותיו, בכפוף לדין. החל ממועד השינוי של התקנון יחייב את
            האתר נוסחו החדש בלבד. נוסחו המחייב של התקנון הוא זה שיופיע
            באתר/אפליקציה בלבד.
          </li>
          <li>
            מנהל האתר יהא רשאי לשנות את שם המועדון, סמליו וכד' בכל עת לפי שיקול
            דעתו הבלעדי בלבד.
          </li>
          <li>
            מתן הטבות לחברים הינם בכפוף לפעילות תקינה של מערכות התפעול והמחשוב
            של מנהל האתר וצדדים שלישיים רלוונטיים. במידה ויחולו תקלות אשר ימנעו
            באופן זמני את מתן ההטבות כאמור לעיל, יפעל מנהל האתר לטיפול בתקלות
            בהקדם ולא תהיה לחברים טענות או תביעות כנגד מנהל האתר בשל אי מתן
            ההטבות בתקופת התקלות והטיפול בהן.
          </li>
          <li>
            לחבר לא תהיה כל דרישה ו/או תביעה בקשר עם התקנון ו/או שינוי התקנון.
          </li>
        </ol>
      </li>
    </ol>
  ),
  benefits: (
    <ol>
      ברוכים הבאים לאתר/אפליקציה "המילואימניקים פלוס" (להלן: "האתר"). האתר מופעל
      על ידי חברת בנפיטס מועדוני צרכנות בע"מ; שהינה מנפיקת השוברים (להלן:
      "בנפיטס" ו/או "מפעיל האתר" ו/או "מנהל האתר" לפי העניין ו/או ההקשר). באתר
      תוכל לרכוש שוברי מתנה כמפורט להלן. חשוב לנו שתדע- אצלנו הלקוח הוא במקום
      הראשון. חשוב לנו שהלקוח יהיה מרוצה, וזאת על ידי הקפדה יתרה על ביטחון
      הקנייה, קשר ישיר ורציף מול בתי העסק ושירות לקוחות מהמעלה הראשונה . לכן,
      חשוב לנו שלפני הרכישה (או לפני מימוש השובר שכבר בידך, לפי העניין) תקפיד
      ותקרא את התקנון. כל הוראות תקנון זה יוחלו, בהתאמה ובשינויים המחויבים, על
      כל שובר שהונפק ע"י "בנפיטס", אף אם נרכש שלא דרך האתר. כמו כן, בכל מקרה של
      שובר מודפס (נייר) שהמחזיק מבקש להמירו לשובר דיגיטלי, יש להיכנס ללשונית
      הרלוונטית באתר ולהתעדכן בתנאים וברשימת בתי העסק.
      <ol>
        <ol>
          <li>
            <h5>כללי</h5>
            האתר/אפליקציה מופעל על ידי מפעיל האתר כהגדרתו להלן, המנהל חנות
            וירטואלית לציבור הגולשים ברשת האינטרנט בישראל. הוראות תקנון זה יחולו
            על כל גלישה באתר, ובכלל זה על כל גולש הנכנס לאתר ו/או מבצע ו/או
            מעוניין לבצע הזמנה באמצעות האתר (ובכלל האמור באמצעות אפליקציה או בכל
            דרך מקוונת אחרת), ולפיכך הנך מתבקש לקרוא את התקנון בעיון, שכן
            הוראותיו תחייבנה ותהוונה את הבסיס המשפטי בכל דין ודברים בינך לבין
            מפעיל האתר ו/או מנהליו ו/או "המילואימניקים פלוס", ובכניסתך לאתר
            והמשך גלישה בו הנך מצהיר ומתחייב כי הנך מודע לכך, מאשר זאת, מצהיר
            ומתחייב כי הנך מודע לתקנון האתר ומאשר אותו. הרשמה לאתר ומסירת פרטים
            אישיים הינה זכות ולא חובה.
            <br />
            למנהלי האתר שמורה הזכות, בכל עת וללא צורך בהודעה מראש, לשנות מעת
            לעת, על פי שיקול דעתם הבלעדי, תקנון זה ו/או את תנאי השימוש באתר ו/או
            את תכניו של האתר, המידע המפורסם בו, השירותים ו/או המוצרים המוצעים
            במסגרתו, וכל הנובע משינויים אלה. לכן, באחריות המשתמש באתר לקרוא תנאי
            שימוש אלה בכל כניסה לאתר, ולא תהיה לו כל טענה ו/או תביעה ו/או דרישה
            כלפי מנהל האתר בקשר לכך. כל שינוי ייכנס לתוקף מיד עם פרסומו באתר,
            והוא יחול על כל משתמשי האתר ו/או על כל מי שמחזיק בשוברים שהונפקו ע"י
            בנפיטס. הגלישה באתר אינה כרוכה בתשלום, אולם רכישת מוצרים ו/או
            שירותים באתר כרוכה בתשלום ובהזנת פרטים.
          </li>
          <li>
            <h5>הגדרות.</h5>
            "מנהל/י האתר" ו/או "מפעיל האתר"- חברת בנפיטס מועדוני צרכנות בע"מ וכל
            הבאים מטעמה.
            <br />
            "שובר"- שובר המעניק לזכאי לאחוז בו, בכפוף לפירעון התמורה בעדו, זכות
            חד פעמית, הנרכשת במסגרת האתר, לקבלת מוצר או שירות תמורת מימושו,
            כמפורסם וכמפורט ביחס לשובר, אלא אם כן מדובר בתו שי או בשובר מתנה
            ("גיפט קארד") בעלי ערך כספי נקוב, ובמקרה כזה ניתן יהיה לממשו מספר
            פעמים (אך לא בעסקת תשלומים נדחים), עד לפירעון מלוא הערך הנקוב.
            <br />
            השובר יכול להיות בעל שווי כספי (ערך נקוב) ו/או שובר מתנה המיועד
            לרכישת מוצרים ספציפיים או קבלת שירות ספציפי או חוויות ברשתות מובילות
            בארץ (כגון קופון, למשל), על פי המפורסם באתר זה.
            <br />
            השובר יישלח ישלח לאפליקציה ו/או בדוא"ל ו/או באופן מודפס, לפי בחירת
            הגולש באתר.
            <br />
            "משתמש" או "משתמש באתר"- כל אדם בגיר (מעל גיל 18) העושה שימוש כלשהו
            באתר;
          </li>
          <li>
            <h5>תנאים כלליים</h5>
            כל משתמש/ גולש מצהיר כי הוא מקבל על עצמו את הוראות תקנון זה ותנאי
            השימוש באתר המפורטים להלן (להלן: "תקנון האתר" או "התקנון"), וכי לא
            תהא לו ו/או למי מטעמו כל טענה ו/או תביעה כנגד בעלי האתר ו/או מפעיליו
            ו/או מי מטעמם מלבד טענות הקשורות בהפרת התחייבויות בנפיטס ו/או בעלי
            האתר ו/או מפעיליו על פי תקנון זה. למען הסר ספק, המוצרים ו/או
            השירותים המוצעים לרכישה במסגרת האתר, מוצעים למכירה על ידי בתי העסק
            השונים המזוהים בכל אחת מעסקאות המכירה ולא על ידי בנפיטס ו/או האתר
            ו/או מנהליו (למעט השוברים עצמם, אותם מנפיקה בנפיטס). בית עסק
            הרלוונטי בקשר עם מימוש השובר הוא יהיה הצד שיישא באחריות הבלעדית בגין
            השירות ו/או המוצרים ו/או הרכישה המתבצעת באמצעות השובר, וכל הנובע
            מהם. מנהל האתר שומר על זכותו לשנות תקנון זה מזמן לזמן. רק הכללים
            הכלולים בתקנון זה, כפי שהוא מעודכן באתר, יחייבו את מנהל האתר כלפי
            משתמשי וגולשי האתר.
          </li>
          <li>
            <h5>תנאי השימוש באתר</h5>
            הרשאים לעשות שימוש באתר הינם כל אדם תושב מדינת ישראל בן 18 ומעלה
            (וכן חברה המאוגדת כדין לפי חוקי מדינת ישראל והרשומה ברשם החברות
            בישראל). זהו, מבלי לגרוע מהאמור לעיל, תנאי מוקדם לביצוע הזמנה באתר.
            רכישת המוצרים באתר הינה באמצעות כרטיס אשראי. כל רכישה באתר מחייבת
            לקבל את אישור הגורם הרלוונטי (כגון חברת כרטיסי האשראי), אשר דרכה
            התחייב הלקוח לביצוע התשלום עבור עסקת הרכישה של המוצרים ו/או השירותים
            שמציע האתר. המוצרים והשירותים המוצעים באתר מיועדים לשימוש הפרטי
            והאישי של הלקוחות ולא למטרות מסחריות ו/או סיטונאות. מנהל האתר רשאי
            למנוע מלקוח כלשהו את השתתפות והשימוש באתר, באופן זמני או לצמיתות, על
            פי שיקול דעתו הסביר, בכפוף לכל דין, לרבות אם יהא סבור כי הלקוח עלול
            לסחור בהם או להעביר באופן אחר לצד שלישי, וזאת מבלי צורך לנמק החלטתו
            ו/או מבלי להודיע ללקוח על כך מראש. מנהל האתר אף שומר לעצמו את הזכות,
            על פי שיקול דעתו הסביר ובכפוף לכל דין, לבטל את רכישתו של המשתמש
            בנסיבות אלו ו/או בנסיבות אחרות, בהן, למשל לדעת מנהל האתר, נמסרו
            פרטים שגויים באופן מכוון ו/או המשתמש נהג בניגוד להוראות תקנון זה
            ו/או פרטי כרטיס האשראי עמו בוצע התשלום נחסם ו/או לדעת מנהל האתר קיים
            חשש סביר כי המשתמש עשה שימוש העלול לפגוע בצדדים שלישיים ו/או במנהל
            האתר. אין בחסימה, בהפסקה או בהגבלת השירותים או ביטולם, כאמור לעיל,
            כדי לגרוע מחובתו של הלקוח לנהוג בכל עת בהתאם לתנאי שימוש אלה. כל
            שימוש של הלקוח באתר, לרבות בשירותים ובתכנים שבאתר, בניגוד להוראות
            מנהל האתר ו/או בניגוד לתנאי שימוש אלה ו/או בניגוד להוראות כל דין,
            יזכה את מנהל האתר ו/או צדדים שלישיים בעלי זכויות בשירותים ובתכנים
            לפי העניין, בנקיטת אחד או יותר מן הסעדים הקבועים בתקנון זה כלפי
            הלקוח, וזאת מבלי לגרוע מכל סעד אחר העומד לזכות מנהל האתר ו/או צדדים
            שלישים בעלי זכויות בשירותים ובתכנים על פי כל דין. כל גולש אשר נרשם
            לאתר מתבקש לאשר את התקנון ע"מ להשלים את האישור. גולשים אשר מבצעים
            רישום באמצעות גוגל או פייסבוק בדף הרישום מאשרים באופן אוטומטי את
            התקנון ותנאי השימוש באתר.
          </li>
          <li>
            <h5> הרכישה באתר, השובר ומימושו</h5>
            מובהר בזאת כי תנאי סף לקבלת שובר ו/או למימושו, הינה כי תמורתו המלאה
            שולמה. התשלום במסגרת רכישות שביצע המשתמש באתר ייעשה על ידי המשתמש,
            באמצעות הזנת פרטי כרטיס אשראי לחיוב וכן מסירת פרטים אישיים. כל
            הוראות תקנון זה יוחלו, בהתאמה ובשינויים המחויבים, על כל שובר שהונפק
            ע"י בנפיטס, אף אם נרכש שלא דרך האתר. פרטי הרכישה יועברו לאישור חברת
            כרטיס האשראי (או הגורם המשלם), אשר תאשר או תדחה את העסקה. רק עסקאות
            אשר אושרו תזכינה את המשתמש בקבלת השובר ומימושו. בכל מקרה של דחיית
            העסקה על ידי חברת האשראי (או הגורם המשלם), תשלח למשתמש הודעה מתאימה,
            והוא יידרש לפרוע את התשלום בכל דרך אחרת בו יורה לו מנהל האתר. במקרה
            כזה, השובר, אם וככל שנשלח, יהיה חסום לשימוש וזאת עד להסדרת התשלום,
            ולמשתמש ו/או לכל צד ג' שהוא לא תהיה כל טענה ו/או דרישה ו/או תביעה
            בעניין. כמו כן, מובהר ומוסכם בזאת כי, בכל מקרה, למנהל האתר שמורה
            הזכות לסרב ו/או שלא לקבל ו/או לבטל עסקה, אף אם אושרה על ידי חברת
            האשראי, על פי שיקול דעתו הבלעדי וללא צורך לנמק את החלטתו. לכל שובר
            תיקבע תקופת מימוש, במסגרתה תוכל לממשו. המחירים המפורסמים באתר ביחס
            לשוברים הינם מחירים כוללים, אלא אם כן צוין במפורש אחרת. השובר יישלח
            למשתמש האתר ו/או לצד ג' כלשהו בהתאם לבחירת משתמש האתר, אשר באחריותו
            יהיה להזין את פרטיו וכל הנובע מכך, באמצעים עליו יחליט מנהל האתר,
            ומימוש השובר ייעשה אף הוא באופן בו יחליט עליו מנהל האתר, מעת לעת.
            <br />
            השובר יכובד בבתי העסק כפי שיפורטו באתר זה. יש לפנות טלפונית לבית
            העסק הנבחר ולוודא ישירות את תנאי מימוש השובר.
            <br />
            השובר אינו מיועד לשימוש של משלם מסוים, וניתן להעבירו גם לשימוש כל צד
            ג' שהוא, ובלבד שתמורתו המלאה שולמה לבנפיטס.
            <br />
            סכום המירבי שניתן לרכוש/ לצבור/ לממש ביחס לכל שובר מוגבל עד לסך של
            1,500 ₪ ואינו ניתן לטעינה נוספת.
            <br />
            החיוב באמצעות השובר יהיה מיידי ולא ניתן לבצע באמצעותו עסקאות
            תשלומים.
            <br />
            במקרה של חדלות פירעון של בית העסק אשר אמור היה לספק את השירות ו/או
            המוצר נשוא השובר, על המחזיק בשובר לפנות לחברת בנפיטס, אשר תזכה אותי
            בגין הערך הנקוב של השובר שלא מומש.
            <br />
            במקרה של שוברים עם ערך צבור, הסכום הנקוב יהיה ניתן למימוש באופן אחיד
            לגבי כל בתי העסק הרלוונטיים לאותו השובר.
            <br />
            על שובר מסוג "שובר מתנה" כהגדרתו בחוק הגנת הצרכן- ניתן יהיה למימוש
            בהתאם להוראות סעיף 14ז. לחוק הגנת הצרכן.
            <br />
            על שובר מסוג "תו קניה" כהגדרתו בחוק הגנת הצרכן- ניתן יהיה למימוש
            בהתאם להוראות סעיף 14ח. לחוק הגנת הצרכן.
            <br />
            באחריותך המלאה והבלעדית לממש השובר במסגרת תקופת המימוש.
            <br />
            תוקף שובר שהינו מסוג "תו קנייה" כהגדרתו בחוק הגנת הצרכן- חמש שנים;
            תוקף שובר שהינו "שובר מתנה" כהגדרתו בחוק הגנת הצרכן- שנתיים תוקף
            שובר מסוג "קופון" לקבלת הטבה ו/או מתנה ו/או מוצר ו/או שירות ספציפי
            הינו כפי שמפורט בתנאים המתייחסים לשובר מסוג קופון.
            <br />
            *על אף האמור, שוברים שתוקפם פג יוארכו לאחר פניית הלקוח אל בנפיטס,
            ויהיו ניתנים למימוש עד לתאריך 31/12/2030 .
            <br />
            במקרה של אובדן או גניבה של השובר, על הלקוח (מי שנחזה כמי שהחזיק כדין
            בשובר) לפנות לחברת בנפיטס, אשר מצדה תנסה, במאמצים סבירים, לשחזר את
            השובר והיתרה הצבורה במערכותיה, ובלבד שהשובר לא מומש ע"י צד ג' כלשהו
            וכי קיימת יתרת זכות בשובר. האמור לא יחול על שובר נייר (מודפס).
            <br />
            חובה על הלקוח שהזמין שובר הכרוך בפעילות או שירות, לבצע אישור הזמנה
            טלפוני מול מפעיל החוויה לפחות יום לפני התאריך המתוכנן על מנת לוודא
            מזג אויר ותנאים טכניים.
            <br />
            עדכונים ושינויים - בתי העסק, שעות פתיחה וזמינות ומגוון ההטבות עשויים
            להשתנות מעת לעת, אף לאחר רכישת השובר/ים. לכן, לפני מימוש השובר, יש
            להתעדכן ברשימת בתי העסק העדכנית המפורטת באתר. ומובהר בזאת כי בכל
            מקרה של סתירה, רשימת בתי העסק המפורטת באתר היא העדכנית והגוברת.
            <br />
            כמו כן, המוצרים ו/או השירותים באתר עשויים להשתנות מעת לעת, כל זמן
            שלא סופקו בפועל.
            <br />
            מנהל האתר רשאי בכל עת להורות על שינוי טיבם, סוגם ומחיריהם של המוצרים
            ו/או השירותים המוצעים למכירה במסגרת האתר.
            <br />
            לכן, לפני מימוש השובר בפועל, יש להתעדכן באתר, ולוודא כי לא חלו
            שינויים. מובהר בזאת כי בכל מקרה של סתירה, רשימת בתי העסק ו/או ההטבות
            ו/ו המוצרים ו/או השירותים המפורטת באתר היא העדכנית והגוברת.
            <br />
            יום ביצוע ההזמנה באתר, הינו היום בו אישרה חברת האשראי את העסקה שביצע
            הלקוח באתר.
            <br />
            ימי עסקים לצורך קיום התחייבויות האתר, מנהליו ומפעיליו, הינם ימי חול,
            להוציא ימי שבת, מועדים, חוה"מ וכן ימי חירום, ימי מלחמה, או ימים
            החוסים תחת כוח עליון.
            <br />
            מובהר כי במקרים חריגים ונדירים ו/או מחמת נסיבות שאינן בשליטת מפעילת
            האתר ו/או מחמת שינויים ברשימת בתי העסק או המוצרים ו/או השירותים
            המפורסמים באתר במועד הרכישה ועד למועד המימוש, ייתכן ומוצרים/ שירותים
            שהוזמנו במסגרת האתר, אך טרם סופקו, אינם במלאי ו/או לא ניתן יהיה
            לספקם, חרף הזמנתם באתר. במקרה כזה, מפעילת האתר תזכה את הלקוח בגין
            המוצרים/ שירותים שלא ניתן לספק, או תעמיד לרשות הלקוח את האפשרות
            להזמין מוצרים / שירותים חלופיים – לפי בחירת הלקוח.
            <br />
            הנך מסכים ונותן את רשותך לכך שמפעילי האתר ישתמשו בפרטים שמסרת במסגרת
            רכישת המוצרים / שירותים, לצורך אספקתם וכל הכרוך בעסקה ו/או הנובע
            ממנה. הפרטים שיימסרו, לרבות שם הלקוח וכתובתו, הינה באחריות הלקוח
            בלבד, והאתר לא יהיה אחראי לכל אובדן או נזק הנובע ממילוי פרטים חסרים
            או שגויים. האמור יחול גם במקרה של מסירת פרטים של צד ג' על ידי המשתמש
            (כגון במקרה של רכישת מתנה לצד שלישי).
          </li>
          <li>
            <h5> הצטרפות לדיוור ישיר/ משלוח דברי פרסומת/ הרשמה לניוזלטר</h5>
            הנך מסכים ונותן את רשותך לכך שמפעילי האתר ישתמשו בפרטים שמסרת, אתה
            או צד ג' בשמך ו/או אתה בשם ועבור צד ג', במסגרת ההירשמות לאתר ו/או
            במסגרת הרכישה באתר ו/או במסגרת קבלת שובר אשר נרכש באתר ו/או במסגרת
            ההרשמה לכל אחד מהשירותים המוצעים על ידי האתר או השירותים הנוספים
            המוצעים על ידי מפעיליו וכל עדכון של פרטים אלו וכן בכל מידע שייאסף
            אודות דפוסי השימוש שלך (לרבות באמצעות COOKIES) לצורך שיפור השירות
            המוצע על ידי האתר ועל מנת ליידע אותך בדבר מוצרים ושירותים (המוצעים
            על ידי האתר או מפעיליו ועל ידי צדדים שלישיים) העשויים לעניין אותך
            ולשגר אליך דברי פרסומת של מפרסמים. את המידע כאמור ואת דברי הפרסומת
            מפעילי האתר יהיו רשאים להביא לידיעתך באמצעות:
            <br />
            משלוח לכתובת המגורים שלך
            <br />
            משלוח הודעות אלקטרוניות לכתובת הדואר האלקטרוני אשר מסרת
            <br />
            הודעות מסרים קצרים, בין השאר לטלפון הסלולארי שלך (SMS)
            <br />
            מערכות חיוג אוטומטיות להעברת פקסימיליה או מסר קולי מוקלט לטלפון
            הנייד שלך או הקווי
            <br />
            הסכמתך כאמור לעיל מהווה הסכמה לשיגור דברי פרסומת על פי חוק התקשורת
            (בזק ושידורים) (תיקון מס' 40), התשס"ח- 2008. מובהר כי על אף הסכמתך
            כאמור, אתה תהא רשאי לחזור בך מהסכמה זו על ידי משלוח הודעת סירוב לאתר
            בכתב או בדרך בה שוגר אליך דבר הפרסומת. מובהר שמפעילי האתר אינם
            אחראים לגבי תוכן הודעות שכאלה או טובין או שירותים שסופקו באמצעותן
            ע"י צד ג' למשתמש ו/או לצד ג' כלשהו, ואתה מסכים כי לא תהיה למפעילי
            האתר ולאתר כל חבות או אחריות בקשר לעניין זה.
          </li>
          <li>
            <h5>זמני אספקה ותשלום</h5>
            כל שובר הנמכר באתר "המילואימניקים פלוס" ניתן למימוש מידי, בכפוף
            לפירעון תשלום התמורה בגינו.
            <br />
            למימוש השובר יש להציג את מספר השובר המופק ע"י אתר "המילואימניקים
            פלוס" בפני בית העסק (במידה ומדובר באתר בילוי או נופש יש לתאם מראש עם
            הספק את מועד ההגעה). יש לשים לב לתנאים המצוינים על השובר ולשעות
            הפעילות.
          </li>
          <li>
            <h5>ביטול עסקה</h5>
            ביטול רכישת השובר יתבצע בהתאם להוראות חוק הגנת הצרכן, התשמ"א-1981
            (להלן- "החוק") ו/או בהתאם להוראות כל דין, לרבות תקנות. ניתן לבטל את
            רכישת השובר תוך 14 יום מיום ביצוע הרכישה, וזאת במידה והשובר לא מומש.
            במקרה כזה יתקבל זיכוי בניכוי עמלת דמי ביטול בסך 5% ממחיר העסקה או
            100 ₪, הנמוך מבניהם. במידה והשובר הינו לרכישת מוצר, והשובר מומש בבית
            העסק, החזרת המוצר הינה לפי תקנון בית העסק. במקרה של ביטול עסקה,
            יבוטל השובר ולא ניתן יהיה לממשו בבית העסק.
          </li>
          <li>
            <h5>אחריות למוצרים/ שירותים המוצעים במסגרת האתר</h5>
            האחריות הבלעדית למוצרים ולמתן לשירותים באתר, לרבות איכותם, טיבם
            ותקינותם חלה באופן בלעדי על בתי העסק ו/או הספקים ו/או היצרנים ו/או
            היבואנים ו/או הסיטונאים ו/או המשווקים המורשים של המוצרים/ השירותים,
            ע"פ החוקים והתקנות להגנת הצרכן הנהוגות בישראל. בשום נסיבות לא תחול
            על האתר ו/או מי מטעמו אחריות כלשהי בגין נזק ישיר ו/או עקיף, מכל מין
            וסוג שהוא, הקשור בשימוש באתר ו/או בהתקשרות המחזיק בשובר עם איזה מבתי
            העסק, לרבות, ומבלי לפגוע בכלליות האמור לעיל, דמי נזיקין בגין אובדן
            השימוש, אובדן הנתונים או אובדן הרווחים, הנובעים או קשורים בכל דרך
            שהיא בשימוש או בביצועי האתר, בגין עיכוב בשימוש או באי יכולת להשתמש
            בו, באספקה או באי אספקה של שירותים, או בכל מידע, תוכנה, מוצר, שירות
            וגרפיקה נלווית שהושגו באמצעות האתר, ו/או הנובעים בכל דרך אחרת מן
            השימוש באתר, בין בהתבסס על הסכם ובין בנזיקין, בין באחריות מוחלטת,
            ו/או כל עילה אחרת, אפילו אם נודע לאתר ו/או למי מטעמו על האפשרות של
            דמי נזיקין כאמור. האחריות המקצועית ו/או טיב המוצרים ועמידתם בלוחות
            הזמנים יחולו על בתי העסק בלבד. בכלל האמור, בנפיטס אינה אחראית בכל
            הנוגע לפרטים שמסרו או לא מסרו בתי העסק השונים, ואינה אחראית בכל
            הנודע ליחסים החוזיים ו/או הצרכניים בין המחזיק בשובר לבין בית העסק.
          </li>
          <li>
            <h5>שיפוי </h5>
            המשתמש מתחייב לפצות ו/או לשפות, לפי העניין, את מנהל האתר ו/או מי
            מטעמו, ובכלל האמור: מנהליו, עובדיו, נציגיו, וכל הפועל בשמו ו/או
            מטעמו, מיד עם דרישתו הראשונה, ללא כל סייג וללא תנאי, בגין כל
            הוצאותיו, מכל מין וסוג שהוא, בגין כל נזק ו/או הוצאה ו/או פגיעה ו/או
            הפסד, מכל מין וסוג שהוא, שייגרמו לו עקב הפרת הוראות תנאי השימוש באתר
            ע"פ תקנון זה ו/או הפרת הוראת דין ו/או עקב כל תביעה ו/או טענה ו/או
            דרישה שתופנה נגד מנהל האתר על ידי צד שלישי כלשהו, בשל מעשי ו/או
            מחדלי המשתמש.
          </li>
          <li>
            <h5> מדיניות פרטיות ואבטחת מידע</h5>
            תוך הכרה בחשיבות המידע הנמסר במסגרת האתר, מנהל האתר נוקט, כעניין
            שבמדיניות, בכל האמצעים הסבירים על מנת להגן על מידע משתמשי האתר,
            באמצעים שמבטיחים גישה למורשים בלבד על פי סיווג ומידור מתאימים ובכך
            למנוע זליגה, גניבה, מחיקה או סילוף של מידע זה. לצורך זאת מיישם מנהל
            האתר בקרות אבטחת מידע מתאימות הכוללות בין היתר אמצעים מנהלתיים
            ואמצעים טכניים מתאימים. מנהל האתר מחויב לדאוג לסביבת מחשוב זמינה,
            רצופה, אמינה ויעילה כדי לתמוך בפעילותו עסקית וכפועל יוצא מכך, לנקוט
            בכל האמצעים הדרושים כדי למנוע סיכונים הנובעים מחדירה של גורמים בלתי
            מורשים למערכות שבאחריותו, לשמר את זמינות המידע, ולהגן עליו מפני הרס,
            פגיעה, חשיפה במזיד או במקרה, או שינוי לא מוסמך. מנהל האתר יפעל
            לאבטחת פרטיו האישיים של המשתמש שימסרו באתר (ככל שימסרו), לשם שמירה
            על פרטיותו. עם זאת, מנהל האתר אינו יכול להבטיח כי מערכותיו חסינות
            בפני פריצות ו/או חדירות בלתי מורשות, ובמקרה כאמור לא תהיה למשתמש כל
            טענה ו/או תביעה ו/או דרישה בעניין. מנהל האתר וכל גורם אחר הקשור
            באספקת השירותים והתכנים שבאתר לא יישאו באחריות כלשהי לכל נזק ו/או
            הפסד ו/או אובדן כלשהם, ישירים או עקיפים שיגרמו או העלולים להיגרם,
            במישרין או בעקיפין, למשתמש ו/או לצד ג' כתוצאה מפריצה ו/או חדירה בלתי
            מורשים למערכות מנהל האתר ו/או למערכות הספקים. מנהל האתר נוקט באמצעי
            זהירות מקובלים על מנת לשמור, ככל האפשר, על סודיות המידע שבאתר. כל
            העברה של מספר כרטיס אשראי מהאתר נעשית באופן מוצפן לפי תקן. עם זאת,
            במקרים שאינם בשליטתו ו/או הנובעים מכוח עליון, מנהל האתר לא יהא אחראי
            לכל נזק מכל סוג שהוא, עקיף או ישיר, שייגרם למשתמש ו/או לצד ג' ו/או
            למי מטעמם, אם מידע זה ייאבד או אם יעשה בו שימוש לא מורשה, למעט לנזק
            ישיר אם נזק כאמור נגרם בשל רשלנותה (ככל שתקבע בבית המשפט) של מנהל
            האתר. מנהל האתר ימנע ככל האפשר ממסירת פרטיך האישיים לצדדים שלישיים,
            אלא אם היא יהיה מחויב לעשות כן על פי צו שיפוטי או אם יעמוד בפני איום
            שינקטו כנגדו צעדים משפטיים (פליליים או אזרחיים) בגין פעולות שנעשו על
            ידי המשתמש באתר. במקרה זה רשאי מנהל האתר למסור את פרטיך לצד הטוען כי
            נפגע ממך או בהתאם להוראות הצו השיפוטי. מנהל האתר רשאי להשתמש בפרטיך
            לצורך שיפור השירותים שהוא מציע באתר, וכן ליצירת קשר עמך ו/או עם צד
            שלישי והתאמת האתר לצרכי המשתמשים בו והעדפותיהם האישיות.
          </li>
          <li>
            <h5>קניין רוחני</h5>
            כל זכויות הקניין הרוחני, מכל מין וסוג שהוא, לרבות ובמיוחד: זכויות
            יוצרים, פטנטים, סימני מסחר, סודות מסחריים וכל כל מידע אחר, הינם
            ויהיו קניינו הבלעדי והמלא של מנהל האתר, ובכלל האמור אף זכויות אשר
            תצמחנה כתוצאה מהשימוש באתר ו/או מהרכישה במסגרתו. לפיכך, המשתמש אינו
            רשאי לעשות בתכני האתר כל שימוש (למעט השימוש אשר הותר באופן מפורש
            במסגרת תקנון זה) ו/או אינו רשאי לשכפל ו/או להעתיק ו/או לשנות ו/או
            להעניק רישיונות ו/או הרשאות ו/או להעביר ו/או למכור, בכל אופן, דרך או
            צורה שהיא, כל דבר ועניין שהוא הקשורים באתר, בין במישרין ובין
            בעקיפין. כמו כן, כל זכויות הבעלות באתר, לרבות בעיצוב, וכן בכל תוכנה,
            יישום, קוד מחשב, קובץ גרפי, וטקסט וכל חומר אחר הכלול באתר, הינן
            רכושו הבלעדי של מנהל האתר ויוותרו בבעלותו המלאה בכל עת.
          </li>
          <li>
            <h5>שונות</h5>
            במקרה בו לא אושרה העסקה ע"י חברות האשראי יקבל מבצע הפעולה הודעה
            מתאימה. לצורך השלמת הרכישה יידרש מבצע הפעולה ליצור קשר טלפוני עם
            מוקד שירות הלקוחות של "המילואימניקים פלוס" לצורך הסדרת אישור חברות
            האשראי לביצוע העסקה. יובהר ויודגש, פעולה תחשב כמושלמת רק לאחר הסדרת
            אישור חברות האשראי לביצוע העסקה ע"י מבצע הפעולה. השימוש באתר הינו
            שימוש חופשי ולא מוגבל. התכנים אשר מופיעים, ניתנים לשימוש כמות שהוא
            (AS IS) בהתאם להחלטת מנהל האתר ולא תהיה לך כל טענה, תביעה או דרישה
            כלפי מנהל האתר בגין תכונות השימוש, המגבלות או התגובות שיעורר הצגת
            המוצרים באתר ובמכשירי הקצה. השימוש, אפוא, בתכנים המוצגים באתר
            ובמכשירי הקצה ובשירותי האתר, יעשה על אחריותך הבלעדית והמלאה. הנך
            מתחייב, לא לפרסם ברבים כל חלק, מוצר ו/או פלט של התכנים, בין שהם
            מודפסים כטקסט, כשרטוט או כדו"ח ובין שהוא ניתן כקובץ על גבי אמצעים
            מגנטיים או בכל צורה אחרת וכי לא ישכפל, לא יצלם, לא יעתיק ולא ידפיס
            כל מוצר ו/או פלט כאמור לעיל מתוך התכנים או כל חלק מהם לצורך הפצה או
            פרסום בכל דרך שהיא. כן, מתחייב הגולש כי לא יאפשר, בין במישרין ובין
            בעקיפין, בין בתמורה ובין ללא תמורה, את ביצוע אחת או יותר מהפעולות
            המפורטות לעיל. מנהל האתר רשאי לסגור את האתר ולשנות מעת לעת את מבנהו,
            מראהו ואופי המוצרים, השירותים והתכנים הניתנים בו וזאת ללא צורך
            להודיע לך על כך מראש. לא תהיה לך כל טענה, תביעה או דרישה כלפי האתר
            בקשר לכך. מנהל האתר רשאי לסגור את האתר לצורך עדכונים ו/או שדרוגים
            ו/או לשנות, מעת לעת, את מבנהו, מראהו וזמינותם של המוצרים, השירותים
            והתכנים הניתנים בו, וזאת ללא צורך בהודעה מראש למשתמש.
          </li>
          <li>
            <h5>דין ושיפוט</h5>
            הדין החל על הפעולות באתר ועל תקנון זה, הוא הדין הישראלי בלבד. בכל
            מקרה של מחלוקת - תהיה לבתי המשפט המצויים במחוז תל אביב-יפו או במחוז
            מרכז הסמכות הבלעדית לדון בתביעה.
          </li>
        </ol>
      </ol>
    </ol>
  ),
  default: <ol></ol>,
};

export default function Rules() {
  const projectName = process.env.REACT_APP_PROJECT || "default";
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <h5
            style={{
              textAlign: "center",
              marginTop: "25px",
            }}
          >
            <b>תקנון, תנאי שימוש ומדיניות הפרטיות</b>
          </h5>
          <div className="rules"> {rulesByProject[projectName]}</div>
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
}
