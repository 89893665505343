import * as React from "react";
import { useDispatch } from "react-redux";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { logout, setSidebarState } from "../../redux/actions-exporter";
import {
  Divider,
  Container,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LogoutIcon from "@mui/icons-material/Logout";
import SecurityIcon from "@mui/icons-material/Security";
import BlockIcon from "@mui/icons-material/Block";
import GavelIcon from "@mui/icons-material/Gavel";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

const theme = createTheme({
  direction: "rtl",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function Menu({ setItem }) {
  const arr = [
    // { icon: <LightbulbIcon />, title: "אודות" },
    { icon: <GavelIcon />, title: "תקנון" },
    { icon: <SecurityIcon />, title: "רכישה מאובטחת" },
    { icon: <BlockIcon />, title: "מדיניות ביטול עסקה" },
    { icon: <AlternateEmailIcon />, title: "יצירת קשר" },
  ];
  const dispatch = useDispatch();

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <MenuList
            sx={{
              "& .MuiListItemText-root": { textAlign: "start !important" },
            }}
          >
            {arr.map((item, i) => (
              <MenuItem
                sx={{ my: 2 }}
                onClick={() => setItem(item.title)}
                key={i}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  sx={{
                    "&>span": { fontSize: "1.5rem !important" },
                  }}
                >
                  {item.title}
                </ListItemText>
              </MenuItem>
            ))}

            <Divider />
            <MenuItem
              onClick={() => {
                dispatch(logout());
                dispatch(setSidebarState(false));
              }}
            >
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                sx={{
                  "&>span": { fontSize: "1.5rem !important" },
                }}
              >
                התנתקות
              </ListItemText>
            </MenuItem>
          </MenuList>
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
}
