import React from "react";
import { shortenString } from "../../helpers/shortenString";
import { Box, Card, Chip, Stack, Typography } from "@mui/material";
import {
  getStatusColor,
  getStatusString,
} from "../../helpers/statusStringsAndColors";

const BenefitCard = ({
  benefit,
  onClick = null,
  isInactive = false,
  type = "default",
}) => {
  const { product, status, balance } = benefit;
  const imgUrl =
    process.env.REACT_APP_API_URL + "/upload" + product.mainImageURL;

  return (
    <Card
      sx={{
        mb: 2,
        px: 1,
        py: 2,
        position: "relative",
        "@media (max-width:400px)": {
          px: 2,
        },
        ...(type === "bybenefit" && {
          // border: "1px solid var(--primary)",
          boxShadow: "0px 0px 10px 0px var(--primary-shadow)",
        }),
      }}
      onClick={onClick}
      elevation={2}
    >
      <Stack
        direction="row"
        sx={{
          opacity: isInactive ? 0.5 : 1,
          gap: 2,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            maxWidth: 100,
            display: "flex",
            "@media (max-width:400px)": {
              display: "none",
            },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={imgUrl}
            alt={product.title}
            style={{
              objectFit: "contain",
              maxHeight: "100%",
              maxWidth: "100%",
            }}
          />
        </Box>

        <Typography
          sx={{
            color: "#212B36",
            fontSize: 16,
            fontWeight: 600,
            textAlign: "start",
          }}
          variant="h5"
        >
          {shortenString(product.title, 35)}
        </Typography>
        <Stack gap={2} sx={{ alignItems: "center", minWidth: 100 }}>
          <Chip
            label={getStatusString(status)}
            color={getStatusColor(status)}
            variant="outlined"
          />
          {!isInactive && (
            <Typography
              sx={{
                color: "#212B36",
                fontSize: 16,
                fontWeight: 600,
                textAlign: "center",
              }}
              variant="h5"
            >
              {balance || "0"}₪{" "}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default BenefitCard;
