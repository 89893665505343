import { ProductsActionTypes } from "./product.types";
import { axiosNoTokenInstance } from "../../axios.config";
import {
  attractionsAndExtremeCategoryId,
  fashionCategoryId,
  kidsAttractionsCategoryId,
  homeBabyCategoryId,
  restaurantsCategoryId,
  selfPickupCategoryId,
} from "../../../helpers/hardcodedIds";

export const getProducts = () => (dispatch) => {
  dispatch({ type: ProductsActionTypes.GET.GET_PRODUCT_PENDING });

  axiosNoTokenInstance
    .get(`/product/getAll`, {
      params: {
        relations: ["categories", "merchants", "prices"],
      },
    })
    .then((res) => {
      dispatch({
        type: ProductsActionTypes.GET.GET_PRODUCT_SUCCESS,
        payload: res.data.payload,
      });
    })
    .catch((error) =>
      dispatch({
        type: ProductsActionTypes.GET.GET_PRODUCT_ERROR,
        payload: error.response.data,
      })
    );
};

export const getProductsBySearch = (search) => (dispatch) => {
  dispatch({ type: ProductsActionTypes.GET.GET_SEARCH_PRODUCTS_PENDING });

  axiosNoTokenInstance
    .get("/product/getAll", {
      params: {
        relations: ["categories", "merchants"],
        search,
      },
    })
    .then((res) => {
      dispatch({
        type: ProductsActionTypes.GET.GET_SEARCH_PRODUCTS_SUCCESS,
        payload: res.data.payload,
      });
    })
    .catch((error) =>
      dispatch({
        type: ProductsActionTypes.GET.GET_SEARCH_PRODUCTS_ERROR,
        payload: error.response.data,
      })
    );
};

const getProductsByCategories =
  (
    categoryIds = [],
    typeName = "GET_PRODUCT",
    type = undefined,
    limit = 1,
    offset = 0
  ) =>
  (dispatch) => {
    dispatch({ type: ProductsActionTypes.GET[`${typeName}_PENDING`] });
    axiosNoTokenInstance
      .get("product/getProductsByCategory", {
        params: {
          categoryIds,
          relations: ["categories", "prices", "merchants"],
          type,
        },
      })
      .then((res) => {
        dispatch({
          type: ProductsActionTypes.GET[`${typeName}_SUCCESS`],
          payload: res.data.payload,
        });
      })
      .catch((error) =>
        dispatch({
          type: ProductsActionTypes.GET[`${typeName}_ERROR`],
          payload: error.response.data,
        })
      );
  };

export const getFashionOpenProducts = () =>
  getProductsByCategories(
    [fashionCategoryId], // ID for: אופנה
    "GET_FASHION_OPEN_PRODUCTS",
    "OPEN_PRICE"
  );

export const getRestaurantsOpenProducts = () =>
  getProductsByCategories(
    [restaurantsCategoryId],
    "GET_RESTAURANTS_OPEN_PRODUCTS",
    "OPEN_PRICE"
  );

export const getHomeBabyOpenProducts = () =>
  getProductsByCategories(
    [homeBabyCategoryId],
    "GET_HOME_BABY_OPEN_PRODUCTS",
    "OPEN_PRICE"
  );

export const getHotProducts = () =>
  getProductsByCategories([restaurantsCategoryId], "GET_HOT_PRODUCTS");

export const getAttractionProducts = () =>
  getProductsByCategories(
    [attractionsAndExtremeCategoryId, kidsAttractionsCategoryId],
    "GET_ATTRACTION_PRODUCTS"
  );
export const getSelfPickupProducts = () =>
  getProductsByCategories([selfPickupCategoryId], "GET_SELF_PICKUP_PRODUCTS");

export const getProductById = (productId) => (dispatch) => {
  dispatch({ type: ProductsActionTypes.GET.GET_PRODUCT_BY_ID_PENDING });

  axiosNoTokenInstance
    .get(`/product?id=${productId}`, {
      params: {
        relations: ["categories", "prices", "merchants"],
      },
    })
    .then((res) =>
      dispatch({
        type: ProductsActionTypes.GET.GET_PRODUCT_BY_ID_SUCCESS,
        payload: res.data.payload,
      })
    )
    .catch((error) =>
      dispatch({
        type: ProductsActionTypes.GET.GET_PRODUCT_BY_ID_ERROR,
        payload: error.response.data,
      })
    );
};
