import { PROJECT_NAME } from "../config";

const defaultValues = {
  marketsIds: [],
  couponsIds: [],
  fashionCategoryId: "",
  restaurantsCategoryId: "",
  attractionsAndExtremeCategoryId: "",
  kidsAttractionsCategoryId: "",
  selfPickupCategoryId: "",
  prioArray: [],
  oldPricesMap: {},
  excludedProducts: [],
  couponsOldPricesMap: {},
  marketsCards: [],
  telephoneNumber: "",
  mashbirPopupIds: [],
};

const mapProjectToIds = {
  benefits: {
    ...defaultValues,
    marketsIds: [3711, 3727, 3728], // 3364
    fashionCategoryId: "d657930b-c3c0-41b1-b0da-68febfd8fd7d",
    restaurantsCategoryId: "4fbfb2ef-9e08-4b10-80fa-30e667d6e847",
    homeBabyCategoryId: "5a31bd7b-3846-416f-ba05-fceab350eb4a",
    attractionsAndExtremeCategoryId: "f0dddb3e-70bd-473e-b7b3-efeebbd959a0",
    kidsAttractionsCategoryId: "b04c4de1-2630-40b7-8ecf-53d7d396aa81",
    selfPickupCategoryId: "aee81565-1a7e-49cc-9257-09fc3080e747",
    couponsIds: [
      "bba49d3b-6109-478c-bf62-735763f0000b",
      "ab97bf3e-c764-419a-ab56-f5c031cd3cd4",
      "2fe283bf-56a5-450f-827e-5602ec37efed",
      "afae8d78-c97a-4383-85c8-bfe070c743b1",
      "6c8943ab-0ecb-472d-bfe8-495c216d9b97",
      "c364a1ac-fe25-4af8-bdec-3d6493015707",
      "5c81eb56-cc48-4b57-8300-57c99ac0f52f",
    ],
    telephoneNumber: "0504394348",
    mashbirPopupIds: ["ec9600f5-bb11-4621-a787-a4d3d1350eb4"],
  },

  miluim: {
    ...defaultValues,
    marketsIds: [3378, 3377, 3364, 3365], // 3364, 3549
    fashionCategoryId: "59ccc88b-eb28-4b69-9b81-b5e2148832f8",
    restaurantsCategoryId: "5f42be3c-e48e-40bc-b2ad-73d4e770d981",
    homeBabyCategoryId: "fcab553a-f61f-4fb7-a81b-0187381f0607",
    attractionsAndExtremeCategoryId: "0ef17fcf-14a4-4750-8249-35ef06bcee7f",
    kidsAttractionsCategoryId: "a3bd8fbf-2366-4b48-9306-50396d4d6146",
    selfPickupCategoryId: "26356b97-f3fe-43bd-a0e5-dda1b3422fd4",
    couponsIds: [
      "fa4a8508-f0d9-4f5a-91c2-6dee5cff2131",
      "af15cbc5-e9eb-486e-8446-6035fd9267d5", //קפה קפה-בוקר זוגית
      "a178bf86-0140-4ded-83d5-54ba9b492637", //דרים קארד-300 ש"ח
      "57d00ac8-dbd1-4c9e-8ba5-efb6caebb11a", //עגבניה-2 פיצות
      "0805ef5c-e447-4df8-acb4-023b8217931d", //מוזס-150 ש"ח
      "4d59ca2a-b342-4420-b1f8-5db88834a4d6", //בי.בי.בי-150 ש"ח
      "d2497ee9-8c0d-41d0-91f9-563dd5fcaeda", //אצה-100 ש"ח
    ],
    telephoneNumber: "0504394348",
    mashbirPopupIds: ["ec9600f5-bb11-4621-a787-a4d3d1350eb4"],
  },

  mshuk: {
    ...defaultValues,
    fashionCategoryId: "b22c0ca7-ac48-4263-b732-3b98a1b725c2",
    restaurantsCategoryId: "42c3a273-7c20-4dff-9d08-38b3a87f8dcd",
    attractionsAndExtremeCategoryId: "6620b3d3-e7ab-43d8-9d0f-e5963f240139",
    homeBabyCategoryId: "1c2c2293-e042-4ebf-bbdc-3a2eb4c66330",
    kidsAttractionsCategoryId: "be4cdf8a-d572-427f-bd0c-f4aad93f28f8",
    selfPickupCategoryId: "a1473722-b31a-4183-a301-54c581e78a16",
    couponsIds: [
      "c855b71c-9380-4899-8eb0-c083bf5cfcb9",
      "b61e7b43-010f-4228-9817-fef4c5dc6620", //קפה קפה-בוקר זוגית
      // "a178bf86-0140-4ded-83d5-54ba9b492637", //דרים קארד-300 ש"ח
      "56595ed1-dca8-443d-b072-242876361e4b", //עגבניה-2 פיצות
      "2b8aec1f-5d24-48bd-bf18-49eabb8190c1", //מוזס-150 ש"ח
      "ca7ccf38-d1a1-48e2-bf85-98dea39c73db", //בי.בי.בי-150 ש"ח
      "6475e3c7-40a7-48cc-a7ff-3d905af2758e", //אצה-100 ש"ח
    ],
    telephoneNumber: "0504394348",
    chatNumber: "972504394348",
    matbeotText: "wincoins",
  },

  pele: {
    ...defaultValues,
    marketsIds: [2947, 2968, 2986, 2946, 2987], // 2946
    couponsIds: [
      "372d16cb-ad71-4b53-8c75-6ee6883c60cd",
      "ecf2c131-390b-447c-9bdb-285a3ca22369",
      "b641afc4-f3e9-475a-baf3-ee9300f8fb47",
      "d2807dcb-eed9-4a04-bb69-d27627a3de30",
      "a292f845-0b31-4b53-a8c0-36276196c194",
      "c4f656bc-d729-48ed-94d4-ba1d6d822aba",
      "f4172b3c-b4b4-4ae5-aa93-05cd6862231f",
      "649cba57-b5c7-4546-a3cc-cc2de6899634",
    ],
    // Old prices for products
    oldPricesMap: {
      "b641afc4-f3e9-475a-baf3-ee9300f8fb47": 78,
      "228bb399-1732-4c24-a74e-64e62f8d9aca": 122,
      "87d36c1c-66dc-4ed3-834b-8393be3e8035": 55,
      "440df775-4228-4856-a2fc-0d3ac71577e8": 119,
      "a292f845-0b31-4b53-a8c0-36276196c194": 87,
      "ea5934ac-14f5-425e-86af-caab3abc869b": 174,
      "3b3fcc4c-46f4-4bca-829c-42346f417c3d": 25,
      "5242372e-bf90-4eaa-9f15-71c41b6997be": 46,
      "ecf2c131-390b-447c-9bdb-285a3ca22369": 139,
      "b324417b-a92d-4987-a057-d985853a84bf": 130,
      "d2807dcb-eed9-4a04-bb69-d27627a3de30": 100,
    },
    couponsOldPricesMap: {
      "ecf2c131-390b-447c-9bdb-285a3ca22369": 139,
      "b641afc4-f3e9-475a-baf3-ee9300f8fb47": 78,
      "d2807dcb-eed9-4a04-bb69-d27627a3de30": 0,
      "a292f845-0b31-4b53-a8c0-36276196c194": 0,
    },
    excludedProducts: [
      "277ec0eb-847a-47aa-b2ba-0a5cdb39acf2",
      "3eb22011-5697-484a-8966-25bda192eaba",
      "77cbba10-60e1-47f5-b2fc-a8488707bbae",
    ],
    marketsCards: [
      "b12baf8b-f118-4557-b2d0-dfbc20949d41",
      "25f74031-cdd3-4e6a-a57f-726a184b11bd",
      "16209c1a-97f0-4684-aa0c-a8f06d61cbad",
    ],
    fashionCategoryId: "56ba72c9-c130-4fce-b7d1-e6059c4d29fb",
    restaurantsCategoryId: "88392ca0-1b73-4da5-9fef-fb2f4c21590c",
    homeBabyCategoryId: "a575d69d-3e26-4dd1-a084-6472eb037583",
    attractionsAndExtremeCategoryId: "f394a336-2715-4351-b63b-8eb05398b9cf",
    kidsAttractionsCategoryId: "05b6b767-1d06-4f99-8c66-6f1967edde67",
    selfPickupCategoryId: "c036aff9-8764-465c-9efb-daf0f6839089",
    prioArray: [
      "קיפצובה",
      "מיני ישראל",
      "שייט קייקים",
      "שייט אתגרי",
      "קרסו למדע",
      "פארק ג'ננה",
      "פעלטון",
      "פאנקי מאנקי",
    ],
    telephoneNumber: "0504394348",
    chatNumber: "972504394348",
    mashbirPopupIds: ["336e13f5-edc4-4166-be8f-829a6a2f9569"],
    matbeotText: "מטבע פלא",
  },
};

function getCurrentProjectData() {
  return mapProjectToIds[PROJECT_NAME];
}

const {
  marketsIds,
  couponsIds,
  fashionCategoryId,
  homeBabyCategoryId,
  restaurantsCategoryId,
  attractionsAndExtremeCategoryId,
  kidsAttractionsCategoryId,
  selfPickupCategoryId,
  prioArray,
  oldPricesMap,
  excludedProducts,
  couponsOldPricesMap,
  marketsCards,
  telephoneNumber,
  mashbirPopupIds,
  matbeotText,
  chatNumber,
} = getCurrentProjectData();

export {
  marketsIds,
  couponsIds,
  fashionCategoryId,
  homeBabyCategoryId,
  restaurantsCategoryId,
  attractionsAndExtremeCategoryId,
  kidsAttractionsCategoryId,
  selfPickupCategoryId,
  prioArray,
  oldPricesMap,
  excludedProducts,
  couponsOldPricesMap,
  marketsCards,
  telephoneNumber,
  mashbirPopupIds,
  matbeotText,
  chatNumber,
};

export const confirmMashbirDialogText = {
  text1: "נא קחו לתשומת לבכם!",
  text2: "כולל כפל מבצעים",
  text3: "לא תקף בהנחות מועדון, מוצרי חשמל, אופטיקה ותכשיטי",
};

export const confirmRestaurantDialogText = {
  text1: "נא קחו לתשומת לבכם!",
  text2:
    "לפני ביקור במסעדה/בית קפה יש לבדוק את רמת הכשרות בטלפון או בביקור באתר",
};

export const confirmGiftDialog = {
  text1: "מזל טוב!",
  text2: "קיבלת מתנה ב-50 שקלים, כנסו לשוברים כדי לראות",
  buttonText: "לשוברים",
};

export const ERROR_STATUSES = [
  "CREDIT_CARD_NOT_VALID",
  "FAILED_CARD_CREATION",
  "TRANSACTION_REFUSED",
  "UNPAID",
];

export const ERROR_STATUSES_MESSAGES = {
  CREDIT_CARD_NOT_VALID: "עסקה סורבה. סוג כרטיס אינו נתמך.",
  FAILED_CARD_CREATION:
    "עסקה אושרה אך זוהתה שגיאה בעת הנפקת השובר, יש לפנות למוקד להמשך טיפול.",
  TRANSACTION_REFUSED: "עסקה סורבה. יש לפנות לחברת האשראי.",
  UNPAID: "זמן לתשלום עבר, נא לנסות שוב פעם.",
  default: "יש בעיה לא מזוהה, נא לפנות למוקד.",
};
