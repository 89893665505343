import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Header from "../header/Header";
import {
  addCard,
  fastCheckout,
  openSidebar,
} from "../../redux/actions-exporter";
import "../cart/checkout.css";
import { debounce } from "@mui/material";
import LoaderPrimary from "../UI/LoaderPrimary";

function Checkout() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const [card, setCard] = useState({});
  const [load, setLoad] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showaddress, setShowAddress] = useState(false);
  const [showcard, setShowCard] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const user = useSelector((state) => state.user.user);

  const handleMonthChange = (e) => {
    let month = e.target.value;

    if (month === "") {
      setSelectedMonth("");
      setCard({ ...card, expirationDate: "" + selectedYear });
      return;
    }

    month = parseInt(month, 10);
    let isValid = month >= 1 && month <= 12;
    if (!isValid) {
      return;
    }

    month = month.toString();
    if (month.length === 1) {
      month = "0" + month;
    }

    setSelectedMonth(month);
    setCard({ ...card, expirationDate: month + selectedYear });
  };

  const handleYearChange = (e) => {
    let year = e.target.value;
    year = year.toString();
    if (year.length > 2) {
      year = year.slice(-2);
    }
    setSelectedYear(year);
    setCard({ ...card, expirationDate: selectedMonth + year });
  };

  const onCheckout = () => {
    setButtonDisabled(true);
    setCard({});
    setLoad(true);
    dispatch(
      fastCheckout(
        {
          ...location?.state,
        },
        (data) => {
          setLoad(false);
          const { orderId, paymentURL } = data;
          navigate("/pay", {
            state: { paymentURL, orderId },
          });
        }
      )
    );
  };

  useEffect(() => {
    if (!user) {
      dispatch(openSidebar());
      navigate("/");
    }
  }, [dispatch, navigate, user]);

  return (
    <div>
      <Header />
      <div className="pageTemplate">
        <div className="container">
          {!showaddress && !showcard ? (
            <div>
              <div className="templateHeading">
                <span className="backIcon isLink">
                  <ArrowBackIcon
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </span>
                <h1 className="templateTitle">{t("checkout")}</h1>
              </div>
              {load && (
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <typography style={{ marginBottom: "15px" }} variant="body1">
                    רק רגע...
                  </typography>
                  <LoaderPrimary />
                </div>
              )}
              <div className="checkoutFooterBtn">
                <Button
                  variant="outlined"
                  onClick={debounce(onCheckout, 100)}
                  disabled={buttonDisabled}
                >
                  {t("payNow")}
                </Button>
              </div>
            </div>
          ) : (
            <>
              {showaddress ? (
                <div>
                  <div className="templateHeading">
                    <span className="backIcon">
                      <ArrowBackIcon onClick={() => setShowAddress(false)} />
                    </span>
                    <h1 className="templateTitle"> {t("newAddress")}</h1>
                  </div>
                  <div className="checkoutBlocks">
                    <h5 className="checkoutBlocks-title text-center">
                      {t("deliveryAddress")}
                    </h5>
                    <div className="row">
                      <div className="col-lg-6">
                        <TextField
                          id="outlined-basic"
                          label={t("country")}
                          variant="outlined"
                        />
                      </div>
                      <div className="col-lg-6">
                        <TextField
                          id="outlined-basic"
                          label={t("city")}
                          variant="outlined"
                        />
                      </div>
                      <div className="col-lg-6">
                        <TextField
                          id="outlined-basic"
                          label={t("zipcode")}
                          variant="outlined"
                        />
                      </div>
                      <div className="col-lg-6">
                        <TextField
                          id="outlined-basic"
                          label={t("street")}
                          variant="outlined"
                        />
                      </div>
                      <div className="col-lg-6">
                        <TextField
                          id="outlined-basic"
                          label={t("appartment")}
                          variant="outlined"
                        />
                      </div>
                      <div className="col-lg-6">
                        <TextField
                          id="outlined-basic"
                          label={t("phone")}
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="checkoutFooterBtn">
                    <Button
                      variant="outlined"
                      onClick={() => setShowAddress(false)}
                    >
                      {t("save")}
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="templateHeading">
                    <span className="backIcon">
                      <ArrowBackIcon onClick={() => setShowCard(false)} />
                    </span>
                    <h1 className="templateTitle">{t("addNewCard")}</h1>
                  </div>
                  <div className="checkoutBlocks">
                    <h5 className="checkoutBlocks-title text-center">
                      {t("paymentMethod")}
                    </h5>
                    <div className="row" dir="rtl">
                      <div className="col-lg-12">
                        <div className="cardTextNo">
                          <TextField
                            id="outlined-basic"
                            label={t("cardNumber")}
                            variant="outlined"
                            autoComplete="off"
                            onChange={(e) =>
                              setCard({
                                ...card,
                                number: e.currentTarget.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-6">
                            <TextField
                              id="month-select"
                              label={t("Expiry Month")}
                              value={selectedMonth}
                              onChange={handleMonthChange}
                              variant="outlined"
                              autoComplete="off"
                              fullWidth
                            ></TextField>
                            <TextField
                              id="outlined-basic"
                              label={t("CVC/CVV")}
                              variant="outlined"
                              autoComplete="off"
                              onChange={(e) =>
                                setCard({
                                  ...card,
                                  cvv2: e.currentTarget.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-6">
                            <TextField
                              id="year-select"
                              label={t("Expiry Year")}
                              value={selectedYear}
                              onChange={handleYearChange}
                              variant="outlined"
                              autoComplete="off"
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <TextField
                          id="outlined-basic"
                          label={t("cardName")}
                          variant="outlined"
                          autoComplete="off"
                          onChange={(e) =>
                            setCard({
                              ...card,
                              name: e.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-lg-12">
                        <TextField
                          id="outlined-basic"
                          label={t("id")}
                          variant="outlined"
                          autoComplete="off"
                          onChange={(e) =>
                            setCard({
                              ...card,
                              idCard: e.currentTarget.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="checkoutFooterBtn">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setShowCard(false);
                          setCard({});
                          dispatch(addCard(card));
                        }}
                      >
                        {t("save")}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Checkout;
