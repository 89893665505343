import { ProductCategoryActionsTypes } from "./productCategories.types";
import { axiosNoTokenInstance } from "../../axios.config";

export const getProductCategories = () => (dispatch) => {
  dispatch({
    type: ProductCategoryActionsTypes.GET.GET_PRODUCT_CATEGORY_PENDING,
  });

  axiosNoTokenInstance
    .get(`/product-category/getAll`)
    .then((res) => {
      dispatch({
        type: ProductCategoryActionsTypes.GET.GET_PRODUCT_CATEGORY_SUCCESS,
        payload: res.data.payload,
      });
    })
    .catch((error) => {
      dispatch({
        type: ProductCategoryActionsTypes.GET.GET_PRODUCT_CATEGORY_ERROR,
        payload: error.response.data,
      });
    });
};

export const getProductCategory = (categoryId) => (dispatch) => {
  dispatch({
    type: ProductCategoryActionsTypes.GET.GET_PRODUCT_CATEGORY_BY_ID_PENDING,
  });

  axiosNoTokenInstance
    .get(`/product-category?id=${categoryId}`)
    .then((res) =>
      dispatch({
        type: ProductCategoryActionsTypes.GET
          .GET_PRODUCT_CATEGORY_BY_ID_SUCCESS,
        payload: res.data.payload,
      })
    )
    .catch((error) =>
      dispatch({
        type: ProductCategoryActionsTypes.GET.GET_PRODUCT_CATEGORY_BY_ID_ERROR,
        payload: error.response.data,
      })
    );
};

export const getProductsByCategoryId = (categoryId) => (dispatch) => {
  dispatch({
    type: ProductCategoryActionsTypes.GET.GET_PRODUCTS_BY_CATEGORY_ID_PENDING,
  });

  axiosNoTokenInstance
    // .get(
    //   `product/getProductsByCategory?categoryIds=${categoryId}&relations=categories`
    // )
    .get(`product-category/`, {
      params: {
        relations: ["products"],
        id: categoryId,
      },
    })
    .then((res) => {
      dispatch({
        type: ProductCategoryActionsTypes.GET
          .GET_PRODUCTS_BY_CATEGORY_ID_SUCCESS,
        payload: res.data.payload.products,
      });
    })
    .catch((error) =>
      dispatch({
        type: ProductCategoryActionsTypes.GET.GET_PRODUCTS_BY_CATEGORY_ID_ERROR,
        payload: error.response.data,
      })
    );
};
