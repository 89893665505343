import { useNavigate } from "react-router-dom";
import Header from "../header/Header";

export default function BenefitsTypeChoice() {
  const navigate = useNavigate();
  const handleClickMyCoupons = () => {
    navigate("/mycoupons");
  };

  const handleClickBenefitsCoupons = () => {
    navigate("/benefitscoupons");
  };

  return (
    <div>
      <Header isFull={true} />
      <div className="walletTamplate">
        <div className="container">
          <div className="walletIcons">
            <div
              className="walletIconsBox isLink"
              onClick={handleClickMyCoupons}
            >
              <h6 className="walletIconsBox-title">
                השוברים
                <br /> שלי
              </h6>
            </div>
            <div
              className="walletIconsBox isLink"
              onClick={handleClickBenefitsCoupons}
              style={{
                boxShadow: "0 0 20px 0 var(--primary-shadow)",
              }}
            >
              <h6 className="walletIconsBox-title">
                שוברי <br /> Benefits
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
