import React from "react";
import { useTranslation } from "react-i18next";

import Header from "../header/Header";

import a from "../../assets/images/categories/a-min.jpg";
import h from "../../assets/images/categories/h-min.jpg";
import { useSelector } from "react-redux";
import ProductCard from "../product/ProductCard";
import { prioArray } from "../../helpers/hardcodedIds";

export default function Products({ type }) {
  const { t } = useTranslation();

  const items = useSelector(
    (state) =>
      type === "hot"
        ? state.product.hotProducts
            .filter(
              (product) =>
                !product.title.includes("אווז") &&
                !product.title.includes("הנחה")
            )
            .map((item) => ({ ...item, price: item?.prices?.[0]?.value }))
        : state.product.attractionProducts
            .filter(
              (product) =>
                !product.title.includes("אווז") &&
                !product.title.includes("הנחה")
            )
            .sort((item) =>
              prioArray.some((substring) => item.title.includes(substring))
                ? -1
                : 0
            )
            .map((item) => ({ ...item, price: item?.prices?.[0]?.value })) // TODO: Do not leave it like this!
  );
  const title = type === "hot" ? "הטבות חמות" : "אטרקציות";

  return (
    <div>
      <Header />
      <div className="pageTemplate">
        <div className="container">
          <div
            className="PageBgHeading"
            style={{
              backgroundImage: `url(${type === "hot" ? h : a})`,
            }}
          >
            <h5 className="PageBgHeading-title">{title}</h5>
          </div>

          <ul className="product-Module">
            {items?.length > 0
              ? items?.map((product) => (
                  <ProductCard key={product.id} product={product} />
                ))
              : t("No products")}
          </ul>
        </div>
      </div>
    </div>
  );
}
