import { Typography } from "@mui/material";

export default function EmptyResults({ text = "לא נמצאו תוצאות" }) {
  return (
    <Typography
      variant="p"
      sx={{ fontSize: 16, color: "#637381", textAlign: "start" }}
    >
      {text}
    </Typography>
  );
}
