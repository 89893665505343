import { Link } from "react-router-dom";
import logoPele from "../../assets/logo/pele/logo.svg";
import logoMiluim from "../../assets/logo/miluim/logoMiluim.svg";
import logoBenefits from "../../assets/logo/benefits/logoBenefits_white_h50.png";
import logoSquarePele from "../../assets/logo/pele/logo_square_Pele.png";
import logoSquareBenefits from "../../assets/logo/benefits/logo_square_benefits.png";
import logoMshuk from "../../assets/logo/mshuk/win_h50.png";
import { PROJECT_NAME } from "../../config";
import styles from "./Logo.module.css";

const type = PROJECT_NAME;

const LogoMiluim = () => (
  <div className={styles.logoMiluimContainer}>
    <div>
      <img
        alt="Miluim Logo"
        src={logoMiluim}
        className={styles.logoMiluimImage}
      />
    </div>
    <span className={styles.logoMiluimText}>המילואימניקים</span>
  </div>
);

const LogoBenefits = () => <img alt="Benefits Logo" src={logoBenefits} />;

const LogoPele = () => <img alt="Pele Logo" src={logoPele} />;

const LogoMshuk = () => <img alt="Wincoins Logo" src={logoMshuk} />;

const logoComponents = {
  miluim: LogoMiluim,
  pele: LogoPele,
  default: LogoPele,
  benefits: LogoBenefits,
  mshuk: LogoMshuk,
};

const LogoSquareMiluim = () => (
  <img alt="Miluim Logo" src={logoMiluim} width="85px" height="85px" />
);
const LogoSquarePele = () => <img alt="Pele Logo" src={logoSquarePele} />;
const LogoSquareBenefits = () => (
  <img alt="Benefits Logo" src={logoSquareBenefits} />
);

const logoSquareComponents = {
  miluim: LogoSquareMiluim,
  pele: LogoSquarePele,
  default: LogoSquarePele,
  benefits: LogoSquareBenefits,
};

export default function Logo({ square = false }) {
  const LogoComponent = logoComponents[type] || logoComponents.default;
  const LogoSquareComponent =
    logoSquareComponents[type] || logoSquareComponents.default;

  if (square) {
    return <LogoSquareComponent />;
  }

  return (
    <Link className="logo" to="/">
      <LogoComponent />
    </Link>
  );
}
