import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Drawer from "@mui/material/Drawer";
import MicIcon from "@mui/icons-material/Mic";
import MenuIcon from "@mui/icons-material/Menu";
import Skeleton from "@mui/material/Skeleton";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import { GiTwoCoins } from "react-icons/gi";

import {
  getMerchantsBySearch,
  getPointsBalance,
  getProductsBySearch,
  openSidebar,
} from "../../redux/actions-exporter";

import SearchResult from "../searchResult/SearchResult";
import Logo from "../logo/Logo";

import clip from "../../assets/images/clip.mp4";
import logo2 from "../../assets/images/user3.jpg";

import { MATBEOT_ENABLED, PROJECT_NAME } from "../../config";

import "./header.css";
import { matbeotText } from "../../helpers/hardcodedIds";
import InfoDrawer from "./InfoDrawer";

const Header = ({ reduce = 0 }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const notification = false;
  const [video, setVideo] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const user = useSelector((state) => state.user.user);
  const points = useSelector((state) => state.user.points);
  const isPointsPending = useSelector((state) => state.user.isPointsPending);
  const merchants = useSelector((state) => state.merchant.merchantsBySearch);
  const products = useSelector((state) => state.product.searchProducts);

  useEffect(() => {
    if (searchText) dispatch(getProductsBySearch(searchText));
    if (searchText) dispatch(getMerchantsBySearch(searchText));
  }, [dispatch, searchText]);

  useEffect(() => {
    if (user?.id && MATBEOT_ENABLED) dispatch(getPointsBalance());
  }, [user, dispatch]);

  const openSearch = () => {
    setIsSearchOpen(true);
  };
  const openInfo = () => {
    setIsInfoOpen(true);
  };

  return (
    <div className="header">
      <div className="top">
        <div className="menuIcon" onClick={() => dispatch(openSidebar())}>
          <MenuIcon fontSize="large" sx={{ color: "var(--header-text)" }} />
        </div>

        <div
          style={{
            color: "var(--header-text)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            lineHeight: "16px",
            cursor: "pointer",
          }}
          onClick={() => dispatch(openSidebar())}
        >
          <div>שלום,</div>
          <div>
            {user?.id ? (
              user?.firstName
            ) : (
              <span style={{ color: "var(--error)" }}>התחבר/י</span>
            )}
          </div>
        </div>
        <Logo />

        <div className="menuIcon" onClick={openSearch}>
          <SearchOutlinedIcon
            fontSize="large"
            sx={{ color: "var(--header-text)" }}
          />
        </div>
      </div>
      {location.pathname !== "/pay" ? (
        <div className="bottom">
          {MATBEOT_ENABLED && (
            <div className="par dark" style={{ marginLeft: 0 }}>
              <div className="balanceH dark">{matbeotText}</div>
              <div className="balanceAmount dark">
                {/* <bdi>{user?.credit?.toFixed(2) || "0.00"}</bdi> */}
                {isPointsPending ? (
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "1rem" }}
                    width={30}
                  />
                ) : (
                  <>
                    <bdi>{((points || 0) - reduce).toFixed(2)}</bdi>
                    <GiTwoCoins sx={{ color: "var(--primary)" }} />
                  </>
                )}
              </div>
            </div>
          )}

          <div className="collect" onClick={openInfo}>
            <span>איך זה עובד</span>
            <GiTwoCoins fontSize="large" sx={{ color: "var(--header-text)" }} />
          </div>

          {/* <InstallPWA icon={true} /> */}

          {/* <div className="notificationBlock">
          <Button className="dropBtn">
            <VideocamIcon onClick={() => setVideo(true)} />
          </Button>
        </div> */}
          {notification && (
            <div className="notificationList">
              <div className="chatMainBox">
                <div className="chatMainBox__inner">
                  <div className="chatMainBox__img">
                    <img
                      // src={user}
                      src={logo2}
                      alt=""
                      height={50}
                      width={50}
                      className="img-fluid"
                    />
                  </div>
                  <div className="chatMainBox__info">
                    <h4 className="chatMainBox__name">מועדון ווינקרד</h4>
                    <p className="chatMainBox__text">שמחים לראותך כאן איתנו!</p>
                  </div>
                </div>
                <span className="chatMainBox__time">4pm</span>
              </div>
            </div>
          )}

          <Drawer
            PaperProps={{
              sx: {
                width: "80%",
                marginTop: "72px",
                maxWidth: "400px",
                position: "absolute",
              },
            }}
            open={isSearchOpen}
            onClose={() => setIsSearchOpen(false)}
          >
            <form className="search-container active-search">
              <div className="search-container__btn">
                <SearchOutlinedIcon />
              </div>
              <input
                type="text"
                id="search-bar"
                placeholder="חיפוש"
                className="search-container__input"
                onChange={(e) => setSearchText(e.target.value)}
                // onClick={openSearch}
                value={searchText}
              />
              <div className="mic-container__btn">
                <MicIcon />
              </div>
            </form>
            <SearchResult filterProducts={products} filterStores={merchants} />
          </Drawer>
        </div>
      ) : (
        <div style={{ height: 100 }}></div>
      )}

      <InfoDrawer
        open={isInfoOpen}
        handleClose={() => setIsInfoOpen(false)}
        projectName={PROJECT_NAME}
      />
      <Drawer
        PaperProps={{
          sx: {
            width: "96%",
            maxWidth: "420px",
            // left: "calc(50vw - 175px)",
            left: { xs: "2%", sm: "calc(50vw - 210px)" },
            top: "25%",
            height: "50%",
            margin: "auto",
            borderRadius: "10px",
            padding: "5px",
            display: "flex",
            justifyContent: "center",
          },
        }}
        open={video}
        onClose={() => setVideo(false)}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: "5px",
            left: "5px",
            p: 0,
          }}
          onClick={() => setVideo(false)}
        >
          <CloseIcon />
        </IconButton>
        <video src={clip} width={"100%"} autoPlay />
      </Drawer>
    </div>
  );
};

export default Header;
