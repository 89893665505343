import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { clearOrder, getOrder, getUser } from "../../../redux/actions-exporter";
import Header from "../../header/Header";

import "./styles.css";
import LoaderPrimary from "../../UI/LoaderPrimary";
import { PROJECT_NAME } from "../../../config";
import { formatPrice } from "../../../helpers/formatPrice";

const Card = ({ card }) => {
  return (
    <div className="pelecard-page__card">
      <h5 className="paymentApproveLine1">{card.cardNumber || ""}</h5>

      {card.pinCode && <h5>{card.pinCode}</h5>}
    </div>
  );
};

const Index = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [requestSent, setRequestSend] = useState(0);
  const order = useSelector((state) => state.order.order);
  const user = useSelector((state) => state.user.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [interval, setInterval] = useState(null);
  const cardsArray = order?.orderItems
    ? order.orderItems.map((orderItem) => orderItem.cards).flat()
    : [];
  const isPele = PROJECT_NAME === "pele";
  const project = PROJECT_NAME;
  const accountSum =
    order?.orderItems?.reduce((acc, curr) => {
      const basePrice = curr.product.prices?.find(
        (price) => price.type === "base_price"
      );
      return (
        acc +
        (curr.amount ? curr.amount : basePrice?.value || 0) *
          (curr.quantity || 1)
      );
    }, 0) ?? 0;

  useEffect(() => {
    if (!user) {
      dispatch(getUser());
    }
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(getOrder(orderId));

    return () => {
      dispatch(clearOrder());
    };
  }, [orderId, dispatch]);

  useEffect(() => {
    if (order.id && !cardsArray.length && requestSent < 10) {
      const intervalVal = setTimeout(() => {
        setInterval(intervalVal);
        setRequestSend((prev) => prev + 1);
        dispatch(getOrder(orderId));
      }, 5000);
    }
  }, [cardsArray.length, dispatch, order, orderId, requestSent]);

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, [interval]);

  return (
    <div className="pageTemplate">
      <Header isFull={false} />
      <div className="container">
        <hr />
        <div className="templateHeading">
          <h1 className="templateTitle">{t("payment approve")}</h1>
        </div>
        <hr />
        <div className="paymentApproveContent">
          {order?.total && user && (
            <div className="paymentApproveText">
              {/* TODO: make more universal solution for various possible projects */}
              {project === "pele" && <h4>מועדון אקסטרא - פלא</h4>}
              {project === "miluim" && <h4>מועדון אקסטרא - מילואימניקים</h4>}
              {project === "benefits" && <h4>מועדון אקסטרא - בנפיטס</h4>}
              {project === "wincoins" && <h4>מועדון אקסטרא - ווינקוינס</h4>}
              {formatPrice(accountSum) > 0 && (
                <h6 className="pelecard-page__info">
                  סכום החשבון: {formatPrice(accountSum)} ₪
                </h6>
              )}
              <h5 className="pelecard-page__title">
                שילמת בפועל: ₪{order?.discountTotal || order?.total || 0}
                {/* תשלום בסך {order?.total * order?.metadata?.discount || 0} ₪ בוצע */}
              </h5>
              <br />
              <h5>קוד להצגה בקופה:</h5>
              <br />
              <div className="pelecard-page__cardsList">
                {!!cardsArray.length && (
                  <>
                    {cardsArray.map((card) => (
                      <Card card={card} key={card.id} />
                    ))}
                    {accountSum - (order?.total || 0) > 0 && (
                      <h5 className="pelecard-page__info">
                        חסכת {formatPrice(accountSum - order?.total)} ₪
                      </h5>
                    )}
                  </>
                )}
                {!cardsArray.length && requestSent < 10 && <LoaderPrimary />}
                {!cardsArray.length && requestSent >= 10 && (
                  <p>הכרטיסים יהיו בארנק שלכם בעוד כמה דקות, תודה.</p>
                )}
              </div>
            </div>
          )}
          {!order?.total && <LoaderPrimary />}

          <div className="loadMoneyCard-btn">
            <Button
              className="blueBtn"
              onClick={() => {
                navigate("/");
              }}
            >
              סיימתי
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
