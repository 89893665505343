import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import { CircularProgress } from "@mui/material";
import Header from "../header/Header";
import {
  getFashionOpenProducts,
  getHomeBabyOpenProducts,
  openSidebar,
  getRestaurantsOpenProducts,
} from "../../redux/actions-exporter";

import "../vendor/vendorsDetails.css";
import VendorCard from "./VendorCard";
import LoaderPrimary from "../UI/LoaderPrimary";
import EmptyResults from "../emptyResults/EmptyResults";
import {
  confirmMashbirDialogText,
  mashbirPopupIds,
} from "../../helpers/hardcodedIds";
import ConfirmDialog from "../wallets/ConfirmDialog";
import ProductInfoDialog from "./ProductInfoDialog";

export default function AllVendor() {
  const { type } = useParams();
  const [confirmDialog, setConfirmDialog] = useState("");
  const [productInfoDialog, setProductInfoDialog] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.user);
  const isStoresPending = useSelector((state) =>
    type === "restaurants"
      ? state.product.isRestaurantsOpenProductsPending
      : type === "fashion"
      ? state.product.isFashionOpenProductsPending
      : state.product.isHomeBabyOpenProductsPending
  );
  const stores = useSelector((state) =>
    type === "restaurants"
      ? state.product.restaurantsOpenProducts
      : type === "fashion"
      ? state.product.fashionOpenProducts
      : state.product.homeBabyOpenProducts
  );

  const title =
    type === "restaurants"
      ? "כל המסעדות"
      : type === "fashion"
      ? "רשתות אופנה"
      : "מוצרים לבית וליולדת";

  const onClick = (item) => {
    console.log(item);
    setProductInfoDialog(item);
  };

  const handleProceedToPayment = (id) => {
    if (!user) {
      dispatch(openSidebar());
    } else if (mashbirPopupIds.includes(id)) {
      setProductInfoDialog(null);
      setConfirmDialog(id);
    } else {
      navigate(`/businesspayment/${id}`);
    }
  };

  useEffect(() => {
    if (type !== "restaurants" && type !== "fashion" && type !== "home-baby") {
      navigate("/");
    } else if (!stores.length) {
      dispatch(
        type === "restaurants"
          ? getRestaurantsOpenProducts()
          : type === "fashion"
          ? getFashionOpenProducts()
          : getHomeBabyOpenProducts()
      );
    }
  }, [dispatch, navigate, stores.length, type]);

  return (
    <div>
      <Header isWallet={true} />

      <div className="pageTemplate">
        <div className="container">
          <div className="vendorAllListBlock">
            <h6 className="module-heading__title">{title}</h6>
            <ul className="categoryList">
              {isStoresPending ? (
                <LoaderPrimary />
              ) : stores.length > 0 ? (
                stores.map((store) => (
                  <VendorCard item={store} onClick={onClick} key={store.id} />
                ))
              ) : (
                <EmptyResults />
              )}
            </ul>
            <ConfirmDialog
              open={!!confirmDialog}
              handleClose={() => setConfirmDialog("")}
              handleConfirm={() =>
                navigate(`/businesspayment/${confirmDialog}`)
              }
              text1={confirmMashbirDialogText.text1}
              text2={confirmMashbirDialogText.text2}
              text3={confirmMashbirDialogText.text3}
            />
            <ProductInfoDialog
              open={!!productInfoDialog}
              handleClose={() => setProductInfoDialog(null)}
              handleConfirm={() => handleProceedToPayment(productInfoDialog.id)}
              product={productInfoDialog}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
