import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../header/Header";

import perfume from "../../assets/images/categories/perfume-min.jpg";
import All from "../../assets/images/categories/All-min.jpg";
import art from "../../assets/images/art.jpg";
import recommended from "../../assets/images/categories/recommended-min.jpg";
import general from "../../assets/images/categories/general-min.jpg";
import Bags from "../../assets/images/categories/Bags-min.jpg";
import home from "../../assets/images/categories/home-min.jpg";
import Kosherrestaurants from "../../assets/images/categories/Kosherrestaurants-min.jpg";
import Careandbeauty from "../../assets/images/categories/Careandbeauty-min.jpg";
import PRODUCTS from "../../assets/images/categories/PRODUCTS-min.jpg";
import Fashion from "../../assets/images/categories/Fashion-min.jpg";
import Fashionchains from "../../assets/images/categories/Fashionchains-min.jpg";
import Marketingnetworks from "../../assets/images/categories/Marketingnetworks-min.jpg";
import Householdkitchenwarechains from "../../assets/images/categories/Householdkitchenwarechains-min.jpg";
import Meatrestaurants from "../../assets/images/categories/Meatrestaurants-min.jpg";
import Dairyrestaurants from "../../assets/images/categories/Dairyrestaurants-min.jpg";
import Asianrestaurants from "../../assets/images/categories/Asianrestaurants-min.jpg";
import Coffeeshops from "../../assets/images/categories/Coffeeshops-min.jpg";
import Pickupproducts from "../../assets/images/categories/Pickupproducts-min.jpg";
import Textilesandhouseholditems from "../../assets/images/categories/Textilesandhouseholditems-min.jpg";
import Veganrestaurants from "../../assets/images/categories/Veganrestaurants-min.jpg";
import boutique from "../../assets/images/categories/boutique-min.jpg";
import tours from "../../assets/images/categories/tours-min.jpg";

const imgs = [
  recommended,
  perfume,
  home,
  general,
  All,
  Bags,
  Kosherrestaurants,
  Careandbeauty,
  PRODUCTS,
  Fashion,
  Fashionchains,
  Marketingnetworks,
  Householdkitchenwarechains,
  Meatrestaurants,
  Dairyrestaurants,
  Asianrestaurants,
  Coffeeshops,
  Pickupproducts,
  Textilesandhouseholditems,
  Veganrestaurants,
  boutique,
  tours,
  Fashion,
  Fashionchains,
  Marketingnetworks,
  Householdkitchenwarechains,
  Meatrestaurants,
  Dairyrestaurants,
  Asianrestaurants,
  Coffeeshops,
  Pickupproducts,
  Textilesandhouseholditems,
  Veganrestaurants,
  boutique,
  tours,
];

export default function AllCategories() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { title } = location?.state;
  const { items } = location?.state;
  return (
    <div>
      <Header />
      <div className="pageTemplate">
        <div className="container">
          <div
            className="PageBgHeading"
            style={{
              backgroundImage: `url(${art})`,
            }}
          >
            <h5 className="PageBgHeading-title">{title}</h5>
          </div>
          <ul className="product-Module allCat">
            {items?.length > 0
              ? items?.map((item, i) => {
                  return (
                    <>
                      <li
                        onClick={() => {
                          navigate(`/category/${item.id}`);
                        }}
                        className="hotBenefit isLink"
                      >
                        <div
                          className="hotBenefitImg"
                          style={
                            {
                              // background: `#${Math.floor(Math.random() * 16777215).toString(16)
                              //   }`
                            }
                          }
                        >
                          <img
                            src={
                              `${process.env.REACT_APP_API_URL}/upload${item.mainImageURL}` ||
                              imgs[i]
                            }
                            alt="My Awesome"
                          />
                        </div>
                        <div className="hotBenefitContent">
                          <div className="old">
                            <h6 className="hotBenefitNew">{item.title}</h6>
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })
              : t("No products")}
          </ul>
        </div>
      </div>
    </div>
  );
}
