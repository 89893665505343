import * as React from "react";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import "./rules.css";
import { Container } from "@mui/material";

const theme = createTheme({
  direction: "rtl",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function Contact() {
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <h5
            style={{
              textAlign: "center",
              marginTop: "25px",
            }}
          >
            <b>יצירת קשר</b>
          </h5>
          <div className="rules">
            בכל שאלה ניתן לפנות למשרדי אלעד מועדוני צרכנות בע"מ על פי פרטי
            ההתקשרות הבאים:
            <br />
            <br />
            מייל:
            <br />
            <a href="mailto:office@benefits.co.il">office@benefits.co.il</a>
            <br />
            <br />
            כתובת:
            <br />
            מצדה 9, בסר 3, ר"ג
            <br />
            <br />
            מענה טלפוני/צ'אט, דרך האפליקציה:
            <br />
            ימים א'-ה' 09:00-16:00
            <br />
            <br />
            קנייה מהנה.
          </div>
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
}
