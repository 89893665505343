import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

const GrayIcon = ({ img, link, title, order }) => {
  let location = useLocation().pathname;
  const [cn, setCn] = useState(
    link === location ? "grayIconLink chosen" : "grayIconLink"
  );
  useEffect(() => {
    setCn(link === location ? "grayIconLink chosen" : "grayIconLink");
  }, [location]); // eslint-disable-line

  return (
    <Link to={link} className={cn} style={{ order }}>
      {img}
      <div className="grayIconTitle">{title}</div>
    </Link>
  );
};

export default GrayIcon;
