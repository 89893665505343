import React from "react";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ProductCard from "../product/ProductCard";
import LoaderPrimary from "../UI/LoaderPrimary";
import { prioArray } from "../../helpers/hardcodedIds";
import EmptyResults from "../emptyResults/EmptyResults";

export default function Stripe({
  title,
  items = [],
  isPending = false,
  handleClick,
}) {
  items = items.filter(
    (product) =>
      !product.title.includes("אווז") && !product.title.includes("הנחה")
  ); // TODO: Do not leave it like this!
  return (
    <>
      <div className="row align-items-center">
        <div className="col-7">
          <h6 className="module-heading__title">{title}</h6>
        </div>
        <div className="col-5 text-right isLink" onClick={handleClick}>
          הכל
          <ArrowLeftIcon />
        </div>
      </div>
      <ScrollingCarousel>
        <ul className="categoryList">
          {isPending ? (
            <LoaderPrimary />
          ) : items.length ? (
            items
              .sort((item) =>
                prioArray.some((substring) => item.title.includes(substring))
                  ? -1
                  : 0
              )
              .map((product, i) => (
                <ProductCard product={product} key={product.id} />
              ))
          ) : (
            <EmptyResults />
          )}
        </ul>
      </ScrollingCarousel>
    </>
  );
}
