import React, { useState } from "react";
import { FiHome } from "react-icons/fi";
import { AiOutlineTags } from "react-icons/ai";
import { BsGift, BsWallet2 } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import cx from "classnames";
import { BiSupport } from "react-icons/bi";
import { AiFillPhone } from "react-icons/ai";
import { BsFillChatFill } from "react-icons/bs";
import Drawer from "@mui/material/Drawer";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import GrayIcon from "./GrayIcon";
import SignUp from "../sign/SignUp";
import SignIn from "../sign/SignIn";
import Menu from "../sign/Menu";
import Rules from "../sign/Rules";
import Cancel from "../sign/Cancel";
import Contact from "../sign/Contact";
import Secure from "../sign/Secure";
import ForgotPassword from "../sign/ForgotPassword";
import { setSidebarState } from "../../redux/actions-exporter";

import "./Footer.css";
import { Box } from "@mui/material";
import { IS_WHATSAPP, PROJECT_NAME, SIDEBANNER_IMAGE } from "../../config";
import { chatNumber, telephoneNumber } from "../../helpers/hardcodedIds";
import AccentIcon from "./AccentIcon";
import ConfirmDialog from "../wallets/ConfirmDialog";

const containerArr = [
  {
    img: <FiHome />,
    link: "/",
    title: "ראשי",
  },
  {
    img: <AiOutlineTags />,
    link: "/unusedbenefits",
    title: "השוברים שלי",
  },
  // {
  //   img: <DinnerDiningOutlinedIcon />,
  //   link: "",
  //   title: "מסעדות",
  // },
  {
    img: <BsWallet2 />,
    link: "/wallet",
    title: "תשלום בקופה",
  },
];

const Footer = () => {
  const dispatch = useDispatch();
  const isOpenSidebar = useSelector((state) => state.sidebar.isOpen);
  const user = useSelector((state) => state.user.user);
  const [support, setSupport] = useState(false);
  const [signUp, setSignUp] = useState("signin");
  const [item, setItem] = useState();
  const benefitsChoice = PROJECT_NAME === "benefits";
  const [matanaPopup, setMatanaPopup] = useState(false);

  function SwitchComponent({ route }) {
    switch (route) {
      case "תקנון":
        return <Rules />;
      case "מדיניות ביטול עסקה":
        return <Cancel />;
      case "יצירת קשר":
        return <Contact setItem={setItem} />;
      case "רכישה מאובטחת":
        return <Secure />;
      default:
        return <Menu setSignUp={setSignUp} setItem={setItem} />;
    }
  }
  return (
    <>
      {/* <BrowserView>
        <div className="webFooter">
          <div className="customizer border-left-blue-grey border-left-lighten-4">
            <div className="customizer-toggle box-shadow-3">
              {containerArr.map((el, i) => {
                return (
                  <Link to={el.link} key={i}>
                    {el.img}
                  </Link>
                );
              })}

              <Link to="#">
                <IoIosChatbubbles />
              </Link>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView> */}

      <div>
        <footer className="footer">
          <div
            className={cx(
              "customizer border-left-blue-grey border-left-lighten-4",
              isOpenSidebar ? "open" : "d-none d-md-block"
            )}
          >
            <div
              id="widget-52e71f7f6254c25b6801e462c69f42580ea008aa"
              style={{ display: !user ? "none" : "block" }}
              data-view="slide"
            />
            <div
              className="chatCloseIcon"
              onClick={() => dispatch(setSidebarState(!isOpenSidebar))}
              style={{
                position: "absolute",
                left: 21,
                top: 15,
                cursor: "pointer",
                color: "var(--header-text)",
              }}
            >
              <ClearIcon />
            </div>
            <div
              style={{
                width: "100%",
                minHeight: 150,
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: "-1",
                background: "var(--primary)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={SIDEBANNER_IMAGE.src}
                alt=""
                style={{
                  height: SIDEBANNER_IMAGE.height,
                }}
              />
            </div>
            {!!item && (
              <div
                style={{
                  position: "absolute",
                  right: 21,
                  top: 15,
                  cursor: "pointer",
                }}
                className="backIcon isLink"
                onClick={() => setItem("")}
              >
                <ArrowForwardIosIcon />
              </div>
            )}
            <Box mt="150px" pt={2}>
              {!user && signUp === "signup" && <SignUp setSignUp={setSignUp} />}
              {!user && signUp === "signin" && <SignIn setSignUp={setSignUp} />}
              {!user && signUp === "forgot" && (
                <ForgotPassword setSignUp={setSignUp} />
              )}
              {user && <SwitchComponent route={item} />}
            </Box>
          </div>
          <div className="sosAndGray">
            <div className="grayIcons">
              {containerArr.map((el, i) => {
                return (
                  <GrayIcon
                    img={el.img}
                    i={i}
                    order={i}
                    key={i}
                    link={el.link}
                    title={el.title}
                  />
                );
              })}
              {benefitsChoice && (
                <AccentIcon
                  img={<BsGift />}
                  title="שתף מתנה"
                  handleClick={() => setMatanaPopup(true)}
                />
              )}
            </div>
            <div
              className="mainChat"
              onClick={() => {
                setSupport(true);
              }}
              style={{
                order: 4,
              }}
            >
              <BiSupport size={40} />
              <div className="grayIconTitle white grayIconTitle__contrast">
                לנציג
              </div>
            </div>
          </div>
        </footer>
      </div>
      <Drawer
        className="callOrChat"
        PaperProps={{
          sx: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "300px",
            bottom: "90px",
            height: "120px",
            margin: "auto",
            borderRadius: "60px",
            padding: "10px",
          },
        }}
        open={support}
        onClose={() => {
          setSupport(false);
        }}
        anchor="bottom"
      >
        <a
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: "100%",
            alignItems: "center",
            color: "black",
          }}
          href={`tel:${telephoneNumber}`}
        >
          <AiFillPhone fill="var(--primary)" size={50} />
          <h6>שיחה</h6>
        </a>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: "100%",
            alignItems: "center",
          }}
          onClick={() => {
            setSupport(false);
            if (IS_WHATSAPP) {
              window.open(`https://wa.me/${chatNumber ?? telephoneNumber}`);
            } else {
              window.open(`tel:${telephoneNumber}`);
            }
          }}
        >
          <BsFillChatFill fill="var(--primary)" size={50} />
          <h6>צ'ט</h6>
        </div>
      </Drawer>
      {benefitsChoice && (
        <ConfirmDialog
          open={matanaPopup}
          handleClose={() => setMatanaPopup(false)}
          handleConfirm={() => setMatanaPopup(false)}
          text1={"האם אתה בטוח שאתה רוצה לשתף מתנה?"}
          buttonText="שתף מתנה"
        />
      )}
      {/* </MobileView> */}
    </>
  );
};

export default Footer;
