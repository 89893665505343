import { Routes, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { App as CapacitorApp } from "@capacitor/app";

import Footer from "./components/newFooter/Footer";
import Login from "./components/login/Login";
import Register from "./components/register/Register";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import UserProfile from "./components/userprofile/UserProfile";
import CategoryDetails from "./components/category/CategoryDetails";
import ProductDetails from "./components/product/ProductDetails";
import VendorsDetails from "./components/vendor/VendorDetails";
import Cart from "./components/cart/Cart";
import Wallets from "./components/wallets/Wallets";
import Payment from "./components/wallets/Payment";
import LoadMoney from "./components/wallets/LoadMoney";
import CreaditCard from "./components/wallets/CreaditCard";
import Dashboard from "./components/dashboard/Dashboard";
import CategoryList from "./components/category/CategoryList";
import Map from "./components/location/Map";
import Pay from "./components/cart/Pay";
import PaymentHistory from "./components/wallets/PaymentHistory";
import Checkout from "./components/cart/Checkout";
import PaymentCardHistory from "./components/wallets/PaymentCardHistory";
import BusinessPayment from "./components/wallets/BusinessPayment";
import PaymentApprove from "./components/wallets/PaymentApprove";
import Company from "./components/booking/Company";
import Product from "./components/booking/Product";
import BookingCart from "./components/booking/BookingCart";
import BookingCartDetail from "./components/booking/BookingCartDetail";
import AllVendor from "./components/vendor/AllVendor";
import Vendors from "./components/vendor/Vendors";
import Market from "./components/vendor/Market";
import BookingPayment from "./components/booking/BookingPayment";
import CategoryProduct from "./components/category/CategoryProduct";
import Products from "./components/category/Products";
import AllCategories from "./components/category/AllCategories";
// import Header from "./components/header/Header";
// import ChatBot from "./components/chat/ChatBot";
import RegisterBusiness from "./components/register/RegisterBusiness";
import Shops from "./components/shop/Shops";
import ManageDashboard from "./components/dashboard/ManageDashboard";
import YourAccount from "./components/userprofile/account/YourAccount";
import ReferralProgram from "./components/userprofile/referralprogram/ReferralProgram";
import Filemanager from "./components/userprofile/filemanage/Filemanager";
import Documents from "./components/userprofile/document/Documents";
import Chatting from "./components/userprofile/chat/Chatting";
import Payments from "./components/userprofile/payment/Payments";
import Gift from "./components/gift/Gift";
import GetGift from "./components/gift/getGift/GetGift";
import UnusedBenefits from "./components/unusedBenefits/UnusedBenefits";
import Success from "./components/cart/success";
import Error from "./components/cart/error";
import Sales from "./components/sales/Sales";
import { getUser, setSidebarState } from "./redux/actions-exporter";

import "./App.css";

import SelfPickupProducts from "./components/category/SelfPickupProducts";
import Rules from "./components/sign/Rules";
import Loader from "./components/loader/Loader";
import {
  IDS_FAVICONS,
  IDS_SPLASHSCREENS,
  PROJECT_NAME,
  SITE_TITLE,
} from "./config";
import BenefitsTypeChoice from "./components/unusedBenefits/BenefitsTypeChoice";

function App(props) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { isChatOpen } = useSelector((state) => state.chat);
  const { pathname } = useLocation();

  const benefitsChoice = PROJECT_NAME === "benefits";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    CapacitorApp.addListener("backButton", ({ canGoBack }) => {
      if (!canGoBack) {
        CapacitorApp.exitApp();
      } else {
        window.history.back();
      }
    });

    return () => {
      CapacitorApp.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    document.title = SITE_TITLE;

    if (PROJECT_NAME !== "pele") {
      document.documentElement.setAttribute("data-theme", PROJECT_NAME);
      IDS_FAVICONS.forEach((id) => {
        const favicon = document.getElementById(id);
        if (favicon) {
          favicon.href = favicon.href.replace(
            "favicons/",
            `favicons/${PROJECT_NAME}/`
          );
        }
      });

      IDS_SPLASHSCREENS.forEach((id) => {
        const splash = document.getElementById(id);
        if (splash) {
          splash.href = splash.href.replace(
            "splash_screens/",
            `splash_screens/${PROJECT_NAME}/`
          );
        }
      });

      const manifest = document.getElementById("manifest");
      if (manifest) {
        manifest.href = manifest.href.replace(
          "manifest.json",
          `favicons/${PROJECT_NAME}/site.webmanifest`
        );
      }
    }
  }, []);

  useEffect(() => {
    dispatch(getUser());

    document.addEventListener("b24chat.widget.logout", () => {
      dispatch(setSidebarState(false));
      dispatch(getUser());
    });
    document.addEventListener("b24chat.widget.close", () =>
      dispatch(setSidebarState(false))
    );
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return loading && window.location.pathname === "/" ? (
    <Loader type={PROJECT_NAME} />
  ) : (
    <div className={"pageDad"}>
      <div className="page">
        <div className={isChatOpen ? "activeChat" : ""}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/userprofile" element={<UserProfile />} />
            <Route path="/categorydetails" element={<CategoryDetails />} />
            <Route path="/category/:id" element={<CategoryProduct />} />
            <Route path="/hot" element={<Products type="hot" />} />
            <Route path="/self-pickups" element={<SelfPickupProducts />} />
            <Route
              path="/attractions"
              element={<Products type="attractions" />}
            />
            <Route path="/all" element={<AllCategories />} />
            <Route path="/product/:id" element={<ProductDetails />} />
            <Route path="/vendor/:id" element={<VendorsDetails {...props} />} />
            <Route path="/sales" element={<Sales />} />
            <Route
              path="/market/:id"
              element={<Market showBenefitType={false} />}
            />
            <Route path="/foods/:id" element={<Market />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/wallet" element={<Wallets />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/loadmoney" element={<LoadMoney />} />
            <Route path="/loadcredit" element={<LoadMoney credit={true} />} />
            <Route path="/creaditcard" element={<CreaditCard />} />
            <Route path="/shops" element={<Shops />} />
            <Route path="/categorylist" element={<CategoryList />} />
            <Route path="/map" element={<Map />} />
            <Route path="/paymenthistory" element={<PaymentHistory />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route
              path="/paymentcardhistory"
              element={<PaymentCardHistory />}
            />
            <Route path="/company" element={<Company />} />
            <Route path="/product" element={<Product />} />
            <Route path="/bookingcart" element={<BookingCart />} />
            <Route path="/bookingcartdetail" element={<BookingCartDetail />} />
            <Route path="/allvendors/:type" element={<AllVendor />} />
            <Route path="/vendors" element={<Vendors />} />
            <Route path="/bookingpayment" element={<BookingPayment />} />
            <Route path="/gift/*" element={<Gift />} />
            <Route path="/getgift" element={<GetGift />} />
            {/* <Route path="/chatbot" element={<ChatBot />} /> */}
            <Route path="/registerbusiness" element={<RegisterBusiness />} />
            <Route path="/managedashboard" element={<ManageDashboard />} />
            <Route path="/youraccount" element={<YourAccount />} />
            <Route path="/referralprogram" element={<ReferralProgram />} />
            <Route path="/filemanager" element={<Filemanager />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/chatting" element={<Chatting />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/pay" element={<Pay />} />
            <Route path="/privacy" element={<Rules />} />
            <Route
              path="/businesspayment/:itemId"
              element={<BusinessPayment />}
            />
            <Route path="/paymentapprove" element={<PaymentApprove />} />
            <Route
              path="/unusedbenefits"
              element={
                benefitsChoice ? <BenefitsTypeChoice /> : <UnusedBenefits />
              }
            />
            {benefitsChoice && (
              <Route
                path="/mycoupons"
                element={<UnusedBenefits type="mycoupons" />}
              />
            )}
            {benefitsChoice && (
              <Route
                path="/benefitscoupons"
                element={<UnusedBenefits type="benefits" />}
              />
            )}
            <Route
              path={"/checkout/success/:orderId"}
              element={<Success />}
              exact
            />
            <Route path={"/checkout/error/:orderId"} element={<Error />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
