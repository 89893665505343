import { CircularProgress } from "@mui/material";
import React from "react";

const LoaderPrimary = () => {
  return (
    <CircularProgress
      sx={{
        color: "var(--primary)",
        m: "auto 0",
      }}
    />
  );
};

export default LoaderPrimary;
