import bannerSideMiluim from "./assets/logo/miluim/logoMiluim.svg";
import bannerSidePele from "./assets/images/banner_side_Pele.png";
import bannerSideBenefits from "./assets/images/banner_side_Benefits.png";
import bannerSideMshuk from "./assets/logo/mshuk/win.png";

export const PROJECT_NAME = process.env.REACT_APP_PROJECT || "default";

// export const MATBEOT_ENABLED = false;
export const MATBEOT_ENABLED = PROJECT_NAME === "mshuk";

const marketsEnabledMap = {
  default: true,
  mshuk: false,
};

export const MARKETS_ENABLED =
  marketsEnabledMap[PROJECT_NAME] ?? marketsEnabledMap.default;

const siteTitles = {
  default: "פלא",
  pele: "פלא",
  miluim: "המילואימניקים",
  benefits: "Benefits",
  mshuk: "WinCard+",
};

export const SITE_TITLE = siteTitles[PROJECT_NAME] ?? siteTitles.default;

const isWhatsappMap = {
  default: false,
  pele: true,
};

export const IS_WHATSAPP = isWhatsappMap[PROJECT_NAME] ?? isWhatsappMap.default;

const sideBanners = {
  default: { src: bannerSidePele, height: 150 },
  pele: { src: bannerSidePele, height: 150 },
  miluim: { src: bannerSideMiluim, height: 150 },
  benefits: { src: bannerSideBenefits, height: 150 },
  mshuk: { src: bannerSideMshuk, height: 50 },
};

export const SIDEBANNER_IMAGE =
  sideBanners[PROJECT_NAME] || sideBanners.default;

export const IDS_FAVICONS = [
  "favicon-ico",
  "favicon-32x32",
  "favicon-16x16",
  "apple-touch-icon",
  "apple-touch-icon-180x180",
];

export const IDS_SPLASHSCREENS = [
  "iPhone14ProMaxLand",
  "iPhone14ProLand",
  "iPhone14Plus13ProMax12ProMaxLand",
  "iPhone14to12Land",
  "iPhone13Mini12Mini11ProXSLand",
  "iPhone11ProMaxXSMAXLand",
  "iPhone11XR_Land",
  "iPhone8Plus7Plus6sPlus6PlusLand",
  "iPhone8toSE_Land",
  "iPhoneSEiPodTouch5GenLandscape",
  "iPadPro12_9Land",
  "iPadPro11_10_5Land",
  "iPadAir10_9Land",
  "iPadAir10_5Land",
  "iPad10_2Land",
  "iPad9_7ProMiniAirLand",
  "iPadMini8_3Land",
  "iPhone14ProMaxPort",
  "iPhone14ProPort",
  "iPhone14PlusMaxPort",
  "iPhone14Port",
  "iPhone13MiniPort",
  "iPhone11ProMaxPort",
  "iPhone11Port",
  "iPhone8PlusPort",
  "iPhone8Port",
  "iPhoneSEPort",
  "iPadProPort",
  "iPadPro11Port",
  "iPadAir109Port",
  "iPadAir105Port",
  "iPad102Port",
  "iPad97Port",
  "iPad83MiniPort",
];
