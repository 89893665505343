import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fastCheckout, getProductById } from "../../redux/actions-exporter";
import { useTranslation } from "react-i18next";
import { NumericKeyboard } from "react-numeric-keyboard";
import { GiTwoCoins } from "react-icons/gi";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import BackspaceIcon from "@mui/icons-material/Backspace";
import LoadingButton from "@mui/lab/LoadingButton";

import Header from "../header/Header";
import "../wallets/BusinessPayment.css";
import { MATBEOT_ENABLED } from "../../config";

// ---------------------------

const DotCalc = ({ handleClick }) => {
  return (
    <span
      className="dotKey"
      style={{ position: "absolute", left: 0, bottom: 0 }}
      onClick={handleClick}
    >
      .
    </span>
  );
};

// ---------------------------

export default function BusinessPayment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { itemId } = useParams();
  const { t } = useTranslation();
  const [amountToReceive, setAmountToReceive] = useState("0");
  const [load, setLoad] = useState(false);
  const amountToPay = amountToReceive * 0.88;
  const inputElement = useRef(null);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    dispatch(getProductById(itemId));
  }, [dispatch, itemId]);

  const product = useSelector((state) => state.product.product);

  const minPrice =
    product?.prices?.find((price) => price.type === "min_price")?.value || 1;

  const maxPrice =
    product?.prices?.find((price) => price.type === "max_price")?.value || 1500;

  const handleKey = ({ value, name }) => {
    if (!isNaN(name)) {
      const updatedAmount =
        amountToReceive === "0" ? name : amountToReceive + name;
      if (
        (amountToReceive.indexOf(".") === -1 ||
          amountToReceive.indexOf(".") > amountToReceive.length - 3) &&
        Number(updatedAmount) <= maxPrice
      ) {
        setAmountToReceive(updatedAmount);
        setErrorMessage("");
      } else {
        setErrorMessage(`הסכום צריך להיות עד ${maxPrice} ש"ח`);
      }
    } else {
      setAmountToReceive((prev) =>
        prev.length === 1 ? "0" : prev.slice(0, -1)
      );
    }
  };

  const handelDotClick = useCallback(() => {
    if (!amountToReceive.includes(".")) {
      setAmountToReceive((prev) => prev + ".");
    }
  }, [amountToReceive]);

  const handleSubmit = () => {
    const amount = amountToReceive.endsWith(".")
      ? amountToReceive.slice(0, -1)
      : amountToReceive;

    const isValidAmount =
      !isNaN(Number(amount)) &&
      Number(amount) >= minPrice &&
      Number(amount) <= maxPrice;

    if (isValidAmount && amount !== "0") {
      setLoad(true);
      dispatch(
        fastCheckout(
          {
            productId: itemId,
            amount,
            priceAtPurchase: amountToPay,
          },
          (data) => {
            setLoad(false);
            const { orderId, paymentURL } = data;
            navigate("/pay", {
              state: { paymentURL, orderId },
            });
          }
        )
      );
    } else {
      setErrorMessage(`אנא הזן סכום בין ${minPrice} ש"ח ל-${maxPrice} ש"ח`);
    }
  };

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  return (
    <div>
      <Header isFull={false} reduce={amountToReceive - amountToPay || 0} />
      <div className="loadMoneyTemplate">
        <div className="container">
          <div className="templateHeading">
            <h1 className="templateTitle">{t("payment at the business")}</h1>

            <span
              className="backIcon isLink"
              onClick={() => {
                navigate("/wallet");
              }}
            >
              <ArrowForwardIosIcon />
            </span>
          </div>
          <br />
          <div className="templateHeading">
            מינימום לתשלום - ₪{minPrice}, מקסימום לתשלום - ₪
            {maxPrice.toLocaleString("he-IL")}
          </div>

          <div className="loadMoneyCard">
            <div className="loadMoneyCard-price">
              <h2 ref={inputElement}>
                <bdi>{amountToReceive}</bdi>
              </h2>
            </div>
            <div className="arrowWrapper">
              <ArrowDownwardIcon className="arrow" />
            </div>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            {MATBEOT_ENABLED ? (
              <h3 className="loadMoneyCard-text">
                {t("payment amount after take")}{" "}
                {(amountToReceive - amountToPay)?.toFixed(2) || 0}
                <GiTwoCoins />
                {/* {t("shuk coins")} */}
              </h3>
            ) : (
              <h3 className="loadMoneyCard-text">סכום לתשלום לאחר 12% הנחה.</h3>
            )}

            <h2 className="loadMoneyCard-price">{amountToPay?.toFixed(2)}</h2>
            <div className="key">
              <NumericKeyboard
                backSpaceIcon={<BackspaceIcon fontSizeSmall />}
                isOpen={true}
                onChange={handleKey}
                containerClassName="key"
              />
              <DotCalc handleClick={handelDotClick} />
            </div>
            <div className="loadMoneyCard-btn">
              <LoadingButton
                className="blueBtn"
                onClick={handleSubmit}
                loading={load}
              >
                {t("pay")}
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
