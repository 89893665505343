import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { styled } from "@mui/system";
// import TabsListUnstyled from "@mui/base/TabsListUnstyled";
// import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
// import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
// import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";

import Header from "../header/Header";
import {
  getMerchantById,
  getMerchantProducts,
} from "../../redux/API/merchant/merchant.action";

import "../vendor/vendorsDetails.css";
import ProductCard from "../product/ProductCard";

// const blue = {
//   50: "#F0F7FF",
//   100: "#C2E0FF",
//   200: "#80BFFF",
//   300: "#66B2FF",
//   400: "#3399FF",
//   500: "#007FFF",
//   600: "#0072E5",
//   700: "#0059B2",
//   800: "#004C99",
//   900: "#003A75",
// };
// const ratingTimes = 5;

// const Tabv = styled(TabUnstyled)`
//   font-family: IBM Plex Sans, sans-serif;
//   color: white;
//   cursor: pointer;
//   font-size: 0.875rem;
//   font-weight: bold;
//   background-color: transparent;
//   width: 100%;
//   padding: 12px 16px;
//   margin: 6px 6px;
//   border: none;
//   border-radius: 5px;
//   display: flex;
//   justify-content: center;

//   &:hover {
//     background-color: ${blue[400]};
//   }

//   &.${buttonUnstyledClasses.focusVisible} {
//     color: #fff;
//     outline: none;
//     background-color: ${blue[200]};
//   }

//   &.${tabUnstyledClasses.selected} {
//     background-color: ${blue[50]};
//     color: ${blue[600]};
//   }

//   &.${buttonUnstyledClasses.disabled} {
//     opacity: 0.5;
//     cursor: not-allowed;
//   }
// `;

// const TabPanel = styled(TabPanelUnstyled)`
//   width: 100%;
//   font-family: IBM Plex Sans, sans-serif;
//   font-size: 0.875rem;
// `;
// const TabsList = styled(TabsListUnstyled)`
//   min-width: 320px;
//   background-color: ${blue[500]};
//   border-radius: 8px;
//   margin-bottom: 16px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   align-content: space-between;
// `;

export default function VendorsDetails() {
  // const [ratings, setRatings] = React.useState([0, 2, 3, 0, 0]);
  // const navigate = useNavigate();
  // const location = useLocation();
  const dispatch = useDispatch();
  // const [filterProducts, setFilterProducts] = useState([]);
  const { t } = useTranslation();
  const { id } = useParams();
  const merchant = useSelector((state) => state.merchant.merchant);
  const products = useSelector((state) => state.merchant.merchantProducts);

  useEffect(() => {
    dispatch(getMerchantById(id));
    dispatch(getMerchantProducts(id));
  }, [id]); //eslint-disable-line

  // useEffect(() => {
  //   setFilterProducts(
  //     products.filter(
  //       (p) => p.prices?.find((price) => price.type === "price")?.value
  //     )
  //   );
  // }, [products]);

  // const handleFavChange = () => {};

  // const handleChange = (event, newValue) => {
  //   if (newValue === "all") {
  //     setFilterProducts(products);
  //   } else {
  //     setFilterProducts(
  //       products.filter(
  //         (product) =>
  //           !!product?.categories?.find((category) => category.id === newValue)
  //       )
  //     );
  //   }
  // };
  return (
    <>
      <Header />
      <div className="container">
        <div className="vendorTemplate">
          <h5 className="module-heading__title">{merchant?.title || ""}</h5>
          {merchant.address && (
            <div className="vendorContent">{merchant.address}</div>
          )}
          <div className="vendorContent">
            {(merchant?.content || merchant?.description) && (
              <div className="vendorContent-text">
                {merchant?.content || merchant?.description}
              </div>
            )}
          </div>

          <div className="product-block">
            <ul className="product-Module">
              {products.length
                ? products.map((product) => (
                    <ProductCard product={product} key={product.id} />
                  ))
                : t("No products")}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
