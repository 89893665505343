import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import { Box, Button, DialogActions, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";

const ProductInfoDialog = ({ open, product, handleClose, handleConfirm }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "80%",
          maxWidth: "350px",
          height: "50%",
          maxHeight: "500px",
        },
      }}
    >
      <IconButton
        sx={{
          justifyContent: "end",
          p: 0,
          width: "25px",
          position: "absolute",
          top: 10,
          right: 10,
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box
          className="container"
          gap={1}
          p={2}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
          }}
        >
          <div className="productDetails-img" style={{ alignSelf: "center" }}>
            <img
              src={`${process.env.REACT_APP_API_URL}/upload${product?.mainImageURL}`}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="productDetails-content">
            <h6 className="productDetails-contentTitle">{t("description")}</h6>
            <p className="productDetails-contentText">
              {product?.content || ""}
            </p>
          </div>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          p: 2,
        }}
      >
        <Button
          onClick={handleConfirm}
          variant="contained"
          sx={{
            fontWeight: 700,
            borderRadius: "50px",
            background: "#212B36",
            boxShadow: "none",
            "&:hover": {
              background: "var(--primary-dark)",
              boxShadow: "none",
            },
          }}
        >
          לתשלום
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductInfoDialog;
