import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import { shortenString } from "../../helpers/shortenString";
import {
  confirmMashbirDialogText,
  excludedProducts,
  mashbirPopupIds,
  oldPricesMap,
} from "../../helpers/hardcodedIds";
import ConfirmDialog from "../wallets/ConfirmDialog";

const ProductCard = ({ product }) => {
  const navigate = useNavigate();
  const [confirmDialog, setConfirmDialog] = useState(false);

  const productPrice = product?.prices?.find(
    (price) => price.type === "price"
  )?.value;

  const basePrice = product?.prices?.find(
    (price) => price.type === "base_price"
  )?.value;

  const oldPrice = oldPricesMap[product.id] || basePrice;

  const handleClick = () => {
    if (mashbirPopupIds.includes(product.id)) {
      setConfirmDialog(true);
    } else {
      navigate(`/product/${product.id}`);
    }
  };

  if (excludedProducts.includes(product.id)) return null;

  return (
    <>
      <li
        key={product.id}
        className="product-Module__list isLink"
        onClick={handleClick}
      >
        <div className="product-box" style={{ direction: "ltr" }}>
          <div
            className="product-box__img"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_API_URL}/upload${product.mainImageURL})`,
            }}
          >
            <div className="product-box__likeBtn">
              <FavoriteBorderOutlinedIcon />
            </div>
          </div>
          <div className="product-box__info">
            <div className="product-box__infoTop">
              <div className="product-box__titleAndLoc">
                <h6 className="product-box__brand">
                  {shortenString(product.title, 35)}
                </h6>
              </div>
            </div>
            <div
              className="product-box__priceDiv"
              style={{
                justifyContent: oldPrice ? "space-between" : "center",
              }}
            >
              {oldPrice && (
                <span
                  className="product-box__price"
                  style={{ textDecoration: "line-through" }}
                >
                  ₪{oldPrice}
                </span>
              )}
              <span className="product-box__price bold">
                {productPrice ? "לתשלום: ₪" + productPrice : "סכום לבחירתך"}
              </span>
            </div>
          </div>
        </div>
      </li>
      <ConfirmDialog
        open={!!confirmDialog}
        handleClose={() => setConfirmDialog("")}
        handleConfirm={() => navigate(`/product/${product.id}`)}
        text1={confirmMashbirDialogText.text1}
        text2={confirmMashbirDialogText.text2}
        text3={confirmMashbirDialogText.text3}
      />
    </>
  );
};

export default ProductCard;
