export const OrderActionTypes = {
  GET: {
    ORDER_PENDING: "GET_ORDER_PENDING",
    ORDER_SUCCESS: "GET_ORDER_SUCCESS",
    ORDER_ERROR: "GET_ORDER_ERROR",

    ORDERS_PENDING: "GET_ORDERS_PENDING",
    ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
    ORDERS_ERROR: "GET_ORDERS_ERROR",

    USAGE_PENDING: "GET_USAGE_PENDING",
    USAGE_SUCCESS: "GET_USAGE_SUCCESS",
    USAGE_ERROR: "GET_USAGE_ERROR",

    BENEFIT_CARDS_PENDING: "GET_BENEFIT_CARDS_PENDING",
    BENEFIT_CARDS_SUCCESS: "GET_BENEFIT_CARDS_SUCCESS",
    BENEFIT_CARDS_ERROR: "GET_BENEFIT_CARDS_ERROR",

    LOAD_CARDS_PENDING: "GET_LOAD_CARDS_PENDING",
    LOAD_CARDS_SUCCESS: "GET_LOAD_CARDS_SUCCESS",
    LOAD_CARDS_ERROR: "GET_LOAD_CARDS_ERROR",

    ALL_CARDS_PENDING: "GET_ALL_CARDS_PENDING",
    ALL_CARDS_SUCCESS: "GET_ALL_CARDS_SUCCESS",
    ALL_CARDS_ERROR: "GET_ALL_CARDS_ERROR",
  },
  CLEAR: {
    ORDER: "CLEAR_ORDER",
  },
};
