import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import OtpInput from "react-otp-input";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import {
  loginViaOtp,
  validationCode,
  setSidebarState,
  getUser,
} from "../../redux/actions-exporter";
import autoReadSMS from "../../helpers/autoReadSMS";
import { FormControlLabel } from "@material-ui/core";
import { Radio, RadioGroup } from "@mui/material";

const EXPIRED_OTP_TIME = 60; //sec
const OTP_LENGTH = 4; //chars
const theme = createTheme({
  direction: "rtl",
});
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function SignIn() {
  const dispatch = useDispatch();
  const phoneRef = useRef(null);
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [showValidationCode, setShowValidationCode] = useState(false);
  const [messageType, setMessageType] = useState("SMS");

  const loginViaOtpError = useSelector(
    (state) => state?.user?.loginViaOtpError
  );
  const handleChangePhone = (event) => {
    setPhone(event.target.value);
  };
  const handleValidateOTP = useCallback(
    (phone, otp) => {
      dispatch(
        validationCode(
          phone,
          otp,
          () => {
            setOtp("");
            dispatch(setSidebarState(false));
            dispatch(getUser());
          },
          (message) => {
            if (message === "Your code has expired") {
              setOtp("");
              setShowValidationCode(false);
            } else if (message === "OTP verification failed") {
              setOtp("");
            } else {
              setOtp("");
              setShowValidationCode(false);
              dispatch(setSidebarState(false));
            }
          }
        )
      );
    },
    [dispatch]
  );
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formPhone = data.get("identifier");

    if (otp) {
      handleValidateOTP(formPhone, otp);
    } else {
      if (!showValidationCode) {
        autoReadSMS(setOtp, EXPIRED_OTP_TIME);
        dispatch(
          loginViaOtp(
            {
              phone: formPhone,
              messageType: messageType,
              host: process.env.REACT_APP_API_URL_HOST,
              otpLength: OTP_LENGTH,
            },
            () => {
              setShowValidationCode(true);
            }
          )
        );
      }
    }
  };
  const handleSwitchToPhone = () => {
    setOtp("");
    setShowValidationCode(false);
  };
  useEffect(() => {
    if (otp && otp.length === OTP_LENGTH) handleValidateOTP(phone, otp);
  }, [otp, handleValidateOTP, phone]);

  useEffect(() => {
    if (phoneRef.current && phone?.length >= 10) {
      phoneRef.current.blur();
    }
  }, [phoneRef, phone]);

  useEffect(() => {
    if (otp?.length >= OTP_LENGTH) {
      document.activeElement.blur();
    }
  }, [otp]);

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />

          <Box
            sx={{
              px: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            component="form"
            onSubmit={handleSubmit}
          >
            {/* <Avatar
              sx={{
                mt: 2,
                mb: 3,
                bgcolor: "var(--yellow-accent)",
                width: 90,
                height: 90,
                borderRadius: "20px !important",
              }}
            >
              <img src="/images/icons/icon-lock.svg" alt="" />
            </Avatar> */}
            <Typography component="h1" variant="h5">
              {showValidationCode ? "אימות הקוד" : "אימות חשבון"}
            </Typography>
            <Box
              // noValidate
              sx={{
                textAlign: "left",
                my: 3,
                width: 1,
                height: 150,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {!showValidationCode && (
                <>
                  <TextField
                    inputRef={phoneRef}
                    margin="normal"
                    required
                    fullWidth
                    id="identifier"
                    label="טלפון"
                    placeholder="מספר טלפון הנייד (10 ספרות)"
                    name="identifier"
                    value={phone}
                    type="tel"
                    onChange={handleChangePhone}
                    autoComplete="identifier"
                    autoFocus
                    sx={{
                      "& label.Mui-focused": {
                        color: "var(--primary)",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "var(--primary)",
                        },
                        borderRadius: "8px",
                      },
                    }}
                    helperText="המספר ישמש לקבלת קוד אימות"
                    FormHelperTextProps={{
                      sx: {
                        fontFamily: "Open Sans",
                        marginInlineStart: "20px",
                        "&::before": {
                          content: "url('/images/icons/ic-eva_info-fill.svg')",
                          position: "absolute",
                          insetInlineStart: 0,
                          marginTop: "2px",
                        },
                      },
                    }}
                  />
                  <Box sx={{ my: 2 }}>
                    <Typography>בחר סוג הודעה:</Typography>
                    <RadioGroup
                      row
                      name="message-type"
                      value={messageType}
                      onChange={(event) => setMessageType(event.target.value)}
                    >
                      <FormControlLabel
                        value="SMS"
                        control={<Radio />}
                        label="SMS"
                      />
                      <FormControlLabel
                        value="TTS"
                        control={<Radio />}
                        label="הודעה קולית"
                      />
                    </RadioGroup>
                  </Box>
                </>
              )}
              {loginViaOtpError && (
                <>
                  <Typography component="p" variant="subtitle2">
                    המספר טלפון שלך לא נמצא ברשימה של טלפונים מאושרים. להרשמה נא
                    להיכנס ללינק:{" "}
                  </Typography>
                  <Link
                    href="http://landing.pelegift.co.il"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    landing.pelegift.co.il
                  </Link>
                </>
              )}
              {showValidationCode && (
                <>
                  <Typography>
                    שלחנו הודעה עם קוד חד פעמי למספר טלפון הנייד{" "}
                    <strong>
                      <bdi>
                        {phone.length > 3
                          ? "*".repeat(phone.length - 3) + phone.slice(-3)
                          : ""}
                      </bdi>
                    </strong>
                  </Typography>
                  <Typography>מה הקוד שקיבלת?</Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <OtpInput
                      // isDisabled={end}
                      autocomplete="one-time-code"
                      shouldAutoFocus
                      placeholder={"*".repeat(OTP_LENGTH)}
                      value={otp}
                      onChange={(e) => {
                        setOtp(e);
                      }}
                      isInputNum={true}
                      numInputs={OTP_LENGTH}
                      separator={<span> </span>}
                      containerStyle={{
                        direction: "ltr",
                        gap: 12,
                      }}
                      inputStyle={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "8px",
                        border: "1px solid var(--gray)",
                      }}
                    />

                    <CountdownCircleTimer
                      isPlaying
                      duration={EXPIRED_OTP_TIME}
                      colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                      colorsTime={[60, 30, 15, 0]}
                      size={50}
                      strokeWidth={3}
                      onComplete={() => {
                        setShowValidationCode(false);
                        setOtp("");
                      }}
                    >
                      {({ remainingTime }) => remainingTime}
                    </CountdownCircleTimer>
                  </Box>
                </>
              )}
            </Box>
            <Box
              sx={{
                mt: 1,
                width: 1,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {!showValidationCode && (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    color: "var(--secondary) !important",
                    borderRadius: "50px",
                    background: "var(--primary)",
                    boxShadow: "none",
                    "&:hover": {
                      background: "var(--primary-dark)",
                      boxShadow: "none",
                    },
                  }}
                >
                  {showValidationCode
                    ? "אימות הקוד"
                    : "לקבלת קוד אימות והתחברות"}
                </Button>
              )}

              {showValidationCode && (
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleSwitchToPhone}
                  sx={{
                    color: "var(--secondary) !important",
                    borderColor: "var(--secondary) !important",
                    borderRadius: "50px",
                    boxShadow: "none",
                  }}
                >
                  לשינוי מספר הטלפון
                </Button>
              )}
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
}
