export const ProductsActionTypes = {
  GET: {
    GET_PRODUCT_PENDING: "GET_PRODUCT_PENDING",
    GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
    GET_PRODUCT_ERROR: "GET_PRODUCT_ERROR",

    GET_HOT_PRODUCTS_PENDING: "GET_HOT_PRODUCTS_PENDING",
    GET_HOT_PRODUCTS_SUCCESS: "GET_HOT_PRODUCTS_SUCCESS",
    GET_HOT_PRODUCTS_ERROR: "GET_HOT_PRODUCTS_ERROR",

    GET_SEARCH_PRODUCTS_PENDING: "GET_SEARCH_PRODUCTS_PENDING",
    GET_SEARCH_PRODUCTS_SUCCESS: "GET_SEARCH_PRODUCTS_SUCCESS",
    GET_SEARCH_PRODUCTS_ERROR: "GET_SEARCH_PRODUCTS_ERROR",

    GET_ATTRACTION_PRODUCTS_PENDING: "GET_ATTRACTION_PRODUCTS_PENDING",
    GET_ATTRACTION_PRODUCTS_SUCCESS: "GET_ATTRACTION_PRODUCTS_SUCCESS",
    GET_ATTRACTION_PRODUCTS_ERROR: "GET_ATTRACTION_PRODUCTS_ERROR",

    GET_SELF_PICKUP_PRODUCTS_PENDING: "GET_SELF_PICKUP_PRODUCTS_PENDING",
    GET_SELF_PICKUP_PRODUCTS_SUCCESS: "GET_SELF_PICKUP_PRODUCTS_SUCCESS",
    GET_SELF_PICKUP_PRODUCTS_ERROR: "GET_SELF_PICKUP_PRODUCTS_ERROR",

    GET_PRODUCT_BY_ID_PENDING: "GET_PRODUCT_BY_ID_PENDING",
    GET_PRODUCT_BY_ID_SUCCESS: "GET_PRODUCT_BY_ID_SUCCESS",
    GET_PRODUCT_BY_ID_ERROR: "GET_PRODUCT_BY_ID_ERROR",

    GET_FASHION_OPEN_PRODUCTS_PENDING: "GET_FASHION_OPEN_PRODUCTS_PENDING",
    GET_FASHION_OPEN_PRODUCTS_SUCCESS: "GET_FASHION_OPEN_PRODUCTS_SUCCESS",
    GET_FASHION_OPEN_PRODUCTS_ERROR: "GET_FASHION_OPEN_PRODUCTS_ERROR",

    GET_RESTAURANTS_OPEN_PRODUCTS_PENDING:
      "GET_RESTAURANTS_OPEN_PRODUCTS_PENDING",
    GET_RESTAURANTS_OPEN_PRODUCTS_SUCCESS:
      "GET_RESTAURANTS_OPEN_PRODUCTS_SUCCESS",
    GET_RESTAURANTS_OPEN_PRODUCTS_ERROR: "GET_RESTAURANTS_OPEN_PRODUCTS_ERROR",

    GET_HOME_BABY_OPEN_PRODUCTS_PENDING: "GET_HOME_BABY_OPEN_PRODUCTS_PENDING",
    GET_HOME_BABY_OPEN_PRODUCTS_SUCCESS: "GET_HOME_BABY_OPEN_PRODUCTS_SUCCESS",
    GET_HOME_BABY_OPEN_PRODUCTS_ERROR: "GET_HOME_BABY_OPEN_PRODUCTS_ERROR",
  },
};
