import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Header from "../header/Header";
import { getLoadCards } from "../../redux/actions-exporter";

import "../wallets/paymentHistory.css";
import { pendingStatus } from "../../redux/API/order/order.reducer";
import LoaderPrimary from "../UI/LoaderPrimary";

function PaymentHistory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loadCards = useSelector((state) => state.order.loadCards);
  const loadCardsPending = useSelector(
    (state) => state.order?.isLoadCardsPending
  );

  // console.log({ loadCards, loadCardsPending });

  useEffect(() => {
    dispatch(getLoadCards());
  }, [dispatch]);

  return (
    <div>
      <Header isFull={true} />
      <div className="paymentHestoryTemplate">
        <div className="container">
          <div className="templateHeading">
            <span
              className="backIcon isLink"
              onClick={() => {
                navigate("/wallet");
              }}
            >
              <ArrowForwardIcon />
            </span>
            <h1 className="templateTitle">{t("allp")}</h1>
          </div>
          <ul className="paymentHestoryCards">
            {loadCards.length > 0
              ? loadCards.map((card, i) => {
                  return (
                    <li className="cartProduct-Module__list" key={i}>
                      <div className="cartProduct-box">
                        <div className="cartProduct-box__info">
                          <h6 className="cartProduct-box__brand">
                            {card.product?.title}
                          </h6>
                          <h4 className="cartProduct-box__name">
                            {moment(card.createdAt).format("MMM Do YY hh:mm")}
                          </h4>
                        </div>
                      </div>
                      <div className="cartProduct-rightBlock">
                        <div className="cartProduct-box__brandBox">
                          <div
                            className="cartProduct-box__price"
                            style={{ display: "block" }}
                          >
                            {card?.balance || 0}₪
                            <br />
                            <br />
                            <div className="cartProduct-box__metadata">
                              מספר כרטיס: {card.cardNumber}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              : "עדיין אין כרטיסים."}
          </ul>
          {!loadCards.length &&
            (loadCardsPending === pendingStatus.IDLE ||
              loadCardsPending === pendingStatus.PENDING) && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <LoaderPrimary />
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default PaymentHistory;
