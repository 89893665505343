import { toast } from "react-toastify";
import i18n from "../../../components/language/i18";
import { UserActionTypes } from "./user.types";
import { CartActionTypes } from "../cart/cart.types";
import { axiosInstance } from "../../axios.config";

const botId = +process.env.REACT_APP_BOT_ID || 355;

export const getUser = () => (dispatch) => {
  dispatch({ type: UserActionTypes.GET_USER_PENDING });
  dispatch({ type: UserActionTypes.GET_POINTS_PENDING });

  axiosInstance
    .get(`/sso/user/me`)
    .then((res) => {
      dispatch({
        type: UserActionTypes.GET_USER_SUCCESS,
        payload: res.data.user,
      });
      dispatch({
        type: CartActionTypes.GET.CART_COUNT_SUCCESS,
        payload: res.data.cartCount,
      });
    })
    .catch((error) => {
      localStorage.removeItem("token");
      dispatch({
        type: UserActionTypes.GET_USER_ERROR,
        payload: error.response.data,
      });
      dispatch({
        type: UserActionTypes.LOGOUT_USER,
        payload: null,
      });
    });
};

export const getPointsBalance = () => (dispatch) => {
  dispatch({ type: UserActionTypes.GET_POINTS_PENDING });

  axiosInstance
    .get(`/customer/getMyPointsBalance`, {
      params: {
        botId,
      },
    })
    .then((res) => {
      dispatch({
        type: UserActionTypes.GET_POINTS_SUCCESS,
        payload: res.data.pointsBalance,
      });
    })
    .catch((error) => {
      dispatch({
        type: UserActionTypes.GET_POINTS_ERROR,
        payload: error.response.data,
      });
    });
};

export const login = (data, successCallback) => (dispatch) => {
  dispatch({ type: UserActionTypes.GET_USER_PENDING });

  axiosInstance
    .post(`/user/login`, data)
    .then((res) => {
      dispatch({
        type: UserActionTypes.GET_USER_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: CartActionTypes.GET.CART_COUNT_SUCCESS,
        payload: res.data.cartCount,
      });

      if (typeof successCallback === "function") {
        successCallback();
      }
    })
    .catch((error) => {
      dispatch({
        type: UserActionTypes.GET_USER_ERROR,
        payload: error.response.data,
      });

      toast.error(i18n.t(`${error.response.data.message}`));
    });
};

export const loginViaOtp = (data, successCallback) => (dispatch) => {
  dispatch({ type: UserActionTypes.LOGIN.USER_VIA_OTP_PENDING });

  axiosInstance
    .post(`/sso/otp/customer/login`, { ...data, botId })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: UserActionTypes.LOGIN.USER_VIA_OTP_SUCCESS,
          payload: res.data,
        });

        if (typeof successCallback === "function") {
          successCallback();
        }
      } else {
        dispatch({
          type: UserActionTypes.LOGIN.USER_VIA_OTP_ERROR,
          payload: res.data,
        });
        toast.error(i18n.t(`${res.data.message}`));
      }
    })
    .catch((error) => {
      dispatch({
        type: UserActionTypes.LOGIN.USER_VIA_OTP_ERROR,
        payload: error.response.data,
      });
      toast.error(i18n.t(`${error.response.data.message}`));
    });
};

export const validationCode =
  (login, code, successCallback, failCallback) => (dispatch) => {
    dispatch({ type: UserActionTypes.GET_USER_PENDING });

    axiosInstance
      .post("/sso/otp/verify", {
        phone: login,
        otp: code,
        botId,
      })
      .then((res) => {
        if (!res.data.success) {
          dispatch({
            type: UserActionTypes.GET_USER_ERROR,
            payload: res.data,
          });

          if (typeof failCallback === "function") {
            failCallback(res.data.message);
          }

          toast.error(i18n.t(res.data.message));
          return;
        }

        const { token } = res.data;
        localStorage.setItem("token", token);
        dispatch({
          type: UserActionTypes.GET_USER_SUCCESS,
          payload: res.data,
        });

        if (typeof successCallback === "function") {
          successCallback();
        }
      })
      .catch((error) => {
        dispatch({
          type: UserActionTypes.GET_USER_ERROR,
          payload: error.response.data,
        });

        if (typeof failCallback === "function") {
          failCallback(error.response.data.message);
        }

        toast.error(i18n.t(`${error.response.data.message}`));
      });
  };

export const logout = (successCallback) => (dispatch) => {
  axiosInstance.delete(`/sso/logout`).finally((res) => {
    localStorage.removeItem("token");
    dispatch({
      type: UserActionTypes.LOGOUT_USER,
      payload: null,
    });

    if (typeof successCallback === "function") {
      successCallback();
    }
  });
};

export const addMoney =
  (money = 0, credit = false) =>
  (dispatch) => {
    dispatch({ type: UserActionTypes.POST_MONEY_PENDING });

    axiosInstance
      .post(`/user/money`, {
        credit,
        money,
      })
      .then((res) =>
        dispatch({
          type: UserActionTypes.POST_MONEY_SUCCESS,
          payload: res.data,
        })
      )
      .catch((error) => {
        dispatch({
          type: UserActionTypes.POST_MONEY_ERROR,
          payload: error.response.data,
        });

        toast.error(error?.response?.data?.message);
      });
  };

export const sendMoney =
  (data, successCallback, errorCallback) => (dispatch) => {
    dispatch({ type: UserActionTypes.SEND_MONEY_PENDING });

    axiosInstance
      .post(`/user/money/send`, data)
      .then((res) => {
        dispatch({
          type: UserActionTypes.SEND_MONEY_SUCCESS,
          payload: res.data,
        });

        if (typeof successCallback === "function") {
          successCallback();
        }
      })
      .catch((error) => {
        dispatch({
          type: UserActionTypes.SEND_MONEY_ERROR,
          payload: error.response.data,
        });

        if (typeof errorCallback === "function") {
          errorCallback();
        }
      });
  };

export const sendGift =
  (data, successCallback, errorCallback) => (dispatch) => {
    dispatch({ type: UserActionTypes.SEND_GIFT_PENDING });

    axiosInstance
      .post(`/user/gift/send`, data)
      .then((res) => {
        dispatch({ type: UserActionTypes.SEND_GIFT_SUCCESS });

        if (typeof successCallback === "function") {
          successCallback();
        }
      })
      .catch((error) => {
        dispatch({
          type: UserActionTypes.SEND_GIFT_ERROR,
          payload: error,
        });

        if (typeof errorCallback === "function") {
          errorCallback();
        }
      });
  };

export const register =
  (data, successCallback, errorCallback) => (dispatch) => {
    dispatch({ type: UserActionTypes.USER_REGISTRATION_PENDING });

    axiosInstance
      .post(`/user/registration`, data)
      .then((res) => {
        if (typeof successCallback === "function") {
          successCallback();
        }

        dispatch({
          type: UserActionTypes.USER_REGISTRATION_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: CartActionTypes.GET.CART_COUNT_SUCCESS,
          payload: res.data.cartCount,
        });
      })
      .catch((error) => {
        dispatch({
          type: UserActionTypes.USER_REGISTRATION_ERROR,
          payload: error.response.data,
        });

        // if (typeof errorCallback === "function") {
        //   errorCallback();
        // }
        toast.error(i18n.t(`${error.response.data.message}`));
      });
  };
