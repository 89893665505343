import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Chip } from "@mui/material";
import {
  getStatusColor,
  getStatusString,
} from "../../helpers/statusStringsAndColors";
import Header from "../header/Header";
import { getAllCards, openSidebar } from "../../redux/actions-exporter";

import LoaderPrimary from "../UI/LoaderPrimary";
import {
  cardStatuses,
  pendingStatus,
} from "../../redux/API/order/order.reducer";
import { useNavigate } from "react-router-dom";
import BenefitCard from "./BenefitCard";

export default function UnusedBenefits({ type = "mycoupons" }) {
  const isMycoupons = type === "mycoupons";
  const dispatch = useDispatch();
  const activeCards = useSelector((state) => state.order.activeCards);
  const inactiveCards = useSelector((state) => state.order.inactiveCards);
  const allCardsPending = useSelector(
    (state) => state.order?.isAllCardsPending
  );
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [code, setCode] = useState(null);

  const cardName = code?.cardNumber?.includes("-") ? "מולטיפס" : "iBonus";

  useEffect(() => {
    if (user) dispatch(getAllCards());
  }, [dispatch, user]);

  useEffect(() => {
    if (!user) {
      dispatch(openSidebar());
      navigate("/");
    }
  }, [dispatch, navigate, user]);

  const ifBenefitIsActive = (benefit) =>
    benefit.status === cardStatuses.NOT_USED ||
    benefit.status === cardStatuses.PARTIALLY_USED;

  return (
    <div>
      <Header />
      <div className="pageTemplate">
        <div className="container">
          {user && allCardsPending === pendingStatus.SUCCESS && (
            <>
              {activeCards.length ? (
                activeCards.map((benefit) => {
                  return (
                    <BenefitCard
                      benefit={benefit}
                      onClick={() => setCode(benefit)}
                      type={isMycoupons ? "default" : "bybenefit"}
                      key={benefit.id}
                    />
                  );
                })
              ) : (
                <Typography> עדיין אין כרטיסים אקטיביים.</Typography>
              )}

              {!!inactiveCards.length && (
                <Accordion sx={{ boxShadow: 0 }} expanded={true}>
                  <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                    <Typography>כרטיסים שמומשו</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    {inactiveCards.map((benefit) => (
                      <BenefitCard
                        benefit={benefit}
                        onClick={() => setCode(benefit)}
                        isInactive
                        key={benefit.id}
                      />
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          )}
          {user &&
            (allCardsPending === pendingStatus.IDLE ||
              allCardsPending === pendingStatus.PENDING) && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <LoaderPrimary />
              </div>
            )}
        </div>
      </div>
      <Drawer
        className="infoWrapper"
        PaperProps={{
          sx: {
            width: "80%",
            maxWidth: "350px",
            left: "10%",
            top: "30%",
            height: "40%",
            minHeight: 400,
            margin: "auto",
            borderRadius: "10px",
            padding: "10px",
            zIndex: "10000",
          },
        }}
        open={code}
        onClose={() => setCode(null)}
      >
        <IconButton
          sx={{
            justifyContent: "end",
            p: 0,
            width: "25px",
            position: "absolute",
          }}
          onClick={() => setCode(null)}
        >
          <CloseIcon />
        </IconButton>
        {code && (
          <div className="container">
            <div className="paymentApproveContent">
              <Chip
                label={getStatusString(code.status)}
                color={getStatusColor(code.status)}
                sx={{ zIndex: 2 }}
              />
              <div className="paymentApproveText">
                {code.product?.image && (
                  <div
                    className="product-box__img"
                    style={{
                      backgroundImage: `url(${code.product?.image})`,
                      maxWidth: 200,
                      margin: "0 auto",
                    }}
                  />
                )}
                <h5 className="pelecard-page__title">
                  {code.product ? code.product.title : "Benefit"}{" "}
                </h5>
                {ifBenefitIsActive(code) && <h5>{code.balance}₪</h5>}
                <hr />
                {!!code.cardNumber && (
                  <>
                    <h5 className="paymentApproveLine1">
                      קוד שובר למימוש במערכת {cardName}:
                    </h5>
                    <h5>{code.cardNumber}</h5>
                  </>
                )}
                <h5>
                  {code.expirationDate && `בתוקף עד ${code.expirationDate}`}
                </h5>
                <h5 className="pelecard-page__info">(להצגה בקופה)</h5>
                {/* {!code.product && code.pinCode} */}
                <hr />
                <Typography sx={{ color: "gray" }}>
                  הונפק בתאריך:{" "}
                  {new Date(code.createdAt).toLocaleString("en-GB")}
                </Typography>
              </div>
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
}
