import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrder, clearOrder } from "../../redux/actions-exporter";
import Header from "../header/Header";
import "../cart/pay.css";
import { ERROR_STATUSES } from "../../helpers/hardcodedIds";

function Pay() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { paymentURL, orderId } = location?.state;
  const order = useSelector((state) => state.order.order);

  useEffect(() => {
    if (!paymentURL) {
      navigate("/");
    }
  }, [navigate, paymentURL]);

  useEffect(() => {
    const orderCheckTimer = setInterval(() => {
      if (orderId) dispatch(getOrder(orderId));
    }, 5000);

    return () => clearInterval(orderCheckTimer);
  }, [orderId, dispatch]);

  useEffect(() => {
    const status = order?.status;
    if (status === "INVOICED") {
      navigate(`/checkout/success/${orderId}`);
    } else if (ERROR_STATUSES.includes(status)) {
      dispatch(clearOrder());
      navigate(`/checkout/error/${orderId}`, { state: { status } });
    }
  }, [dispatch, navigate, order, orderId]);

  return (
    <div>
      <Header isFull={false} />
      <div className="pageTemplate">
        <div className="container">
          <script
            src="https://gateway21.pelecard.biz/Scripts/Payment/ClientSecure.js"
            type="text/javascript"
          ></script>
          <iframe
            allowpaymentrequest
            title="payment"
            className="checkout__payment"
            allow="payment"
            src={paymentURL}
          />
        </div>
      </div>
    </div>
  );
}

export default Pay;
