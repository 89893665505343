import React from "react";
import placeholderImg from "../../assets/images/categories/recommended-min.jpg";
import { shortenString } from "../../helpers/shortenString";

const VendorCard = ({ item, onClick }) => {
  // const specialNames = {
  // };
  // const title =
  //   specialNames[item.id] || item.merchants?.[0].title || item.title;
  const title = item.title;

  return (
    <li className="categoryList__block" onClick={() => onClick(item)}>
      <div className="category-box__img">
        <img
          src={
            `${process.env.REACT_APP_API_URL}/upload${item.merchants?.[0].mainImageURL}` ||
            `${process.env.REACT_APP_API_URL}/upload${item.mainImageURL}` ||
            placeholderImg
          }
          className="img-fluid"
          alt=""
        />
      </div>
      <h6 className="category-box__title">{shortenString(title, 30)}</h6>
    </li>
  );
};

export default VendorCard;
