import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import { getCouponsByIds } from "../../redux/actions-exporter";
import Coupon from "./_components/_coupon";
import LoaderPrimary from "../UI/LoaderPrimary";
import { couponsIds } from "../../helpers/hardcodedIds";
// import holidayImage from "../../assets/images/sufganiyot.jpg";

const CouponsList = () => {
  const dispatch = useDispatch();
  const coupons = useSelector((state) => state.coupon.coupons);
  const couponsPending = useSelector((state) => state.coupon.isCouponsPending);
  // const holidayCoupon = {
  //   id: "2971",
  //   title: "חנוכה במאפה נאמן",
  //   mainImageURL: holidayImage,
  //   type: "merchant",
  //   subtitle: "מבצעים מיוחדים",
  // };
  const allCoupons = [...coupons];

  useEffect(() => {
    if (!coupons.length) dispatch(getCouponsByIds(couponsIds));
  }, [dispatch, coupons.length]);

  if (couponsPending)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 20,
        }}
      >
        <LoaderPrimary />
      </div>
    );

  return (
    <div className="caseback-center">
      <Swiper
        speed={2000}
        freeMode={true}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        pagination={true}
        modules={[Autoplay, Pagination]}
        spaceBetween={10}
        style={{ paddingBottom: 20, "--swiper-pagination-color": "var(--primary)" }}
      >
        {coupons.length > 0 &&
          allCoupons.map((coupon, index) => {
            return (
              <SwiperSlide key={index} style={{ width: "auto" }}>
                <Coupon coupon={coupon} />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default CouponsList;
