import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../header/Header";
import CouponsList from "../couponsList";
// import HotBenefitList from "./HotBenefitList";
import Stripe from "./Stripe";
import MerchantsStripe from "./MerchantsStripe";
import "./dashboard.css";
import {
  getAttractionProducts,
  getHotProducts,
  getMerchants,
  getMerchantsFashion,
  getMerchantsRestaurants,
  // getProductCategories,
  getRestaurantsOpenProducts,
  getSelfPickupProducts,
} from "../../redux/actions-exporter";
import { confirmGiftDialog, marketsIds } from "../../helpers/hardcodedIds";
import { MARKETS_ENABLED, PROJECT_NAME } from "../../config";
import BannerMiluim from "./BannerMiluim";
import ConfirmDialog from "../wallets/ConfirmDialog";
import FashionRestaurantsStripe from "./FashionRestaurantsStripe";

export default function Dashboard() {
  window.history.replaceState({}, document.title);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [giftPopup, setGiftPopup] = useState(false);
  const [bannerDialog, setBannerDialog] = useState(null);
  const merchants = useSelector((state) => state.merchant.merchants);

  const merchantsFashion = useSelector(
    (state) => state.merchant.merchantsFashion
  );

  const merchantsRestaurants = useSelector(
    (state) => state.merchant.merchantsRestaurants
  );

  const restaurantsOpenProducts = useSelector(
    (state) => state.product.restaurantsOpenProducts
  );
  const markets = merchants.filter((m) => marketsIds.includes(m.id));

  const merchantsFoodFashion = {
    // Horrible hack to make the list be identical to the one in AllVendor
    food: merchantsRestaurants.filter((m) =>
      restaurantsOpenProducts.some((openProduct) =>
        openProduct?.merchants.map((merch) => merch.id).includes(m.id)
      )
    ),
    other: merchantsFashion,
  };
  const hotProducts = useSelector((state) => state.product.hotProducts);
  const hotProductsPending = useSelector(
    (state) => state.product.isHotProductsPending
  );
  const attractionProducts = useSelector(
    (state) => state.product.attractionProducts
  );
  const attractionProductsPending = useSelector(
    (state) => state.product.isAttractionProductsPending
  );
  const selfPickupItems = useSelector((state) => state.product.selfPickupItems);
  const selfPickupItemsPending = useSelector(
    (state) => state.product.selfPickupPending
  );

  useEffect(() => {
    if (!restaurantsOpenProducts.length) {
      dispatch(getRestaurantsOpenProducts());
    }
  }, [dispatch, restaurantsOpenProducts.length]);

  useEffect(() => {
    if (!hotProducts.length) {
      dispatch(getHotProducts());
    }
  }, [dispatch, hotProducts.length]);

  useEffect(() => {
    if (!attractionProducts.length) {
      dispatch(getAttractionProducts());
    }
  }, [dispatch, attractionProducts.length]);

  useEffect(() => {
    if (!selfPickupItems.length) {
      dispatch(getSelfPickupProducts());
    }
  }, [dispatch, selfPickupItems.length]);

  useEffect(() => {
    if (!merchants.length) {
      dispatch(getMerchants());
    }
  }, [dispatch, merchants.length]);

  useEffect(() => {
    if (!merchantsRestaurants.length) {
      dispatch(getMerchantsFashion());
    }
  }, [dispatch, merchantsRestaurants.length]);

  useEffect(() => {
    if (!merchantsFashion.length) {
      dispatch(getMerchantsRestaurants());
    }
  }, [dispatch, merchantsFashion.length]);

  return (
    <>
      <Header />
      <div className="container">
        <div className="block-slider">
          <CouponsList />

          <hr />
          {MARKETS_ENABLED && (
            <>
              <MerchantsStripe
                title="שוברים לרשתות מזון"
                items={markets}
                isMarkets={true}
              />
              <hr />
            </>
          )}

          <FashionRestaurantsStripe
            handleFashionClick={() => navigate("/allvendors/fashion")}
            handleRestaurantsClick={() => navigate("/allvendors/restaurants")}
            handleHomeAndBabyClick={() => navigate("/allvendors/home-baby")}
          />

          {PROJECT_NAME === "miluim" && (
            <BannerMiluim handleClose={() => setBannerDialog(null)} />
          )}

          {/* <MerchantsStripe
            title="תשלום בהנחה בקופה"
            items={merchantsFoodFashion}
          /> */}

          <hr />
          <Stripe
            title="הטבות חמות"
            items={hotProducts}
            handleClick={() => navigate("/hot")}
            isPending={hotProductsPending}
          />
          <hr />
          <Stripe
            title="שוברים באיסוף עצמי"
            items={selfPickupItems}
            handleClick={() => navigate("/self-pickups")}
            isPending={selfPickupItemsPending}
          />
          <hr />
          <Stripe
            title="אטרקציות"
            items={attractionProducts}
            handleClick={() => navigate("/attractions")}
            isPending={attractionProductsPending}
          />
        </div>
        <ConfirmDialog
          open={giftPopup}
          handleClose={() => setGiftPopup(false)}
          handleConfirm={() => navigate("/unusedbenefits")}
          text1={confirmGiftDialog.text1}
          text2={confirmGiftDialog.text2}
          buttonText={confirmGiftDialog.buttonText}
        />
      </div>
    </>
  );
}
