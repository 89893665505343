import banner from "../../assets/banners/banner.jpeg";
import BannerDialog from "./BannerDialog";
import { useState } from "react";

export default function BannerMiluim({ handleClick = () => {} }) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleBannerClick = () => {
    setDialogOpen(true);
  };

  const handleConfirm = () => {
    window.open("https://www.diablo.co.il", "_blank");
  };

  return (
    <>
      {dialogOpen && (
        <BannerDialog
          open={dialogOpen}
          handleClose={() => setDialogOpen(false)}
          handleConfirm={handleConfirm}
          text={"קוד קופון 15% הנחה רק מלאוימניקים MIL15"}
        />
      )}
      <div style={{ margin: "20px 0" }}>
        <img
          src={banner}
          alt="miluim"
          onClick={handleBannerClick}
          width="100%"
          style={{ borderRadius: 10 }}
        />
      </div>
    </>
  );
}
